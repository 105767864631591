import UserSetting from "components/UsetSetting/Admin/UserSetting";
import { useAdminDispatch, useAdminState } from "context/Admin/global";
import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Locale from "translations";
import logoImg from "../../../assets/images/Image1.png";
import Settings from "../../../assets/images/settings.svg";

import ShowForPermissionAdmin from "helpers/ShowForPermissionAdmin";
import useUserAdminDetails from "context/Admin/useUserAdminDetalis";
import Notifications from "../Notifications/Notifications";
import NotificationsAdmins from "./NotificationsAdmin/Notifications";

function Header() {
  const { translate, Admin, Accounts } = Locale;
  const { pathname } = useLocation();
  const AdminIsAuth = localStorage.getItem("Admin_isAuth");
  const dispatch = useAdminDispatch();
  const { balance } = useAdminState();
  const { Admin_permissions } = useAdminState();
  const { details } = useUserAdminDetails();
  
  return !AdminIsAuth ? null : (
    <>
      <div className="w-100 bg-white pt-2">
        <div className="container  ">
          <div className="d-flex justify-content-between align-items-center">
            <figure className="logo">
              <img className="w-100" src={logoImg} alt="logo" />
            </figure>
            <div className="header-icons">
              {/* <button
                onClick={() => {
                  dispatch({ type: "logout", payload: "logout" });
                }}
              >
                <i class="fas fa-sign-out-alt"></i>
              </button> */}
              <NotificationsAdmins />
              <UserSetting />
            </div>
          </div>
        </div>
      </div>

      <nav className="header ">
        <div className="d-flex justify-content-between align-items-stretch  container ">
          <div className="d-flex  ">
            <NavLink
              exact={true}
              to="/admin/home"
              isActive={() => pathname.includes("home")}
              activeClassName="is-active"
              className="mr-4 py-2 text-bold AdminHeaderLink"
            >
              {Admin.header_home}
            </NavLink>

            <ShowForPermissionAdmin
              permission={["list-companies", "list-pending-companies"]}
            >
              <div className="subnav">
                <button
                  className={
                    pathname.includes("companies")
                      ? "subnavbtn subnavbtn-active mr-4 py-2 text-bold AdminHeaderLink"
                      : "subnavbtn mr-4 py-2 text-bold AdminHeaderLink"
                  }
                >
                  {Admin.header_companies} <i class="fa fa-caret-down"></i>
                </button>

                <div className="subnav-content">
                  <ShowForPermissionAdmin permission={"list-companies"}>
                    <NavLink
                      exact={true}
                      to="/admin/companies"
                      isActive={() => pathname.includes("companies")}
                      activeClassName="is-active"
                      className="mr-4 py-2  text-bold "
                    >
                      {Admin.active_company}
                    </NavLink>
                  </ShowForPermissionAdmin>

                  <ShowForPermissionAdmin permission={"list-pending-companies"}>
                    <NavLink
                      exact={true}
                      to="/admin/companies-requests"
                      isActive={() => pathname.includes("companies")}
                      activeClassName="is-active"
                      className="mr-4 py-2  text-bold"
                    >
                      {Admin.request_company}
                    </NavLink>
                  </ShowForPermissionAdmin>
                </div>
              </div>
            </ShowForPermissionAdmin>

            <ShowForPermissionAdmin permission={["list-admins", "list-groups"]}>
              {Admin_permissions.includes("list-admins") ||
              Admin_permissions.length === 0 ? (
                <NavLink
                  exact={true}
                  to="/admin/team-management/users"
                  isActive={() => pathname.includes("team")}
                  activeClassName="is-active"
                  className="mr-4 py-2 text-bold AdminHeaderLink"
                >
                  {Admin.header_team_management}
                </NavLink>
              ) : (
                <NavLink
                  exact={true}
                  to="/admin/team-management/groups"
                  isActive={() => pathname.includes("team")}
                  activeClassName="is-active"
                  className="mr-4 py-2 text-bold AdminHeaderLink"
                >
                  {Admin.header_team_management}
                </NavLink>
              )}
            </ShowForPermissionAdmin>

            <ShowForPermissionAdmin
              permission={["list-transaction-requests-and-transactions"]}
            >
              <NavLink
                exact={true}
                to="/admin/wallet"
                isActive={() => pathname.includes("admin/wallet")}
                activeClassName="is-active"
                className="mr-4 py-2 text-bold AdminHeaderLink"
              >
                {Admin.header_balance_requests}
              </NavLink>
            </ShowForPermissionAdmin>

            <ShowForPermissionAdmin permission={"list-reservation"}>
              <NavLink
                exact={true}
                to="/admin/orders-inprogress"
                isActive={() =>
                  pathname.includes("admin/medical-services-requests") ||
                  pathname.includes("admin/orders-inprogress")
                }
                activeClassName="is-active"
                className="mr-4 py-2 text-bold AdminHeaderLink"
              >
                {Admin.medical_services_requests}
              </NavLink>
            </ShowForPermissionAdmin>

            <ShowForPermissionAdmin permission={"list-payment-accounts"}>
              <NavLink
                exact={true}
                to="/admin/accounts"
                isActive={() => pathname.includes("accounts")}
                activeClassName="is-active"
                className="mr-4 py-2 text-bold AdminHeaderLink"
              >
                {Accounts.headTile}
              </NavLink>
            </ShowForPermissionAdmin>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Header;
