import React, { useState, useEffect } from "react";

import Locale from "../../../translations";
import { Table } from "reactstrap";

import { medical_ListOrder } from "../../../services/admin";
import Order from "./Order";
import Filter from "./filter";

import Pagination from "components/shared/Pagination";

export default function Orders(props) {
  const { translate, translate_companies } = Locale;

  const [orders, setOrders] = useState([]);
  const [ordersMeta, setOrdersMeta] = useState(null);
  const [filter, setFilter] = useState({
    status: 'done',
    traveler_name: '',
    company_name: '',
    check_date: '',
    page: 1,
  });

  useEffect(() => {
    async function fetchData() {
      const Order = await medical_ListOrder(filter);
      
      setOrders(Order?.data?.data);
      setOrdersMeta(Order?.data?.meta);
    }
    fetchData();
  }, [filter]);

  // pagination
  const goTo = async (page) => {
    setFilter({ ...filter, page: page });
  };

  const AllOrders =
    orders && orders?.length > 0
      ? orders?.map((order, index) => (
        <Order
          order={order}
          index={index}
        />
      ))
      : "";

  const filterSubmitted = (dateQ, searchQ, searchCompanyNameQ) => {
    
    
    if (dateQ !== '') {
      setFilter({ ...filter, check_date: dateQ, traveler_name: searchQ, company_name: searchCompanyNameQ, page: 1 });
    } else {
      setFilter({ ...filter, traveler_name: searchQ, company_name: searchCompanyNameQ, page: 1 });
    }
  }

  const resetSubmitted = () => {
    setFilter({ ...filter, status: 'done', traveler_name: '', company_name: '', check_date: '', page: 1 });
  }

  return (
    <React.Fragment>
      <Filter
        ResetSubmitted={() => resetSubmitted()}
        FilterSubmitted={(status, searchQ, searchCompanyNameQ) => filterSubmitted(status, searchQ, searchCompanyNameQ)} />

      <div className=" mt-3 p-0 CompaniesList-table">
        <Table>
          <thead>
            <tr className="CompaniesList-head">
              <th className="CompanyHead-title"> {translate.companyName}</th>
              <th className="CompanyHead-title"> {translate_companies.order_number}</th>
              <th className="CompanyHead-title">{translate_companies.type_examination}</th>
              <th className="CompanyHead-title">{translate_companies.customer_name}</th>
              <th className="CompanyHead-title">{translate_companies.medical_service_provider}</th>
              {/* <th>{translate_companies.method_examination}</th> */}
              {/* <th>{translate_companies.number}</th> */}
              <th className="CompanyHead-title">{translate_companies.date_request}</th>
              <th className="CompanyHead-title">{translate_companies.examination_date}</th>
              {/* <th>{translate_companies.result_date}</th> */}
              <th className="CompanyHead-title">{translate_companies.cost}</th>
              <th className="CompanyHead-title">{translate_companies.time_taken}</th>
              <th className="CompanyHead-title">{translate_companies.payment_method}</th>
              <th className="CompanyHead-title">{translate_companies.company_ratio}</th>
            </tr>
          </thead>
          <tbody className="table-stripe ">{AllOrders}</tbody>
        </Table>
        <Pagination info={ordersMeta} goTo={goTo} items={10} />
      </div>
    </React.Fragment>
  );
}
