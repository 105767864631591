import Pagination from "components/shared/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/textField";
import { useCompanyState } from "context/global";
import { getClientList } from "services/yarvel";
import Locale from "translations";
import Filter from "./filter";
import ListTravelers from "./listTravelers";

function CustomersList() {
  const [modal, setModal] = useState(false);
  const { currentLanguage } = useCompanyState();
  const { translate } = Locale;
  const [clients, setClients] = useState();
  const { id } = useParams();
  const [meta, setmeta] = useState();
  const [filter, setfilter] = useState({
    traveler_name: "",
    created_date: "",
    check_date: "",
  });

  const [searching, setSearching] = useState(false);
  /*   const examainatonTypes = LooksupTestTypes(); */

  const submit = () => {
    setSearching(!searching);
  };

  /* get clients list */

  useEffect(() => {
    async function fetchListClients() {
      const list = await getClientList({
        ...filter,
        status: id ? id : "",
        result: id ? "all" : "",
        created_date: filter.created_date
          ? moment(filter.created_date).format("DD-MM-YYYY")
          : "",

        check_date: filter.check_date
          ? moment(filter.check_date).format("DD-MM-YYYY")
          : "",
      });
   
      if (list?.status === 200 || list?.status === 201) {
        setClients(list?.data?.data);
        setmeta(list?.data?.meta);
      }
    }
    fetchListClients();
  }, [id, searching, filter.page]);

  const goTo = async (page) => {
    setfilter({ ...filter, page: page });
  };



  return (
    <>
      <div className="container">
        {/*         <div className="d-flex justify-content-end my-3 ">
          <button
            type="button"
            className="btn btn-success p-2  font-weight-bold "
            onClick={toggle}
          >
            <i className="fas fa-plus-circle mr-2"></i>
            {translate.AddReservation}
          </button>
        </div> */}

        <header className=" list_header border py-3 px-2 no-gutters row justify-content-between align-items-baseline mt-3">
          <h3 className="text-muted font-weight-bold font-mobile col-12 col-md-2 text-center">
            {translate.MedicalServiceReq}
          </h3>
          <div className="d-flex col-12 col-md-8 justify-content-center justify-content-md-end">
            <NavLink
              exact={true}
              to="/customers"
              activeClassName="is-active"
              className="p-2 text-bold bg-white mx-3 bg-brwon rounded text-muted"
            >
              {translate.InProgress}
            </NavLink>

            <NavLink
              exact={true}
              to="/customers/done"
              activeClassName="is-active"
              className="p-2 text-bold bg-white rounded text-muted"
            >
              {translate.AreChecked}
            </NavLink>
          </div>
        </header>
        <Filter filter={filter} setfilter={setfilter} submit={submit} />
        <ListTravelers clients={clients} />
        <Pagination
          info={meta}
          goTo={goTo}
          items={10}
          pageLength={clients?.length}
        />
      </div>

      {/* Modal examainaton type */}
      {/* <Modal isOpen={modal} toggle={toggle}>
        <div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
          <p className="h3 text-secondary ">{translate.ExaminationType}</p>
          <i
            className="far fa-times-circle fa-lg text-secondary "
            onClick={toggle}
          ></i>
        </div>
        <ModalBody className="lan  mx-5 my-4 p-0  ">
          <div
            className="mt-4"
            style={{
              textAlign: currentLanguage === "ar" ? "right" : "left",
            }}
          >
            <SelectField
              hasLabel={true}
              value={examainaton.by}
              label={translate.ExaminationType}
              onFocus={true}
              options={examainatonTypes}
              onChange={(e) => {
                setExamainaton({ ...examainaton, by: e, id: e.id });
              }}
            />

            {examainaton.id != " " && (
              <div className="mt-4">
                <TextField
                  hasLabel={true}
                  label={translate.NumberOfPeople}
                  type={"text"}
                  onChange={(e) => {
                    setExamainaton({
                      ...examainaton,
                      numbers: e.target.value,
                    });
                  }}
                ></TextField>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter className="pb-4 border-top-0  mx-5 p-0">
          <button
            className="rounded btn-success w-100 lan text-bold h5 py-2 m-0 "
            onClick={() => {
              
              history.push(
                `/Add-Reservation/${examainaton.numbers}/${examainaton.by.value}`
              );
            }}
          >
            {translate.Reservation}
          </button>
        </ModalFooter>
      </Modal> */}
    </>
  );
}

export default CustomersList;
