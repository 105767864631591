import React from "react";
import Locale from "translations";

export default function ViewClient({ client, index, service }) {
  const { translate, translate_companies } = Locale;
  
  return (
    <React.Fragment>
      <div className=" p-3  border medical_box rounded mt-3">
        <h5 className="font-weight-bold text_blue-dark mt-2 mb-3 d-flex justify-content-between">
          {translate.viewUserData} ({index + 1})
          {client?.result && (
            <a class="btn btn-primary " href={client.result_file}>
              {translate.analysisResults} :{" "}
              <span className="text-bolder">
                {client.result} <i class="fas fa-download"></i>
              </span>
            </a>
          )}
        </h5>
        <div className=" row">
          <p className="col-2 h5  font-weight-bold">{translate.nameArabic}</p>
          <p className="col-4  h5 ">
            {" "}
            {
            `${client?.traveler?.ar_first_name} ${
              client?.traveler?.ar_father_name != null
                ? client?.traveler?.ar_father_name
                : ""
            } ${
              client?.traveler?.ar_grandpa_name != null
                ? client?.traveler?.ar_grandpa_name
                : ""
            } ${client?.traveler?.ar_last_name}`}
          </p>

          {/* <p className="col-2 h5  font-weight-bold">
              {translate.phoneNumber}
            </p>
            <p className="col-4  h5 ">{client?.traveler.phone}</p> */}

          <p className="col-2 h5  font-weight-bold">{translate.EnglishName}</p>
          <p className="col-4  h5 ">{`${client?.traveler?.en_first_name} ${
            client?.traveler?.en_father_name != null
              ? client?.traveler?.en_father_name
              : ""
          }  ${
            client?.traveler?.en_grandpa_name != null
              ? client?.traveler?.en_grandpa_name
              : ""
          }  ${client?.traveler?.en_last_name} `}</p>
          {/* <p className="col-2 h5  font-weight-bold">{translate.country}</p>
            <p className="col-4  h5 ">
             {client.country_id?.names[ar]} مصر
            </p> */}
          <p className="col-2 h5  font-weight-bold">
            {translate.passportNumber}
          </p>
          <p className="col-4  h5 ">{client?.traveler.passport_number}</p>
          {/* <p className="col-2 h5  font-weight-bold">{translate.city}</p>
            <p className="col-4  h5 ">{client?.traveler?.city_id?.names.ar}</p> */}
          <p className="col-2 h5  font-weight-bold">{translate.ExpiryDate}</p>
          <p className="col-4  h5 ">{client?.traveler.expiration_date}</p>

          {/* <p className="col-2 h5  font-weight-bold">{translate.area}</p>
            <p className="col-4  h5 ">{client?.traveler?.area_id?.names.ar}</p> */}

          <p className="col-2 h5  font-weight-bold">{translate.nationalId}</p>
          <p className="col-4  h5 ">{client?.traveler.national_id}</p>
          {/* <p className="col-2 h5  font-weight-bold">{translate.address}</p>
            <p className="col-4  h5 ">{client?.traveler.address}</p> */}
          <p className="col-2 h5  font-weight-bold">{translate.Nationality}</p>
          <p className="col-4  h5 ">{client?.traveler?.country_id?.name_ar}</p>
        </div>
      </div>

      <div className="rounded reservationVeiw">
        <div className="p-3 row">
          {client?.time_taken != null ? (
            <p className="col-2 h5   font-weight-bold">
              {translate.ResultWithin}
            </p>
          ) : null}
          {client?.time_taken != null ? (
            <p
              className={`col-4 h5 ${
                +service?.time < client?.time_taken
                  ? "text-danger"
                  : "text-success"
              } `}
            >
              {client.time_taken} {translate_companies.hours}
            </p>
          ) : null}
          <p className="col-2 h5  font-weight-bold">
            {" "}
            {translate.DateRequest}{" "}
          </p>
          <p className="col-4  h5  ">
            {" "}
            {client.created_at_date} {client.created_at_time}{" "}
          </p>

          <p className="col-2 h5  font-weight-bold">{translate.orderNumber}</p>
          <p className="col-4  h5  "> {client.id}</p>

          <p className="col-2 h5  font-weight-bold">
            {translate.examinationDate}
          </p>
          <p className="col-4  h5 "> {client.check_date}</p>

          <p className="col-2 h5  font-weight-bold">{translate.time}</p>
          <p className="col-4  h5 ">{client.check_time}</p>

          <p className="col-2 h5  font-weight-bold">{translate.governorate}</p>
          <p className="col-4  h5 ">{client.city}</p>
          <p className="col-2 h5  font-weight-bold">{translate.city}</p>
          <p className="col-4  h5 ">{client.area}</p>
          <p className="col-2 h5  font-weight-bold">{translate.StName}</p>
          <p className="col-4  h5 ">{client.street}</p>

          <p className="col-2 h5  font-weight-bold">
            {translate.BuildingNumber}
          </p>
          <p className="col-4  h5 ">{client.building}</p>

          <p className="col-2 h5  font-weight-bold">{translate.Floor}</p>
          <p className="col-4  h5 ">{client.floor}</p>

          <p className="col-2 h5  font-weight-bold">
            {translate.ApartmentNumber}
          </p>
          <p className="col-4  h5 ">{client.flat}</p>
          <p className="col-2 h5  font-weight-bold">{translate.phoneNumber}</p>
          <p className="col-4  h5 ">{client.phone}</p>
          <p className="col-2 h5  font-weight-bold">{translate.email}</p>
          <p className="col-4  h5 ">{client.email}</p>

          <p className="col-2 h5  font-weight-bold">{translate.Cost}</p>
          <p className="col-4  h5 ">{client.total_cost}</p>

          <p className="col-2 h5  font-weight-bold">
            {translate.paymentMethods}
          </p>
          <p className="col-4  h5 ">{client?.payment?.type}</p>
        </div>
      </div>
    </React.Fragment>
  );
}
