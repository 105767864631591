import React, { useState, useEffect } from "react";

import Locale from "../../../../translations";
import { Table } from "reactstrap";

import { company_ListOrder } from "../../../../services/admin";
import Order from "./Order";
import Filter from "./filter";

import Pagination from "components/shared/Pagination";

export default function Orders(props) {
  const { translate, translate_companies } = Locale;
  const { companyID } = props;

  const [orders, setOrders] = useState([]);
  const [ordersMeta, setOrdersMeta] = useState(null);
  const [filter, setFilter] = useState({
    status: "done",
    company_id: companyID,
    traveler_name: "",
    check_date: "",
    page: 1,
  });

  useEffect(() => {
    async function fetchData() {
      const Order = await company_ListOrder(filter);
      
      setOrders(Order?.data?.data);
      setOrdersMeta(Order?.data?.meta);
      props.setCompanyName(
        Order?.data?.data[0]?.company_name
          ? Order?.data?.data[0]?.company_name
          : ""
      );
    }
    fetchData();
  }, [filter]);

  // pagination
  const goTo = async (page) => {
    setFilter({ ...filter, page: page });
  };

  const AllOrders =
    orders && orders?.length > 0 ? (
      orders?.map((order, index) => (
        <Order company_id={companyID} order={order} index={index} />
      ))
    ) : (
      <tr>
        <td colSpan="11">
          <div className=" text-center">
            <i className="fas fa-info-circle fa-lg text-muted m-3 "></i>{" "}
            <h4 className=" text-muted">{translate.noResult}</h4>
          </div>
        </td>
      </tr>
    );

  const filterSubmitted = (dateQ, searchQ) => {
    
    
    if (dateQ !== "") {
      setFilter({
        ...filter,
        check_date: dateQ,
        traveler_name: searchQ,
        page: 1,
      });
    } else {
      setFilter({ ...filter, traveler_name: searchQ, page: 1 });
    }
  };

  const resetSubmitted = () => {
    setFilter({
      ...filter,
      status: "done",
      traveler_name: "",
      check_date: "",
      page: 1,
    });
  };

  return (
    <React.Fragment>
      <Filter
        ResetSubmitted={() => resetSubmitted()}
        FilterSubmitted={(status, searchQ) => filterSubmitted(status, searchQ)}
      />

      <div className=" mt-3 p-0 CompaniesList-table">
        <Table>
          <thead>
            <tr className="CompaniesList-head">
              <th className="CompanyHead-title">
                {" "}
                {translate_companies.order_number}
              </th>
              <th className="CompanyHead-title">
                {translate_companies.type_examination}
              </th>
              <th className="CompanyHead-title">
                {translate_companies.customer_name}
              </th>
              <th className="CompanyHead-title">
                {translate_companies.medical_service_provider}
              </th>
              {/* <th>{translate_companies.method_examination}</th> */}
              {/* <th>{translate_companies.number}</th> */}
              <th className="CompanyHead-title">
                {translate_companies.date_request}
              </th>
              <th className="CompanyHead-title">
                {translate_companies.examination_date}
              </th>
              <th className="CompanyHead-title">{translate_companies.cost}</th>
              <th className="CompanyHead-title">
                {translate_companies.time_taken}
              </th>
              <th className="CompanyHead-title">
                {translate_companies.payment_method}
              </th>
              <th className="CompanyHead-title">
                {translate_companies.company_ratio}
              </th>
            </tr>
          </thead>
          <tbody className="table-stripe ">{AllOrders}</tbody>
        </Table>
        <Pagination info={ordersMeta} goTo={goTo} items={10} />
      </div>
    </React.Fragment>
  );
}
