import { useState } from "react";
import { useEffect } from "react";
import { getUserDetails } from "services/yarvel";
import { useAdminDispatch } from "./global";

const useUserAdminDetails = () => {
  const dispatch = useAdminDispatch();
  const [details, setDetails] = useState();

  const getDetails = async () => {
    
    const res = await getUserDetails();
    if (res.status === 200) {
      dispatch({ type: "getPermissions", payload: res.data.data });
      setDetails(res.data.data);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return {
    getDetails,
    details,
  };
};

export default useUserAdminDetails;
