import React, { useState, useEffect } from "react";

import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { NavLink, useParams } from "react-router-dom";
import { store } from "react-notifications-component";

import {
  RetrievedCompany,
  listCountriesDp,
  listCitiesDp,
  editCompanyAPI,
} from "../../../services/admin";
import Locale from "../../../translations";

import { useAdminState } from "../../../context/Admin/global";

const Edit = (props) => {
  const { translate, translate_companies } = Locale;
  const { company_id } = useParams();
  const { currentLanguage } = useAdminState();

  const [countriesDB, setCountriesDB] = useState([]);
  const [countries, setCountries] = useState(0);

  const [citiesDB, setCitiesDB] = useState([]);
  const [cities, setCities] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const Company = await RetrievedCompany(company_id);
      //debugger
      
      formik.setFieldValue("companyName", Company.data.companies.name);
      formik.setFieldValue("email", Company.data.companies.business_email);
      formik.setFieldValue("phoneNumber", Company.data.companies.phone);
      formik.setFieldValue(
        "extraPhoneNumber",
        Company.data.companies.extra_data
      );
      formik.setFieldValue("address", Company.data.companies.address);
      formik.setFieldValue("countries", Company.data.companies.country_id);
      formik.setFieldValue("cities", Company.data.companies.city_id);
      setCountries(Company.data.companies.country_id);
      setCities(Company.data.companies.city_id);

      const CountriesDp = await   listCountriesDp();
      if (CountriesDp.status === 200) {
        const format = CountriesDp.data?.data?.map((e) => ({
          value: e.id,
          label: currentLanguage === "en" ? e.names.en : e.names.ar,
        }));
        setCountriesDB(format);
      }

      if(Company.data.companies.city_id) {
        
        const CitiesDp = await   listCitiesDp(Company.data.companies.country_id);
        if (CitiesDp.status === 200) {
          const format = CitiesDp.data?.data?.map((e) => ({
            value: e.id,
            label: currentLanguage === "en" ? e.names.en : e.names.ar,
          }));
          setCitiesDB(format);
        }
      }
    }
    fetchData();
  }, []);
  
  const handleChangeCountries = async (event) => {
    let countryID = event.target.value.target.value;
    
    setCountries(event.target.value.target.value);

    const CitiesDp = await   listCitiesDp(countryID);
      if (CitiesDp.status === 200) {
        const format = CitiesDp.data?.data?.map((e) => ({
          value: e.id,
          label: currentLanguage === "en" ? e.names.en : e.names.ar,
        }));
        setCitiesDB(format);
      }
  };

  const handleChangeCities = (event) => {
    setCities(event.target.value.target.value);
  }

  const editCompany = async (payload) => {
    const res = await editCompanyAPI(company_id, payload);

    if (res.status === 200) {
      store.addNotification({
        title: "info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      props.history.push("/Admin/Companies");
    }

    
  };

  const validationSchema = yup.object({
    companyName: yup
      .string(translate_companies.companyNameRequired)
      .required(translate_companies.companyNameRequired),
    email: yup
      .string(translate_companies.companyEmailRequired)
      .email(translate_companies.companyEmailError)
      .max(50, translate_companies.companyEmailError)
      .required(translate_companies.companyEmailRequired),
    phoneNumber: yup
      .number()
      .typeError(translate_companies.phoneNumberError)
      .test("phoneNumber-check", translate_companies.phoneNumberError, (mobile) => {
        if (mobile) {
          let mobilevalue = "+20" + mobile;

          try {
            const phoneUtil =
              require("google-libphonenumber").PhoneNumberUtil.getInstance();
            const number = phoneUtil.parse(mobilevalue.toString(), "EG");

            if (phoneUtil.isValidNumber(number, "EG")) {
              return true;
            } else {
              return false;
            }
          } catch (e) {
            
          }
        }else {
          return false;
        }
      })
      .required(translate_companies.phoneNumberRequired),
    extraPhoneNumber: yup
      .number()
      .typeError(translate_companies.phoneNumberError)
      .test("extraPhoneNumber-check", translate_companies.phoneNumberError, (mobile) => {
        if (mobile) {
          let mobilevalue = "+20" + mobile;

          try {
            const phoneUtil =
              require("google-libphonenumber").PhoneNumberUtil.getInstance();
            const number = phoneUtil.parse(mobilevalue.toString(), "EG");

            if (phoneUtil.isValidNumber(number, "EG")) {
              return true;
            } else {
              return false;
            }
          } catch (e) {
            
          }
        }
        else{
          return false;
        }
      }).nullable(),
      address: yup
      .string(translate_companies.addressRequired)
      .required(translate_companies.addressRequired),
      countries: yup.string().required(translate_companies.countriesRequired).nullable(),
      cities: yup.string().required(translate_companies.citiesRequired).nullable(),
  });

  const formik = useFormik({
    initialValues: {
      companyName: "",
      email: "",
      phoneNumber: "",
      extraPhoneNumber: "",
      address: "",
      countries: countries,
      cities: cities
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      let payload = {};

      if(values.extraPhoneNumber !== null){
        payload = {
          id: company_id,
          name: values.companyName,
          business_email: values.email,
          phone: values.phoneNumber,
          extra_data: [values.extraPhoneNumber],
          address: values.address,
          avatar: 'public/files/636970643.png',
          country_id: values.countries,
          city_id: values.cities
        };
      }else {
        payload = {
          id: company_id,
          name: values.companyName,
          business_email: values.email,
          phone: values.phoneNumber,
          address: values.address,
          avatar: 'public/files/636970643.png',
          country_id: values.countries,
          city_id: values.cities
        };
      }

      

      editCompany(payload);
    },
  });

  return (
    <section>
      <div className="container  my-4 ">
        <header className=" AdminTeamlist_header border py-3 px-3">
          <div className="d-flex justify-content-between  ">
            <h3 className="text-muted font-weight-bold AdminTeamlist_title">
              {translate_companies.editCompany}
            </h3>
          </div>
          
        </header>
        <form onSubmit={formik.handleSubmit}>
          <div className=" controls">
            <div className="row my-3">
              <div className="col-3 mr-3">
                <TextField
                  className={`CompaniesInput ${
                    formik.touched.companyName && formik.errors.companyName
                      ? "CompaniesInputError"
                      : ""
                  }`}
                  id="companyName"
                  name="companyName"
                  label={translate_companies.companyName}
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.companyName &&
                    Boolean(formik.errors.companyName)
                  }
                  helperText={
                    formik.touched.companyName && formik.errors.companyName
                  }
                  placeholder={translate_companies.companyNamePlaceholder}
                />
              </div>
              <div className="col-3 mr-3">
                <TextField
                  className={`CompaniesInput ${
                    formik.touched.email && formik.errors.email
                      ? "CompaniesInputError"
                      : ""
                  }`}
                  id="email"
                  name="email"
                  label={translate_companies.companyEmail}
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  placeholder={translate_companies.companyEmailPlaceholder}
                />
              </div>
              <div className="col-3 mr-3">
                <TextField
                  className={`CompaniesInput ${
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                      ? "CompaniesInputError"
                      : ""
                  }`}
                  id="phoneNumber"
                  name="phoneNumber"
                  label={translate_companies.phoneNumber}
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  placeholder={translate_companies.phoneNumberPlaceholder}
                />
              </div>
            </div>

            <div className="row my-3">
              <div className="col-3 mr-3">
                <TextField
                  className={`CompaniesInput ${
                    formik.touched.extraPhoneNumber &&
                    formik.errors.extraPhoneNumber
                      ? "CompaniesInputError"
                      : ""
                  }`}
                  id="extraPhoneNumber"
                  name="extraPhoneNumber"
                  label={translate_companies.extraPhoneNumber}
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values.extraPhoneNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.extraPhoneNumber &&
                    Boolean(formik.errors.extraPhoneNumber)
                  }
                  helperText={
                    formik.touched.extraPhoneNumber &&
                    formik.errors.extraPhoneNumber
                  }
                  placeholder={translate_companies.extraPhoneNumberPlaceholder}
                />
              </div>
              <div className="col-3 mr-3">
                <TextField
                  className={`CompaniesInput ${
                    formik.touched.address && formik.errors.address
                      ? "CompaniesInputError"
                      : ""
                  }`}
                  id="address"
                  name="address"
                  label={translate_companies.address}
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  placeholder={translate_companies.addressPlaceholder}
                />
              </div>
              <div className="col-3 mr-3">
                <FormControl
                  className={`CompaniesInput ${
                    formik.touched.countries && formik.errors.countries
                      ? "CompaniesInputError"
                      : ""
                  }`}
                  variant="filled"
                >
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    shrink={true}
                  >
                    {translate_companies.countries}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={formik.values.countries}
                    name="countries"
                    onChange={(selectedOption) => {
                      
                      let event = {
                        target: { name: "countries", value: selectedOption },
                      };
                      handleChangeCountries(event);
                      formik.handleChange(selectedOption);
                    }}
                  >
                    {countriesDB.length > 0
                      ? countriesDB.map((country) => (
                          <MenuItem key={country.value} value={country.value}>
                            {country.label}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                  {formik.touched.countries && Boolean(formik.errors.countries) ? (
                    <FormHelperText
                      className={"CountriesInputError"}
                      id="component-error-text"
                    >
                      {formik.errors.countries}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </div>
              <div className="col-3 mr-3">
                <FormControl
                  className={`CompaniesInput ${
                    formik.touched.cities && formik.errors.cities
                      ? "CitiesInputError"
                      : ""
                  }`}
                  variant="filled"
                >
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    shrink={true}
                  >
                    {translate_companies.cities}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={formik.values.cities}
                    name="cities"
                    onChange={(selectedOption) => {
                      
                      let event = {
                        target: { name: "cities", value: selectedOption },
                      };
                      handleChangeCities(event);
                      formik.handleChange(selectedOption);
                    }}
                  >
                    {citiesDB.length > 0
                      ? citiesDB.map((city) => (
                          <MenuItem key={city.value} value={city.value}>
                            {city.label}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                  {formik.touched.cities && Boolean(formik.errors.cities) ? (
                    <FormHelperText
                      className={"CitiesInputError"}
                      id="component-error-text"
                    >
                      {formik.errors.cities}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </div>
            </div>
          </div>

          <div className=" btns d-flex justify-content-between align align-items-end max-height">
            <NavLink
              exact={true}
              to={`/admin/companies`}
              activeClassName="is-active"
            >
              <button className="btn btn-secondary px-5 py-2 ">
                {translate.back}
              </button>
            </NavLink>
            <button className="btn btn-success px-5 py-2 " type="submit">
              {translate.edit}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Edit;
