import React from "react";
import Locale from "translations";
export default function Client({ client, index, reservation }) {
  const { translate } = Locale;
  const locale = localStorage.getItem("currentLanguage") || "en";
  //debugger;
  

  return (
    <div className="border bg-white rounded mt-4">
      <h5 className="font-weight-bold p-3 text_blue-dark mt-2">
        {translate.viewUserData} ({index + 1})
      </h5>

      <div className="  px-3 row">
        <p className=" col-md-3 col-6 h5 font-mobile   font-weight-bold">
          {translate.nameArabic}
        </p>
        <p className=" col-md-3 col-6 h5 font-mobile ">
          {" "}
          {`${client?.ar_first_name} ${client?.ar_father_name} ${client?.ar_grandpa_name} ${client?.ar_last_name}`}
        </p>

        <p className="col-md-3 col-6 h5 font-mobile   font-weight-bold">
          {translate.area}
        </p>
        <p className="col-md-3 col-6 h5 font-mobile ">{reservation?.area}</p>
        <p className="col-md-3 col-6 h5 font-mobile   font-weight-bold">
          {translate.phoneNumber}
        </p>
        <p className="col-md-3 col-6 h5 font-mobile ">{reservation?.phone}</p>
        <p className="col-md-3 col-6 h5 font-mobile   font-weight-bold">
          {translate.city}
        </p>
        <p className="col-md-3 col-6 h5 font-mobile ">{reservation?.city}</p>

        <p className="col-md-3 col-6 h5 font-mobile   font-weight-bold">
          {translate.address}
        </p>
        <p className="col-md-3 col-6 h5 font-mobile ">{reservation?.street}</p>

        <p className="col-md-3 col-6 h5 font-mobile   font-weight-bold">
          {translate.EnglishName}
        </p>
        <p className="col-md-3 col-6 h5 font-mobile ">
          {`${client?.en_first_name} ${client?.en_father_name} ${client?.en_grandpa_name} ${client?.en_last_name} `}
        </p>
        <p className="col-md-3 col-6 h5 font-mobile  font-weight-bold">
          {translate.country}
        </p>
        <p className="col-md-3 col-6 h5 font-mobile ">مصر</p>
        <p className="col-md-3 col-6  h5 font-mobile  font-weight-bold">
          {translate.passportNumber}
        </p>
        <p className="col-md-3 col-6 h5 font-mobile ">
          {client.passport_number}
        </p>
      {client.expiration_date?  <p className="col-md-3 col-6  h5 font-mobile  font-weight-bold">
          {translate.ExpiryDate}
        </p>:null}
        <p className="col-md-3 col-6  h5 font-mobile  ">
          {client.expiration_date}
        </p>

        {client?.national_id ? (
          <p className="col-md-3 col-6  h5 font-mobile  font-weight-bold">
            {translate.nationalId}
          </p>
        ) : null}
        <p className="col-md-3 col-6  h5 font-mobile  ">{client.national_id}</p>

        {client?.nationality ? (
          <p className="col-md-3 col-6  h5 font-mobile  font-weight-bold">
            {translate.Nationality}
          </p>
        ) : null}
        <p className="col-md-3 col-6  h5 font-mobile  ">
          {client?.nationality}
        </p>
      </div>
    </div>
  );
}
