import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import arLogo from "../../../assets/images/ar-flag.svg";
import enLogo from "../../../assets/images/en-flag.svg";
import { useAdminDispatch } from "../../../context/Admin/global";
import Locale from "../../../translations";

function UserSetting() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const dispatch = useAdminDispatch();

  const { translate, Admin } = Locale;
  return (
    <>
      <UncontrolledDropdown setActiveFromChild>
        <DropdownToggle tag="button" className="h5 text-primary" caret>
          <i className="fas fa-cog fa-lg  mx-2 text-primary "></i>{" "}
          {translate.setting}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            tag="button"
            className=" d-flex justify-content-between"
            onClick={() => {
              dispatch({ type: "setLocale", payload: "ar" });
            }}
          >
            <img className=" px-2" src={arLogo} width="40" alt="lang" />
            العربيه
          </DropdownItem>
          <DropdownItem
            tag="button"
            className=" d-flex justify-content-between"
            onClick={() => {
              dispatch({ type: "setLocale", payload: "en" });
            }}
          >
            <img className=" px-2" src={enLogo} width="40" alt="lang" />
            English
          </DropdownItem>
          <DropdownItem
            tag="button"
            className=" d-flex justify-content-between"
            onClick={() => {
              dispatch({ type: "logout", payload: "logout" });
            }}
           
          >
            <i class="fas fa-sign-out-alt px-2"></i>
            <p className="m-0"> {Admin.logout}</p>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default UserSetting;
