import { useCompanyState } from "context/global";
import React from "react";

export default function ShowForPermission(props) {
  let CompanyState = useCompanyState();

  if (CompanyState.permissions && CompanyState.permissions.length > 0) {
    let type = props.type ? props.type : "or";

    if (Array.isArray(props.permission) && type === "or") {
      /* this case for or conditions ex :  list admins and list groups   permission could have one of them */
      return props.permission.some((per) =>
        CompanyState.permissions.includes(per)
      ) ? (
        <React.Fragment>{props.children}</React.Fragment>
      ) : null;

      /* *************************************** */
    } else if (Array.isArray(props.permission) && type === "and") {
      return props.permission.every((per) =>
        CompanyState.permissions.includes(per)
      ) ? (
        <React.Fragment>{props.children}</React.Fragment>
      ) : null;

      /* *************************************** */
    } else {
      return CompanyState.permissions.includes(props.permission) ? (
        <React.Fragment>{props.children}</React.Fragment>
      ) : null;
    }
  } else if (CompanyState?.userType?.includes("company-admin")) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
}
