import React, { createContext, useContext, useReducer } from "react";
import reducers from "./reducers";
const CompanyContext = createContext(undefined);
const CompanyDispatchContext = createContext(undefined);
const currentLanguage = localStorage.getItem("currentLanguage");
const token = localStorage.getItem("token");
const company_id = localStorage.getItem("company_id");
// intial state to be passed to reducer

const initialState = {
  currentLanguage: currentLanguage ?? "ar",
  token: token ? token : "",
  company_id: company_id ? company_id : "",
  balance: 0,
  havePermission: true,

  updateNotifications : false,
  userType: localStorage.getItem("userType") ?? null,
  permissions: localStorage.getItem("permissions")
    ? JSON.parse(localStorage.getItem("permissions"))
    : [],
};

// to get data from context
function useCompanyState() {
  const context = useContext(CompanyContext);
  if (context === undefined) {
    throw new Error("useCompanyState is must be used within a Provider ");
  }
  return context;
}

// to dispatch actions store dispatch function in context to see
// in components and change reducer state passed to company context
function useCompanyDispatch() {
  const context = useContext(CompanyDispatchContext);
  if (context === undefined) {
    throw new Error("useCompanyDispatch must be used within a Provider");
  }
  return context;
}

// provider for all components to see context stored  data
// reducer to manage data
// pass reducer state to context

function CompanyProvider({ children }) {
  const [state, dispatch] = useReducer(reducers, initialState);

  return (
    <CompanyContext.Provider value={state}>
      <CompanyDispatchContext.Provider value={dispatch}>
        {children}
      </CompanyDispatchContext.Provider>
    </CompanyContext.Provider>
  );
}

export { CompanyProvider, useCompanyState, useCompanyDispatch };
