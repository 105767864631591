import React from "react";
import img from "assets/images/404_error_image.svg";
import { Link } from "react-router-dom";

import "./style.scss";
import Locale from "translations";
  const { translate } = Locale;
function NotFound({ title = "Oops!", message = translate.notFound, to = "/" }) {
  return (
    <div className="not-found">
      <img className="not-found__image" src={img} />
      <div className="not-found__content">
        <h1 className="title">{title}</h1>
        <h3 className="">{message}</h3>
      </div>
      {/* <Link to={to} className="not-found__btn btn btn-primary mt-3">
			العوده الي القائمة الرئيسيه
			</Link> */}
    </div>
  );
}

export default NotFound;
