import DatePicker from "components/shared/DatePicker";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/textField";
import Locale from "translations";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useCompanyState } from "context/global";
import Checkbox from "components/shared/checkbox";
import { fetchAreas, fetchServiceAreas } from "services/lookups";
import { useEffect, useState } from "react";
import {
  LooksupCities,
  LooksupServicesCities,
  LooksupTimeReservation,
} from "components/lookups";
import { saveReservation } from "services/yarvel";
import { useClientsDispatch, useClientsState } from "context/addClient";

export default function HomeBooking({
  client,
  index,
  testid,
  cost,
  tripdate,
  selectedGrade,
  setCost,
  bookingType,
  fillClients,
  setErrorsReservation,
  errorsReservation,
  checkFormallErrorsReservation,
  doneReservation,
  setDoneReservation,
}) {
  const { listClients } = useClientsState();

  const locale = localStorage.getItem("currentLanguage") || "en";
  const cities = LooksupServicesCities();
    const { tenant_id } = useClientsState();

  const times = LooksupTimeReservation(tenant_id);
  const { translate, userDetails, forgetPassword, translate_companies } =
    Locale;
  const [area, setArea] = useState([]);
  const [startValidate, setstartValidate] = useState(false);
  const dispatch = useClientsDispatch();
  const currentDate = moment(new Date());
  const limit = moment(currentDate).subtract(1, "days");
  const { listReservation } = useClientsState();
  /* intial value for data */
  const [reservationData, setreservationData] = useState({
    grade_id: "",
    type_id: client.type_id ? client.type_id : bookingType.selectedType,
    country_id: "20",
    city_id: "",
    street: "",
    building: "",
    floor: "",
    flat: "",
    phone: "",
    email: "",
    check_date: "",
    check_time:"",
    area_id: "",
  });
  

  /* fetch areas */
  useEffect(() => {
    async function fetchLookups() {
      if (reservationData?.city_id?.value) {
        const res = await fetchServiceAreas(reservationData.city_id?.value);
        const formatted = res.areas.map((area) => ({
          value: area.id,
          label: area.name,
        }));
        setArea(formatted);
      }
    }
    fetchLookups();
  }, [reservationData?.city_id?.value]);

  /* get data when edting*/ /* need to be looked conflict with validation */
  useEffect(() => {
    
    setreservationData({
      ...reservationData,
      check_date: client.check_date ? moment(client.check_date) : null,
      test_type: client.test_type,

      grade_id:
        client.grade_id && client.grade_id.hasOwnProperty("label")
          ? client.grade_id
          : /* {
              value: client.grade_id,
              label: client.grade_name,
            } */ "",

      country_id: "20",
      city_id:
        client.city_id && client.city_id.hasOwnProperty("label")
          ? client.city_id
          : {
              value: client?.city_id,
              label: client?.city,
            },
      area_id:
        client.area_id && client.area_id.hasOwnProperty("label")
          ? client.area_id
          : {
              value: client?.area_id,
              label: client?.area,
            },
      check_time:
        client.check_time && client.check_time.hasOwnProperty("label")
          ? client.check_time
          :  client.check_time !=""? {
              value: client.check_time,
              label: client.check_time,
            }:null,
      street: client.street,
      building: client.building,
      floor: client.floor,
      flat: client.flat,
      phone: client.phone,
      email: client.email,
      id: client.id,
      payment_status: client.payment_status,
      payment_status_flag: client.payment_status_flag,
    });
  }, [client]);


  /* to handel select feild */
  const handleChange = (e) => {
    if ("target" in e) {
      const value = e.target.value;
      const name = e.target.name;
      setreservationData({ ...reservationData, [name]: value });
    } else {
      const value = e.value;
      const name = e.name;
      setreservationData({ ...reservationData, [name]: value });
    }
  };

  /* to handel set error */
  const setErrorsReservationfiled = (name, value, role) => {
    let error = errorsReservation;
    error[index] = {
      ...error[index],
      ...validate({ name: name, value: value }, role),
    };
    setErrorsReservation(error);
  };

  /* handel errors flow */

  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  useEffect(() => {
    if (startValidate) {
      checkFormErrors();
      setDoneReservation(!doneReservation);
    }
    setstartValidate(true);
  }, [checkFormallErrorsReservation]);
  /* sending data to state */
  useEffect(() => {
    const sendData = setTimeout(() => {
      dispatch({
        type: "addReservation",
        data: {
          ...reservationData,
          check_date: moment(reservationData?.check_date).format("YYYY-MM-DD "),
          city_id: reservationData?.city_id?.value,
          area_id: reservationData?.area_id?.value,
          check_time: reservationData?.check_time?.value,
        },
        index: index,
      });
    }, 1000);

    return () => {
      clearTimeout(sendData);
    };
  }, [reservationData]);

  /* check errors in submit case */
  const checkFormErrors = () => {
    let error = errorsReservation;
    let newError = {
      ...validate(
        { name: "city_id", value: reservationData.city_id.value },
        { required: true }
      ),
      ...validate(
        { name: "street", value: reservationData.street },
        { required: true }
      ),
      ...validate(
        { name: "building", value: reservationData.building },
        { required: true }
      ),
      ...validate(
        { name: "floor", value: reservationData.floor },
        { required: true }
      ),
      ...validate(
        { name: "flat", value: reservationData.flat },
        { required: true }
      ),
      ...validate(
        { name: "phone", value: reservationData.phone },
        {
          required: true,
          min: 11,
        }
      ),
      ...validate(
        { name: "email", value: reservationData.email },
        { required: true }
      ),
      ...validate(
        { name: "check_date", value: reservationData.check_date },
        { required: true }
      ),
      ...validate(
        { name: "check_time", value: reservationData?.check_time?.value },
        { required: true }
      ),
      ...validate(
        { name: "area_id", value: reservationData.area_id.value },
        { required: true }
      ),
    };
    if (error[index]) {
      error[index] = {
        ...error[index],
        ...newError,
      };
    } else {
      error.splice(index, 0, newError);
    }
    setErrorsReservation(error);
  };

  /* save data reservation */

  /*  useEffect(async () => {
    if (isFormValid(errors)) {
      const res = await saveReservation(client.id, {
        ...reservationData,
        check_date: moment(reservationData?.check_date).format("YYYY-MM-DD"),
        city_id: reservationData?.city_id?.value.toString(),
        area_id: reservationData?.area_id?.value.toString(),
        grade_id: reservationData?.grade_id?.value.toString(),
        check_time: reservationData?.check_time?.label,
        total_coast: cost,
        test_id: testid,
      });

      if (res.status == 200 || res.status == 201) {
        toggle(client.id, cost);
      }
    }
  }, [isErrorLoaded]);
 */

  /*   const submit = () => {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }; */


  return doneReservation || !doneReservation ? (
    <div className="row py-2">
      <div className="col-12 col-md-3  mb-3 mb-md-2">
        <DatePicker
          hasLabel={true}
          disabled={reservationData.payment_status_flag === true}
          label={translate.examinationDate}
          placeholder={translate.examinationDatePlaceholder}
          date={reservationData.check_date}
          displayFormat={"YYYY-MM-DD"}
          minDate={limit}
          maxDate={tripdate ? moment(tripdate) : null}
          errors={errorsReservation[index]?.check_date}
          color={errorsReservation[index]?.check_date?.required ? "danger" : ""}
          onChangeDate={(e) => {
            setreservationData({
              ...reservationData,
              check_date: e,
            });
            setErrorsReservationfiled("check_date", e, {
              required: true,
            });
          }}
        ></DatePicker>
      </div>
      <div className="col-12 col-md-3  mb-3 mb-md-2">
        <SelectField
          onFocus={true}
          disabled={reservationData.payment_status_flag === true}
          hasLabel={true}
          options={times}
          label={translate_companies.examination_time}
          placeholder={translate_companies.examination_time}
          value={reservationData?.check_time}
          errors={errorsReservation[index]?.check_time}
          color={errorsReservation[index]?.check_time?.required ? "danger" : ""}
          onChange={(e) => {
            handleChange({ name: "check_time", value: e });
            setErrorsReservationfiled("check_time", e, {
              required: true,
            });
          }}
        />
      </div>
      <div className="col-12 col-md-3  mb-3 mb-md-0">
        <SelectField
          onFocus={true}
          disabled={reservationData.payment_status_flag === true}
          hasLabel={true}
          options={cities}
          label={translate.city}
          placeholder={translate.cityPlaceholder}
          value={reservationData?.city_id}
          errors={errorsReservation[index]?.city_id}
          color={errorsReservation[index]?.city_id?.required ? "danger" : ""}
          onChange={(e) => {
            handleChange({ name: "city_id", value: e });
            setErrorsReservationfiled("city_id", e, {
              required: true,
            });
          }}
        />
      </div>
      <div className="col-12 col-md-3  mb-3 mb-md-0">
        <SelectField
          onFocus={true}
          disabled={reservationData.payment_status_flag === true}
          hasLabel={true}
          options={area}
          label={translate.area}
          placeholder={translate.governoratePlaceholder}
          value={reservationData?.area_id}
          errors={errorsReservation[index]?.area_id}
          color={errorsReservation[index]?.area_id?.required ? "danger" : ""}
          onChange={(e) => {
            handleChange({ name: "area_id", value: e });
            setErrorsReservationfiled("area_id", e, {
              required: true,
            });
          }}
        />
      </div>

      <div className="col-12 col-md-6 mt-2   mb-1 ">
        <TextField
          hasLabel={true}
          className={"mb-1"}
          disabled={reservationData.payment_status_flag === true}
          label={translate.StName}
          placeholder={translate.StName}
          value={reservationData.street}
          name="street"
          errors={errorsReservation[index]?.street}
          color={errorsReservation[index]?.street?.required ? "danger" : ""}
          onChange={(e) => {
            setreservationData({
              ...reservationData,
              street: e.target.value,
            });
            setErrorsReservationfiled("street", e.target.value, {
              required: true,
            });
          }}
        ></TextField>
      </div>
      <div className="col-12 col-md-3 mt-2  mb-1 ">
        <TextField
          hasLabel={true}
          className={"mb-1"}
          disabled={reservationData.payment_status_flag === true}
          label={translate.phone}
          placeholder={userDetails.phoneNumberPlaceholder}
          value={reservationData.phone}
          name="phone"
          min={11}
          errors={errorsReservation[index]?.phone}
          color={
            errorsReservation[index]?.phone?.required ||
            errorsReservation[index]?.phone?.min
              ? "danger"
              : ""
          }
          onChange={(e) => {
            setreservationData({
              ...reservationData,
              phone: e.target.value,
            });
            setErrorsReservationfiled("phone", e.target.value, {
              required: true,
              min: 11,
            });
          }}
        ></TextField>
      </div>
      <div className="col-12 col-md-3 mt-2   mb-1 ">
        <TextField
          hasLabel={true}
          className={"mb-1"}
          disabled={reservationData.payment_status_flag === true}
          label={translate.BuildingNumber}
          placeholder={translate.BuildingNumber}
          value={reservationData.building}
          name="building"
          errors={errorsReservation[index]?.building}
          color={errorsReservation[index]?.building?.required ? "danger" : ""}
          onChange={(e) => {
            setreservationData({
              ...reservationData,
              building: e.target.value,
            });
            setErrorsReservationfiled("building", e.target.value, {
              required: true,
            });
          }}
        ></TextField>
      </div>
      <div className="col-12 col-md-3 mt-2   mb-1">
        <TextField
          hasLabel={true}
          className={"mb-1"}
          disabled={reservationData.payment_status_flag === true}
          label={translate.Floor}
          placeholder={translate.Floor}
          value={reservationData.floor}
          name="floor"
          errors={errorsReservation[index]?.floor}
          color={errorsReservation[index]?.floor?.required ? "danger" : ""}
          onChange={(e) => {
            setreservationData({
              ...reservationData,
              floor: e.target.value,
            });
            setErrorsReservationfiled("floor", e.target.value, {
              required: true,
            });
          }}
        ></TextField>
      </div>

      <div className="col-12 col-md-3 mt-2   mb-1">
        <TextField
          hasLabel={true}
          className={"mb-1"}
          disabled={reservationData.payment_status_flag === true}
          label={translate.ApartmentNumber}
          placeholder={translate.ApartmentNumber}
          value={reservationData.flat}
          name="flat"
          errors={errorsReservation[index]?.flat}
          color={errorsReservation[index]?.flat?.required ? "danger" : ""}
          onChange={(e) => {
            setreservationData({
              ...reservationData,
              flat: e.target.value,
            });
            setErrorsReservationfiled("flat", e.target.value, {
              required: true,
            });
          }}
        ></TextField>
      </div>
      <div className="col-12 col-md-6 mt-3 ">
        <TextField
          hasLabel={true}
          className={"mb-1"}
          disabled={reservationData.payment_status_flag === true}
          label={translate.email}
          placeholder={forgetPassword.emailPlaceholder}
          value={reservationData.email}
          name="email"
          errors={errorsReservation[index]?.email}
          color={errorsReservation[index]?.email?.required ? "danger" : ""}
          onChange={(e) => {
            setreservationData({
              ...reservationData,
              email: e.target.value,
            });
            setErrorsReservationfiled("email", e.target.value, {
              required: true,
            });
          }}
        ></TextField>
      </div>

      {listClients.length > 1 && (
        <div className="col-12 d-flex justify-content-between align-items-end py-3 font-mobile">
          <Checkbox
            hasLabel={true}
            className="radio_bnt font-mobile"
            disabled={reservationData.payment_status_flag === true}
            label={translate.UseDateForAll}
            labelClassName={"font-mobile"}
            onChange={(e) => {
              fillClients(
                {
                  ...reservationData,
                  total_coast: cost,
                },
                e.target.checked
              );
            }}
          />
        </div>
      )}
    </div>
  ) : (
    ""
  );
}
