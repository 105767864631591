import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import Locale from "translations";
import { Tooltip } from "reactstrap";
import moment from "moment";
import ShowForPermission from "helpers/showForPermission";

function Traveler({ client, index }) {
  const { translate } = Locale;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const { id } = useParams();
  let history = useHistory();

  return (
    <tr>
      <td>{client.id}</td>
      <td>{client.test_name}</td>
      <td>{client.traveler_name}</td>
      <td>{client.type}</td>
      <td>{client.grade_name}</td>
      <td>{client.created_at}</td>
      <td>{client.total_cost} </td>
      <td
        className={`font-weight-bold ${
          client.payment_status_flag === true ? "text-success" : " text-danger"
        }`}
      >
        {client.payment_status}
      </td>

      <td >
        <p className="w-100 m-0">
          {client.check_date_end
            ? moment(client.check_date_end, "YYYY-MM-DD HH:mm aa").format(
                "YYYY-MM-DD"
              )
            : " "}
        </p>

        <p className="m-0 w-100">{client.check_time}</p>
      </td>

      <td className="d-flex">
        <ShowForPermission permission={"view-reservation"}>
          <button
            className="btn btn-outline-primary d-flex align-items-center  justify-content-center p-1 "
            onClick={() => {
              history.push(`/view-result/${client.trip_id}`);
            }}
          >
            <i class="fas fa-eye mx-1 "></i>
            <p className="m-0 mx-1">{translate.view}</p>
          </button>
        </ShowForPermission>

        <ShowForPermission
          permission={["update-reservation", "view-reservation"]}
          type={"and"}
        >
          {/*           {client.payment_status_flag === true && id != "done" ? (
            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              target={`Tooltip${index}`}
              toggle={toggle}
              className={"bg-info"}
              innerClassName={"bg-info"}
              arrowClassName={"bg-info"}
              hideArrow={true}
            >
              <span className="lan">{translate.CantModified}</span>
            </Tooltip>
          ) : (
            " "
          )} */}

          {id != "done" && (
            <button
              className={`btn btn-outline-primary d-flex align-items-center  justify-content-center p-1 mx-2 ${
                client.payment_status_flag === true ? "text-secondary" : ""
              }`}
              /*        id={`${
                client.payment_status_flag === true ? `Tooltip${index}` : " "
              }`} */

              onClick={() => {
                if (client.payment_status_flag != true) {
                  history.push(`/medical/services/${client.trip_id}`);
                }
              }}
              disabled={client.payment_status_flag === true}
            >
              <i class="fas fa-edit mx-1"></i>
              <p className="m-0 mx-1">{translate.edit}</p>
            </button>
          )}
        </ShowForPermission>
      </td>
    </tr>
  );
}

export default Traveler;
