import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { store } from "react-notifications-component";

import Locale from "../../../translations";

import { ListUser } from "../../../services/admin";

import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import ShowForPermissionAdmin from "helpers/ShowForPermissionAdmin";
import { useAdminState } from "context/Admin/global";
import { useCompanyState } from "context/global";
export default function User(props) {
  const { company } = props;
  const { translate, translate_companies } = Locale;
 
  const [modalRejected, setModalRejected] = useState(false);
  const [modalAccepted, setModalAccepted] = useState(false);

  const [usersDB, setUsersDB] = useState([]);
  const [users, setUsers] = useState("");

  const [filter, setFilter] = useState({
    admin_type: localStorage.getItem("Admin_userType"),
    status: "",
    q: "",
  });
  let { Admin_permissions, is_admin } = useAdminState();

 



  useEffect(() => {
    async function fetchData() {
      const UsersDp = await ListUser(filter);
      if (UsersDp.status === 200) {
        const format = UsersDp.data?.data?.map((e) => ({
          value: e.id,
          label: e.name,
        }));
        setUsersDB(format);
      }
    }

    if (
      Admin_permissions.includes("list-admins") ||
      Admin_permissions.length === 0
    ) {
      fetchData();
    }
  }, []);

  const handleChangeUsers = (event) => {
    setUsers(event.target.value.target.value);
  };

  const toggleRejected = (e) => {
    setModalRejected(!modalRejected);
  };

  const toggleAccepted = (e) => {
    setModalAccepted(!modalAccepted);
  };

  const validationSchema = yup.object({
    message: yup
      .string(translate_companies.rejectReasonsInputRequired)
      .min(5, translate_companies.rejectReasonsErrorMin)
      .max(500, translate_companies.rejectReasonsErrorMax)
      .required(translate_companies.rejectReasonsInputRequired),
  });

  const formik = useFormik({
    initialValues: {
      message: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      let payload = {
        rejection_reason: values.message,
      };
      
      props.rejectCompany(company.id, payload);
      toggleRejected();
    },
  });

  const validationSchemaAccepted = yup.object({
    manager: yup
      .string()
      .required(translate_companies.accept_userRequired)
      .nullable(),
  });

  const formikAccepted = useFormik({
    initialValues: {
      manager: "",
    },

    validationSchema: validationSchemaAccepted,
    onSubmit: (values) => {
      let payload = {
        manager_id: values.manager,
      };
      
      props.changeStatusCompany(company.id, payload);
      toggleAccepted();
    },
  });

  return (
    <>
      <tr>
        <td className="CompanyRow-title">{company.name}</td>
        <td className="CompanyRow-title">{company.admin_email}</td>
        <td className="CompanyRow-title">{company.phone}</td>
        <td
          className={
            company.status === "active" ? "text-green" : "text-warning"
          }
        >
          {" "}
          {company.status}
        </td>
        <td>
          <div className="actions">
            <span className="text-primary">
              <button
                type="button"
                onClick={() => toggleAccepted(company.id)}
                className="btn btn-link text-success accepted"
              >
                <i class="fas fa-check-circle mx-1"></i>
                {translate_companies.accept}
              </button>

              <button
                type="button"
                onClick={() => toggleRejected(company.id)}
                className="btn btn-link text-danger rejected"
              >
                <i class="fas fa-times-circle mx-1"></i>
                {translate_companies.reject}
              </button>
            </span>
          </div>
        </td>
      </tr>

      <Modal isOpen={modalRejected} toggle={toggleRejected}>
        <div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
          <p className="h3 text-secondary ">
            {translate_companies.reject_modal_title}
          </p>
          <i
            className="far fa-times-circle fa-lg text-secondary "
            onClick={toggleRejected}
          ></i>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <p className="h3 text-secondary reject-model-des">
                  {translate_companies.reject_modal_des}
                </p>
                <TextField
                  className="companies-reject-modal"
                  id="message"
                  name="message"
                  label={translate_companies.reject_modal_reasons_input}
                  multiline
                  rows={4}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.message && Boolean(formik.errors.message)
                  }
                  helperText={formik.touched.message && formik.errors.message}
                  variant="filled"
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="CompanyReject-button" type="submit">
              {translate_companies.reject}
            </Button>{" "}
            <Button color="secondary" onClick={toggleRejected}>
              {translate.cancel}
            </Button>
          </ModalFooter>
        </form>
      </Modal>

      <Modal

        isOpen={modalAccepted}
        toggle={toggleAccepted}
      >
        <div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
          <p className="h3 text-secondary ">
            {translate_companies.accept_modal_title}
          </p>
          <i
            className="far fa-times-circle fa-lg text-secondary "
            onClick={toggleAccepted}
          ></i>
        </div>
        <form onSubmit={formikAccepted.handleSubmit}>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <p className="h3 text-secondary reject-model-des lan">
                  {translate_companies.accept_modal_des}
                </p>

                <FormControl
                  className={`WorkTeamInput ${
                    formikAccepted.touched.manager &&
                    formikAccepted.errors.manager
                      ? "WorkTeamInputError"
                      : ""
                  }`}
                  variant="filled"
         
                >
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    shrink={true}
                  >
                    {translate_companies.accept_manager}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={formikAccepted.values.manager}
                    name="manager"
                  
                    dir="rtl"
                    onChange={(selectedOption) => {
                      
                      let event = {
                        target: { name: "manager", value: selectedOption },
                      };
                      handleChangeUsers(event);
                      formikAccepted.handleChange(selectedOption);
                    }}
                  >
                    {usersDB.length > 0 ? (
                      usersDB.map((user) => (
                        <MenuItem
                          key={user.value}
                          value={user.value}
                          dir="rtl"
                     
                        >
                          {user.label}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem dir="rtl" className="lan text-danger">
                        {translate.listAdminsPer}{" "}
                      </MenuItem>
                    )}
                  </Select>
                  {formikAccepted.touched.manager &&
                  Boolean(formikAccepted.errors.manager) ? (
                    <FormHelperText
                      className={"GroupsInputError"}
                      id="component-error-text"
                    >
                      {formikAccepted.errors.manager}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="CompanyAccepted-button lan" type="submit">
              {translate_companies.accept}
            </Button>{" "}
            <Button className="lan" color="secondary" onClick={toggleAccepted}>
              {translate.cancel}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}
