import logoImg from "assets/images/Image1.png";
import Checkbox from "components/shared/checkbox";
import TextField from "components/shared/textField";
import { useCompanyDispatch } from "context/global";
import React, { useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { showCompany } from "services/admin";
import { forgetPassword, resetPassword, singin } from "services/yarvel";
import Locale from "translations";

export default function ForgetPassword() {
    let history = useHistory();
     const urlSearchParams = new URLSearchParams(window.location.search);
     const params = Object.fromEntries(urlSearchParams.entries());
  const [user, setUser] = useState({
    email: params?.email,
    password: "",
    password_confirmation: "",
  });

    
   
    

  const sendPassword = async () => {
    const res = await resetPassword({ ...user, token: params.token });
    if (res?.status === 200) {
      store.addNotification({
        title: "info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
           history.push("/login");
        
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center mt-15">
      <figure className="logo w-25">
        <img className="w-100" src={logoImg} alt="logo" />
      </figure>

      <div className="d-flex flex-column ml-5 w-25 ">
        <div className="mt-4">
          {/*      <TextField
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          >
            <i className="fas fa-user text-primary"></i>
          </TextField>{" "} */}
          <TextField
            value={user.password}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
            placeholder={"ادخل كلمة المرور"}
            type={"password"}
          >
            <i className="fas fa-lock text-primary"></i>
          </TextField>{" "}
        </div>
        <TextField
          value={user.password_confirmation}
          onChange={(e) =>
            setUser({ ...user, password_confirmation: e.target.value })
          }
          placeholder={"تاكيد كلمة المرور"}
          type={"password"}
        >
          <i className="fas fa-lock text-primary"></i>
        </TextField>{" "}
        <button className="btn btn-primary mt-4" onClick={sendPassword}>
          تاكيد كلمة المرور
        </button>
      </div>
    </div>
  );
}
