/*  import libphonenumber from "google-libphonenumber";
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance(); */

export const isFormValid = (errors) => {
  let errorsList = [];
  if (errors != undefined && Object.values(errors).length > 0) {
    Object.values(errors).map((value, i) => {
      errorsList = [...errorsList, ...Object.values(value)];
    });
    return errorsList.every((value) => !value);
  } else {
    return false;
  }
};

/*export const validatePhone = (phone, code) => {
	// TODO: fix this
	return true;

	try {
		return phoneUtil.isValidNumberForRegion(
			phoneUtil.parse(phone.toString(), code),
			code
		);
	} catch (err) {
		return false;
	}
}; */

export default function validate(target, rules) {
  const { name, value } = target;
  const errors = { [name]: {} };

  if (rules.required) {
    if (value === "" || value == undefined || value == null || value == false) {
      errors[name]["required"] = true;
    } else {
      errors[name]["required"] = false;
    }
  }

  if (rules.min) {
    if (value != undefined && value.length >= rules.min) {
      errors[name]["min"] = false;
    } else {
      errors[name]["min"] = true;
    }
  }

  if (rules.max) {
    if (value != undefined && value.length > rules.max) {
      errors[name]["max"] = false;
    } else {
      errors[name]["max"] = true;
    }
  }

  if (rules.maxNumber) {
    if (value != undefined && +value <= rules.maxNumber) {
      errors[name]["maxNumber"] = false;
    } else {
      errors[name]["maxNumber"] = true;
    }
  }

  if (rules.minNumber) {
    if (value != undefined && +value >= rules.minNumber) {
      errors[name]["minNumber"] = false;
    } else {
      errors[name]["minNumber"] = true;
    }
  }

  if (rules.email) {
    const emailRegex =
      /(?=^.{10,90}$)^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

    if (emailRegex.test(value)) {
      errors[name]["email"] = false;
    } else {
      errors[name]["email"] = true;
    }
  }

  if (rules.number) {
    const numberRegex = /^((?!501|504)[0-9]*)$/;

    if (numberRegex.test(value)) {
      errors[name]["number"] = false;
    } else {
      errors[name]["number"] = true;
    }
  }

  if (rules.notNumber) {
    const notNumberRegex = /^[a-zA-Zء-ي_ ]*$/;

    if (notNumberRegex.test(value)) {
      errors[name]["notNumber"] = false;
    } else {
      errors[name]["notNumber"] = true;
    }
  }



  
  if (rules.password) {
    const passwordRegex = /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/g;

    if (passwordRegex.test(value)) {
      errors[name]["password"] = false;
    } else {
      errors[name]["password"] = true;
    }
  }

  if (rules.formula) {
    const formulaRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/g;

    if (formulaRegex.test(value)) {
      errors[name]["formula"] = false;
    } else {
      errors[name]["formula"] = true;
    }
  }

  if (rules.confirm) {
    if (value) {
      errors[name]["confirm"] = false;
    } else {
      errors[name]["confirm"] = true;
    }
  }

  if (rules.phone) {
    if (value) {
      errors[name]["phone"] = false;
    } else {
      errors[name]["phone"] = true;
    }
  }

  if (rules.date) {
    if (value) {
      errors[name]["date"] = false;
    } else {
      errors[name]["date"] = true;
    }
  }

  return errors;
}
