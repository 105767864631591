import React, { useEffect, useState } from "react";
import Locale from "../../../translations";
import { useHistory } from "react-router-dom";

import FilterGroup from "./filterGroup";
import { NavLink } from "react-router-dom";
import Collaps from "./Collapse";
import UserNumber from "./userNumber";
import { ListGroups, ListUser } from "../../../services/yarvel";
import ShowForPermission from "helpers/showForPermission";
import { useCompanyState } from "context/global";
import useUserDetails from "helpers/useUserDetalis";

export default function Groups() {
  const { translate } = Locale;
  let history = useHistory();
  const [Groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const { permissions, is_admin } = useCompanyState();
  const { details } = useUserDetails();
  useEffect(() => {
    async function fetchData() {
      const User = await ListGroups();
      setGroups(User?.data);
    }
    fetchData();

    async function fetchUserData() {
      
      const User = await ListUser();
      setUsers(User?.data.data);
    }
    if (permissions.includes("list-admins") || is_admin=== true) {
      fetchUserData();
    }
  }, []);

  const AllGroups =
    Groups && Groups?.data?.length > 0
      ? Groups?.data?.map((Group, index) => (
          <Collaps Group={Group} index={index} details={details} />
        ))
      : "";

  return (
    <section>
      <UserNumber users={users} />
      <div className="container">
        <div className="d-flex justify-content-between mt-5 mb-2">
          <h3 className="text-muted font-weight-bold">
            {translate.teamManagement}
          </h3>

          <ShowForPermission permission={"create-group"}>
            <NavLink
              exact={true}
              to={`/team-management/groups/add`}
              activeClassName="is-active"
            >
              <button
                type="button"
                className="btn btn-success px-4 py-2 font-weight-bold "
                onClick={() => {
                  history.push("/team-management/groups");
                }}
              >
                <i className="fas fa-plus-circle mr-2"></i>
                {translate.addGroup}
              </button>
            </NavLink>
          </ShowForPermission>
        </div>
        <header className="row  list_header border py-3 px-2  justify-content-center ">
          <div className="col-12 col-md-10">
            <FilterGroup />
          </div>

          <div className=" mt-3 mt-md-0">
            <ShowForPermission permission={"list-admins"}>
              <NavLink
                exact={true}
                to={`/team-management/users`}
                activeClassName="is-active"
                className="btn-nav  mx-1 px-3 bg-brwon rounded text-muted"
              >
                {translate.users}
              </NavLink>
            </ShowForPermission>
            <ShowForPermission permission={"list-groups"}>
              <NavLink
                exact={true}
                to={`/team-management/groups`}
                activeClassName="is-active"
                className="btn-nav text-bold mx-1 px-3 bg-brwon rounded text-muted"
              >
                {translate.groups}
              </NavLink>
            </ShowForPermission>
          </div>
        </header>

        <div className="Collapse">{AllGroups}</div>
      </div>
    </section>
  );
}
