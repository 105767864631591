import React from 'react'
import Locale from "translations";
export default function TripInfo({ trip }) {
  const { translate } = Locale;
  return (
    <div className="border bg-white rounded mt-4">
      <h5 className="font-weight-bold p-3 text_blue-dark mt-2 m-0">
        {translate.TripData}
      </h5>

      <div className=" px-3  row">
        <p className="col-6  col-md-3 h5 font-mobile   font-weight-bold">PNR</p>
        <p className="col-6  col-md-3 h5 font-mobile ">
          {trip?.pnr}
        </p>

        <p className="col-6  col-md-3 h5 font-mobile   font-weight-bold">
          {translate.DepartureAirport}
        </p>
        <p className="col-6  col-md-3 h5 font-mobile ">
          {trip.departure_port_name}
        </p>

        <p className="col-6  col-md-3 h5 font-mobile   font-weight-bold">
          {translate.destnation}
        </p>
        <p className="col-6  col-md-3 h5 font-mobile ">
          {trip.destination_name}
        </p>
        <p className="col-6  col-md-3 h5 font-mobile  font-weight-bold">
          {translate.departureDate}
        </p>
        <p className="col-6  col-md-3 h5 font-mobile ">
          {" "}
          {trip.departure_date}
        </p>
        <p className="col-6  col-md-3 h5 font-mobile font-weight-bold">
          {translate.Transporter}
        </p>
        <p className="col-6  col-md-3 h5 font-mobile ">
          {trip.transporter_name}
        </p>
        <p className="col-6  col-md-3 h5 font-mobile font-weight-bold">
          {translate.ArrivalAirport}
        </p>
        <p className="col-6  col-md-3 h5 font-mobile ">
          {trip.arrival_port_name}
        </p>
        <p className="col-6  col-md-3 h5 font-mobile font-weight-bold">
          {translate.TripNumber}
        </p>
        <p className="col-6  col-md-3 h5 font-mobile ">{trip.trip_num}</p>

        <p className="col-6  col-md-3 h5 font-mobile font-weight-bold">
          {translate.ArrivalTime}
        </p>
        <p className="col-6  col-md-3 h5 font-mobile ">{trip.arrival_date}</p>
      </div>
    </div>
  );
}
