import Loader from "components/Loader";
import { useAuthState } from "context/auth";
import { useCompanyState } from "context/global";
import EmailConfirmation from "modules/EmailConfirmation";
import CompanyDetails from "modules/Yarvel/auth/components/CompanyDetails";
import UserDetails from "modules/Yarvel/auth/components/UserDetails";
import RegistrationLayout from "modules/Yarvel/auth/layout/RegistrationLayout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { accountRegister, accountUpdate } from "services/auth";
import { fetchCountries } from "services/lookups";
import Locale from "translations";

export default function Register() {
  // ----------------------------------------------------------------------------------------------------
  // Component States / Variables / Constants
  const { currentLanguage } = useCompanyState();
  const registerData = useAuthState();
  const [companyLogo, setCompanyLogo] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);
  const [lookups, setLookups] = useState({
    countries: []
  });

  Locale.setLanguage(currentLanguage);
  const history = useHistory();

  // ----------------------------------------------------------------------------------------------------
  // Component Functions
  // ----------------------------------------------------------------------------------------------------
  const updateLogo = (file) => {
    setCompanyLogo(file);
  };

  // Register New Account
  const registerAccount = async () => {
    const formdata = new FormData();
    formdata.append("user[name]", registerData.userDetails?.name);
    formdata.append("user[email]", registerData.userDetails?.email);
    formdata.append("user[password]", registerData.userDetails?.password);
    formdata.append(
      "user[password_confirmation]",
      registerData.userDetails?.confirmPassword
    );
    formdata.append(
      "user[phone_number]",
     /*  registerData.userDetails?.phoneCode +  */registerData.userDetails?.phone
    );
    formdata.append("company[name]", registerData.companyDetails?.name);
    formdata.append("company[address]", registerData.companyDetails?.address);
    formdata.append(
      "company[phone]",
      registerData.companyDetails?.phoneCode +
        registerData.companyDetails?.phone
    );
    formdata.append(
      "company[business_email]",
      registerData.companyDetails?.email
    );
    formdata.append("company[avatar]", registerData.avatarID);
    formdata.append(
      "company[country_id]",
      registerData.companyDetails?.country?.value
    );
    formdata.append(
      "company[city_id]",
      registerData.companyDetails?.city?.value
    );
    formdata.append(
      "company[extra_data][phone]",
      registerData.companyDetails?.secondPhone
        ? registerData.companyDetails?.phoneCode +
            registerData.companyDetails?.secondPhone
        : null
    );

    const rejectedFormData = new FormData();
    rejectedFormData.append("id", history.location?.state?.id);
    rejectedFormData.append("name", registerData.userDetails?.name);
    rejectedFormData.append("business_email", registerData.userDetails?.email);
    rejectedFormData.append("address", registerData.companyDetails?.address);
    rejectedFormData.append(
      "phone",
      registerData.companyDetails?.phoneCode +
        registerData.companyDetails?.phone
    );
    rejectedFormData.append("avatar", registerData.avatarID);
    rejectedFormData.append(
      "country_id",
      registerData.companyDetails?.country?.value
    );
    rejectedFormData.append(
      "city_id",
      registerData.companyDetails?.city?.value
    );
    rejectedFormData.append(
      "extra_data[phone]",
      registerData.companyDetails?.secondPhone
        ? registerData.companyDetails?.phoneCode +
            registerData.companyDetails?.secondPhone
        : registerData.companyDetails?.phoneCode +
            registerData.companyDetails?.phone
    );
    rejectedFormData.append("_method", "put");
    // Register New Account
    setApiCalled(true);
    const company = history.location.state;
    const response = company
      ? await accountUpdate(rejectedFormData, company.token)
      : await accountRegister(formdata);
    if (response.status === 201 || response.status === 200) {
      setApiCalled(false);
      history.push("/register/success");
    } else {
      setApiCalled(false);
    }
  };

  const fetchLookups = async () => {
    const [countries] = await Promise.all([fetchCountries()]);

    const formatCountries = countries.map((t) => ({
      value: t.id,
      label: t.names[currentLanguage],
      code: t.code
    }));

    setLookups({
      lookups,
      countries: formatCountries
    });
  };
  useEffect(() => {
    fetchLookups();
  }, []);

  // Return View
  const currentStepView = () => {
    switch (registerData.step) {
      case 1:
        return (
          <UserDetails
            countries={lookups.countries}
            isCountriesLoaded={lookups.countries.length > 0}
          />
        );
      case 2:
        return (
          <CompanyDetails
            countries={lookups.countries}
            isCountriesLoaded={lookups.countries.length > 0}
          />
        );
      case 3:
        return (
          <EmailConfirmation
            mainAction={registerAccount}
            isDisabled={apiCalled}
          />
        );
      default:
        return (
          <UserDetails
            countries={lookups.countries}
            isCountriesLoaded={lookups.countries.length > 0}
          />
        );
    }
  };

  if (lookups.countries.length > 0) {
    return (
      <>
        <RegistrationLayout>{currentStepView()}</RegistrationLayout>
      </>
    );
  } else {
    return <Loader />;
  }
}
