import React from "react";
import Locale from "translations";
import Logo from "assets/images/Image1.png";
import Coverimage from "assets/images/login_image.jpg";

export default function LoginBanner(props) {
	const { loginBanner } = Locale;

	return (
    <div
      className="login-banner text-center d-flex  justify-content-center align-items-center"
      /* style={{ background: `url(${Coverimage})` }} */
    >
      <img className="login-banner__logo" src={Logo} alt="logo" />
      <div className="text-light text-title">
        {/* 			<h4>{loginBanner.title1}</h4>
				<h4 className="mt-2">{loginBanner.title2}</h4> */}
      </div>
    </div>
  );
}
