import React from "react";
import Locale from "translations";

export default function Pagination(props) {
  const { commons } = Locale;

  return (
    <>
      {props.info?.total > props.items ? (
        <div className="row justify-content-between align-items-center mb-2 mr-2 ml-2">
          <div className="text-body col-12 col-md-3">
            {commons.show} {props.info.from + " "}
            {commons.to} {props.info.to} {commons.of} {props.info?.total}{" "}
            {commons.entries}
          </div>
          <nav aria-label="  Page navigation example col-12 col-md-8" >
            <ul className="pagination my-4 row">
              {props.info?.links?.map((page) => {
                let string = page?.url?.lastIndexOf("page=");
                let goToPage = page?.url?.substring(string + 5);
                return (
                  <li
                    key={`page-${page.label}`}
                    className={`${page.active ? "page-item active" : "page-item"}`}
                    onClick={() => {
                      props.goTo(goToPage);
                    }}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      dangerouslySetInnerHTML={{ __html: page.label }}
                      className={`page-link ${
                        page.url === null ? "false" : ""
                      }`}
                    ></span>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      ) : null}
    </>
  );
}
