import React from "react";
import walletAdd from "assets/images/wallet-add.svg";

export default function Card({ children, label }) {
  return (
    <div className="create-request">
      <div className="row justify-content-center no-gutters">
        <div className="col-12 col-md-4 card">
          <img src={walletAdd} alt="wallet icon" />
          <b className="my-2">{label}</b>
          {children}
        </div>
      </div>
    </div>
  );
}
