import React from "react";
import Header from "./Header";
import MobileHeader from "./MobileHeader";
export default function Layout(props) {
  const isAuth = localStorage.getItem("isAuth");
  const width = window.innerWidth;
  return (
    <>
      {isAuth && width > 768 ? (
        <Header />
      ) : isAuth && width <= 768 ? (
        <MobileHeader />
      ) : null}

      <section>{props.children}</section>
    </>
  );
}
