import logoImg from "assets/images/Image1.png";
import Checkbox from "components/shared/checkbox";
import TextField from "components/shared/textField";
import { useCompanyDispatch } from "context/global";
import React, { useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { showCompany } from "services/admin";
import { singin } from "services/yarvel";
import Locale from "translations";

export default function Login() {
  let history = useHistory();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const { translate, emailConfirmation } = Locale;
  const dispatch = useCompanyDispatch();

  const showRejectedCompany = async (id, token) => {
    return await showCompany(id, token);
  };

  const login = async () => {
    const res = await singin(user);
    if (res?.data?.data?.status === "rejected") {
      //   localStorage.setItem("token", res.data.data.access_token);
      const company = await showRejectedCompany(
        res.data.data.company_id,
        res.data.data.access_token
      );

      const companyState = {
        ...company.data.companies,
        token: res.data.data.access_token,
      };

      store.addNotification({
        title: `${translate.rejected}!`,
        message: `${res.data.data.rejection_reason || translate.rejected} ${
          translate.tryTo
        }`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
        onRemoval: () => {
          history.push({
            pathname: "/register",
            state: companyState,
          });
        },
      });
    } else if (res?.status === 200) {
      dispatch({ type: "login", payload: res?.data?.data });
      history.push("/");
    } /* else {

      store.addNotification({
        title: `${translate.somethingWentWrong}!`,
        message: `${res?.data}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    } */
  };
  const [seePass, setSeePass] = useState(false);
  const seePassword = () => {
    //debugger;
    setSeePass(!seePass);
  };
  return (
    <div className="row no-gutters login-banner align-items-center justify-content-center ">
      <figure className="logo col-8 col-md-3 ">
        <img className="w-100" src={logoImg} alt="logo" />
      </figure>

      <div className="d-flex flex-column col-8 col-md-3 ">
        <div className="mt-4">
          <TextField
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          >
            <i className="fas fa-user text-primary"></i>
          </TextField>
        </div>
        <div className="mt-4">
          <TextField
            value={user.password}
            type={seePass ? "text" : "password"}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
          >
            <i
              className={`fas fa-${seePass ? "eye-slash" : "eye"} text-primary`}
              onClick={() => {
                seePassword();
              }}
            ></i>
          </TextField>
        </div>
        <div className="mt-4">
          <Checkbox
            hasLabel={true}
            className="radio_bnt"
            label={translate.RememberMe}
          />
        </div>
        <button className="btn btn-primary mt-4" onClick={login}>
          {translate.signIn}
        </button>
        <Link to="/register" className="btn-link text-center mt-3">
          {emailConfirmation.createEmail}{" "}
        </Link>
        <Link to="/reset-password" className="btn-link text-center mt-3">
          نسيت كلمة المرور
        </Link>
      </div>
    </div>
  );
}
