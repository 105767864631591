import { useClientsDispatch } from "context/addClient";
import React, { useEffect, useState } from "react";
import { getClientsReservation } from "services/yarvel";
import Locale from "translations";
import Traveler from "./Traveler";

export default function PcrReservation({
  switchTo,
  setSwitchTo,
  toggle,
  service,
  errorsReservation,
  setErrorsReservation,
  checkFormallErrorsReservation,
  doneReservation,
  setDoneReservation
}) {
  const { translate } = Locale;
  const [clients, setClients] = useState([]);
  const [tripdate, setTripDate] = useState();
  const [Show, setShow] = useState(false);

  /* call api for list clients */
  useEffect(() => {
    async function fetchListClients() {
      if (switchTo.tripId) {
        const list = await getClientsReservation(switchTo.tripId);
        if (list.status === 201 || list.status === 200) {
          setClients(list.data.data.reservations);
          setTripDate(list.data.data.Trip.departure_date);
        }
      }
    }

    fetchListClients();
  }, []);

  /* use all data button function */
  const fillClients = (data, checked) => {
    let clientsfree = clients;
    if (checked) {
      let clientsfree = clients;
      clientsfree = clientsfree.map((Response) => {
        if (Response.payment_status === "مدفوع") {
          return (Response = { ...Response });
        } else {
          return (Response = { ...Response, ...data, id: Response.id });
        }
      });
      setClients(clientsfree);
      setShow(!Show);
    }
  };

  return (
    <section>
      <div className="">
        {(clients.length > 0 && !Show) || (Show && clients.length > 0)
          ? clients.map((client, index) => {
              return (
                <Traveler
                  tripdate={tripdate}
                  client={client}
                  index={index}
                  key={index}
                  checkFormallErrorsReservation={checkFormallErrorsReservation}
                  service={service}
                  setErrorsReservation={setErrorsReservation}
                  errorsReservation={errorsReservation}
                  switchTo={switchTo}
                  toggle={toggle}
                  fillClients={fillClients}
                  doneReservation={doneReservation}
                  setDoneReservation={setDoneReservation}
                />
              );
            })
          : []}
      </div>

      {/*     <div className=" list_header pb-4 border my-4 bg-white">
        <header className=" list_header--bottom  py-3 px-2 mb-3 d-flex justify-content-between">
          <h3 className="text-muted font-weight-bold">{translate.Cost}</h3>
        </header>
        <div className="d-flex justify-content-center py-3">
          <div className="cost_box border p-3 ">
            <h6>{translate.travelersNumber}</h6>
            <p className="text-bold h5">2</p>
          </div>
          <div className="cost_box border p-3 mx-4">
            <h6>{translate.TotalCost}</h6>
            <p className="text-bold h5">2225 EGP</p>
          </div>
        </div>
      </div> */}
    </section>
  );
}
