import { Redirect } from "react-router-dom";

export default function reducers(state, action) {
  switch (action.type) {
    case "setLoading":
      return { ...state, loading: action.payload };

    case "RedirectToHome": {
      return { ...state, havePermission: action.payload };
    }

    case "setLocale":
      localStorage.setItem("currentLanguage", action.payload);
      window.location.reload();
      return { ...state, currentLanguage: action.payload };

    case "setBalance":
      return {
        ...state,
        balance: action.balance,
        balanceCreditor: action.creditor,
        balanceDebtor: action.debtor,
      };

    case "setBalanceCredit":
      return {
        ...state,
        safaWallet: action.balance,
        safaLimit: action.limit,
        safaCreditor: action.creditor,
        safaDebtor: action.debtor,
      };

    case "logout":
      localStorage.clear();
      window.location.href = "/login";
      return { ...state };

    case "getPermissions":
      localStorage.setItem("userType", action.payload.user_type);
      localStorage.setItem(
        "permissions",
        JSON.stringify(action.payload.permissions)
      );
      localStorage.setItem(
        "country_id",
        JSON.stringify(action.payload.company.country_id)
      );

      return {
        ...state,
        permissions: action.payload.permissions,
        userType: action.payload.user_type,
        is_admin: action.payload.is_admin,
        country_id: action.payload.company.country_id,
      };
      case "getTokenApi":
        localStorage.setItem(
          "token_api",
          action.payload
        );
        return {
          ...state,
          token_api: action.payload,
        };

    case "login":
      /*   localStorage.setItem("currentLanguage", "ar"); */
      localStorage.setItem("isAuth", true);
      localStorage.setItem("token", action.payload.access_token);
      localStorage.setItem("userType", action.payload.user_type);
      localStorage.setItem("company_id", action.payload.company_id);
      localStorage.setItem("company_name", action.payload.company_name);
      localStorage.setItem(
        "permissions",
        JSON.stringify(action.payload.permissions)
      );
      return {
        ...state,
        isAuth: true,
        token: action.payload.access_token,
        permissions: action.payload.permissions,
        userType: action.payload.user_type,
        company_id: action.payload.company_id,
        is_admin: action.payload.is_admin,
      };
    // eslint-disable-next-line no-fallthrough
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
