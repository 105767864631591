import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import Locale from "translations";
import { Link, useHistory } from "react-router-dom";

import { getNotification, readNotification } from "services/yarvel";
import HeaderNotify from "./HeaderNotify";

export default function Notifications({ isMobile }) {
  const { translate } = Locale;
  let history = useHistory();
  const [notifications, setNotifications] = useState();

  useEffect(async () => {
    const res = await getNotification({ page: 1 });
    if (res.status === 200) {
      setNotifications(res.data.data);
    }
  }, []);

  const readNotify = async (id, url) => {
    const res = await readNotification(id);
    window.location.href = `${url}`;
  };

  const AllNotifiesTitle =
    notifications && notifications?.length > 0 ? (
      notifications.slice(0, 3).map((data, index) => {
        return (
          <HeaderNotify
            key={index}
            data={data}
            index={index}
            readNotify={readNotify}
          />
        );
      })
    ) : (
      <DropdownItem tag="button" className={`d-flex justify-content-between`}>
        {translate.noNotifications}
      </DropdownItem>
    );

  return (
    <>
      {" "}
      <UncontrolledDropdown setActiveFromChild className="notification">
        <DropdownToggle
          data-toggle="dropdown"
          tag="button"
          className="h5 text-primary d-flex align-items-center"
          caret
        >
          <i class="fas fa-bell notify-icon mx-1">
            {notifications &&
            notifications?.filter((item) => item.read_at === null).length >
              0 ? (
              <span className="notify-number lan ">
                {notifications?.filter((item) => item.read_at === null).length}
              </span>
            ) : null}
          </i>

          {!isMobile ? translate.notification : null}
        </DropdownToggle>
        <DropdownMenu
          className="lan bg-notifcation p-0 drop-down__notificatios  "
          persist={true}
          tag={"div"}
          container="body"
         
        >
          {AllNotifiesTitle}

          {
            <DropdownItem
              tag="button"
              onClick={() => {
                history.push("/All-Notifications");
              }}
              className="d-flex justify-content-center w-100 notify-show-more p-0 "
            >
              <h6 className="bg-primary text-white w-100  text-center m-0 p-2 text-bold ">
                {" "}
                {translate.showMore}
              </h6>
            </DropdownItem>
          }
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}
