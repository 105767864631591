import { useAdminDispatch } from "context/Admin/global";

import React from "react";
import { useHistory } from "react-router-dom";
import { readNotification } from "services/yarvel";

export default function Notify({ data, index, changeCounter }) {
  let history = useHistory();
  const dispatch = useAdminDispatch();
  const readNotify = async (id , url) => {
    const res = await readNotification(id);
    window.location.href = `${url}`;
  };

  return (
    <div
      className={` rounded border p-2 col-5 mt-2 ${
        data.read_at === null ? "medical_box" : "readed_notify"
      }`}
      role={"button"}
      onClick={() => {
        if (data.read_at === null) {
          readNotify(data.id, data.url);
        } else {
          history.push(`${data.url}`);
        }
      }}
    >
      <h4
        className={data.read_at === null ? "text-primary " : "text-secondary"}
      >
        {" "}
        {data.title}
      </h4>
      <div className="d-flex  align-items-baseline  justify-content-between">
        <p>{data.body}</p>
        {data.read_at === null ? (
          <i class="fas fa-eye fa notify-icon  mx-2 text-secondary"></i>
        ) : (
          <i class="fas fa-eye-slash notify-icon  mx-2 text-secondary"></i>
        )}
      </div>
    </div>
  );
}
