import React from 'react'
import { useHistory } from "react-router";
import Locale from 'translations';

export default function TransferInfo({ info }) {
  let history = useHistory();
  
  const { translate, translate_companies, wallet } = Locale;
  return (
    <div className="border bg-white w-50 py-2     mt-5 ">
      <div className="d-flex flex-column align-items-center ">
        <i class="fas fa-arrow-right fa-3x text-blue my-1 "></i>
        <i class="fas fa-arrow-left fa-3x text-danger my-1 "></i>
        <h3 className="my-2 text-bolder">{translate.BalanceTransferred}</h3>
        <div className="row w-25 my-3 ">
          <div className="col-6">{wallet.receiptNumber}</div>
          <div className="col-6">{info.transaction_number}</div>
          <div className="col-6">{wallet.requestDate}</div>
          <div className="col-6">{info.transaction_date}</div>
          <div className="col-6">{wallet.amount}</div>
          <div className="col-6">{info.amount}</div>
          <div className="col-6">{translate_companies.transaction_type}</div>
          <div className="col-6">{info.type}</div>.
        </div>

        <button
          type="button"
          class="btn btn-secondary w-25 mb-2"
          onClick={() => {
            history.push("/wallet");
          }}
        >
          {wallet.backToRequests}
        </button>
      </div>
    </div>
  );
}

