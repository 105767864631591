import { useAdminDispatch, useAdminState } from "context/Admin/global";
import React from "react";
import { Redirect, Route } from "react-router-dom";

export default function AdminRoute({ component: Component, ...props }) {

  const AdminIsAuth = localStorage.getItem("Admin_isAuth");

  if (AdminIsAuth === "true") {
    return (
      <Route
        {...props}
        render={(matchProps) => <Component {...matchProps} />}
      />
    );
  } else {
    return <Redirect to="/admin/login" />;
  }
}
