import React, { useState } from "react";
import { DropdownItem } from "reactstrap";
import { Link, useHistory } from "react-router-dom";

import { useAdminDispatch } from "context/Admin/global";

export default function HeaderNotify({ data, index, readNotify }) {

  let history = useHistory();


  return (
    <DropdownItem
      key={index}
      role={"button"}
      className={`w-100 d-flex justify-content-between  align-items-baseline  py-2 border-b px-2 text-bold ${
        data.read_at === null ? "medical_box" : "bg-white"
      }`}
      onClick={() => {
        if (data.read_at === null) {
          readNotify(data.id, data.url);
        } else {
          history.push(`${data.url}`);
        }
      }}
    >
      <div className="d-flex flex-column justify-content-start align-items-start">
        <h6
          className={`${
            data.read_at === null ? "text-primary " : "text-secondary"
          }`}
        >
          {data.title}
        </h6>
        <p
          className={`m-0 ${
            data.read_at === null ? "text-dark " : "text-secondary"
          }`}
        >
          {data.body.substring(0, 28)}...
        </p>
      </div>

      {data.read_at === null ? (
        <i class="fas fa-circle  mx-2 text-primary readed_icon p-2 "></i>
      ) : (
        <span className="mx-2"></span>
      )}
    </DropdownItem>
  );
}
