import Loader from "components/Loader";
import PasswordField from "components/shared/PasswordField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/textField";
import { useAuthDispatch, useAuthState } from "context/auth";
import libphonenumber from "google-libphonenumber";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link, useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import { emailAvailability as emailAvailability } from "services/auth";
import Locale from "translations";

export default function UserDetails(props) {
  // ----------------------------------------------------------------------------------------------------
  // Component States / Variables / Constants
  // ----------------------------------------------------------------------------------------------------
  const { userDetails, commons } = Locale;
  const userRegister = useAuthState();
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  const [userDetailsState, setUserDetailsState] = useState({
    name: userRegister.userDetails?.name ?? "",
    email: userRegister.userDetails?.email ?? "",
    country: userRegister.userDetails?.country ?? "",
    countryPhoneCode: userRegister.userDetails?.phoneCode ?? "",
    phone: userRegister.userDetails?.phone ?? "",
    password: userRegister.userDetails?.password ?? "",
    passwordConfirmation: userRegister.userDetails?.confirmPassword ?? "",
    countryCode: null,
    spin: false
  });
  const history = useHistory();

  const [loaderMessage] = useState(
    "Please be patient and wait, The page will load now."
  );
  const dispatch = useAuthDispatch();
  const [errors, setErrors] = useState(userRegister.userDetails?.errors ?? {});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const validatePhone = (phone) => {
    try {
      return phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(phone.toString(), userDetailsState.countryCode),
        userDetailsState.countryCode
      );
    } catch (err) {
      return false;
    }
  };

  const isPasswordMatch =
    userDetailsState.passwordConfirmation === userDetailsState.password;
  // ----------------------------------------------------------------------------------------------------
  // Component Functions
  // ----------------------------------------------------------------------------------------------------

  const checkFormErrors = () => {
    setErrors({
      ...errors,
      ...validate(
        { name: "name", value: userDetailsState.name },
        { required: true, min: 3 }
      ),
      ...validate(
        { name: "email", value: userDetailsState.email },
        { required: true, email: true }
      ),
      ...validate(
        { name: "country", value: userDetailsState.country },
        { required: true }
      ),
      ...validate(
        { name: "phone", value: validatePhone(userDetailsState.phone) },
        { required: true, phone: true }
      ),
      ...validate(
        { name: "password", value: userDetailsState.password },
        { required: true, password: true, min: 8 }
      ),
      ...validate(
        {
          name: "confirm",
          value: isPasswordMatch
        },
        { required: true, confirm: true }
      )
    });
  };

  

  // SUbmit Form
  const submitForm = () => {
    dispatch({
      type: "User_Details",
      payload: {
        name: userDetailsState.name,
        email: userDetailsState.email,
        country: userDetailsState.country,
        phone: userDetailsState.phone,
        phoneCode: userDetailsState.countryPhoneCode,
        password: userDetailsState.password,
        confirmPassword: userDetailsState.passwordConfirmation,
        errors: errors
      }
    });
    dispatch({ type: "Progress", progress: 50, step: 2 });
  };

  const data = {
    user_email: userDetailsState.email
  };

  useEffect(async () => {
    if (isFormValid(errors) && userRegister.lastStep === 1) {
      const response = await emailAvailability(data.user_email);
      if (response.status === 200) {
        submitForm();
      } else {
        store.addNotification({
          title: commons.somethingWentWrong,
          message: userDetails.businessExistInvalideValidationMessage,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true
          }
        });
      }
    } else {
      dispatch({ type: "Progress", progress: 0, step: 1, lastStep: 1 });
    }
    setUserDetailsState({
      ...userDetailsState,
      spin: false
    });
  }, [isErrorLoaded]);

  const checkEmailAvailability = () => {
    setUserDetailsState({
      ...userDetailsState,
      spin: true
    });
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  };

  // Use Effect for [Country]
  useEffect(() => {
    const selectedCountry = props.countries?.filter(
      (country) => country.value == userDetailsState.country?.value
    );

    setUserDetailsState({
      ...userDetailsState,
      countryPhoneCode:
        selectedCountry.length > 0 ? selectedCountry[0].value : "-",
      countryCode:
        selectedCountry.length > 0 ? selectedCountry[0]["code"] : null
    });
  }, [props.countries, userDetailsState.country?.value]);

  useEffect(() => {
    if (!isPasswordMatch) {
      setErrors({
        ...errors,
        ...validate(
          {
            name: "confirm",
            value: isPasswordMatch
          },
          {
            required: true,
            confirm: true
          }
        )
      });
    } else {
      setErrors({
        ...userRegister.userDetails?.errors,
        confirm: {}
      });
    }
  }, [userDetailsState.password, userDetailsState.passwordConfirmation]);

  // rejected company form filling
  useEffect(() => {
    if (history.location.state) {
      const company = history.location.state;
      const country = props.countries.find(
        (country) => country.value === company.country_id
      );
      

      setUserDetailsState({
        name: company.name,
        email: company.business_email,
        country,
        phone: company.phone.split("+")[1],
        countryCode: country.code,
        countryPhoneCode: company.country_id,
        phoneCode: company.country_id
      });
    }
  }, [history.location.state]);

  // ----------------------------------------------------------------------------------------------------
  // Main Component Body UI
  // ----------------------------------------------------------------------------------------------------
  if (props.isCountriesLoaded) {
    return (
      <>
        <h1 className="text-center text-title text-dark-blue">
          {userDetails.mainTitle}
        </h1>

        <div className="bg-white p-3 px-3 radius-10 mt-3">
          <div className="row m-0">
            {/* Full Name */}
            <div className="col-12">
              <TextField
                type="text"
                hasLabel={true}
                label={userDetails.fullName}
                placeholder={userDetails.fullNamePlaceholder}
                value={userDetailsState.name}
                name="name"
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    ...validate(e.target, {
                      required: true,
                      min: 3
                    })
                  });
                  setUserDetailsState({
                    ...userDetailsState,
                    name: e.target.value
                  });
                }}
                min={3}
                color={
                  errors?.name?.required || errors?.name?.min ? "danger" : ""
                }
                errors={errors?.name}
              />
            </div>

            {/* Business Email */}
            <div className="col-12">
              <TextField
                hasLabel={true}
                label={userDetails.businessEmail}
                placeholder={userDetails.businessEmailPlaceholder}
                value={userDetailsState.email}
                name="email"
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    ...validate(e.target, {
                      required: true,
                      email: true
                    })
                  });
                  setUserDetailsState({
                    ...userDetailsState,
                    email: e.target.value
                  });
                }}
                color={
                  errors?.email?.required || errors?.email?.email
                    ? "danger"
                    : ""
                }
                errors={errors?.email}
              />
            </div>

            {/* Country */}
            <div className="col-md-6 ">
              <SelectField
                label={userDetails.country}
                value={userDetailsState.country}
                name="country"
                placeholder={userDetails.countryPlaceholder}
                options={props.countries}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "country", value: e.value },
                      { required: true }
                    )
                  });
                  setUserDetailsState({
                    ...userDetailsState,
                    country: e,
                    phone: e.value
                  });
                }}
                onBlur={() =>
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "country", value: userDetailsState.country },
                      { required: true }
                    )
                  })
                }
                color={errors?.country?.required ? "danger" : ""}
                errors={errors?.country}
              />
            </div>

            {/* Phone Number */}
            <div className="col-md-6 mt-4 m-md-0">
              <TextField
                hasLabel={true}
                label={userDetails.phoneNumber}
                placeholder={userDetails.phoneNumberPlaceholder}
                name="phone"
                value={userDetailsState.phone}
                extraText={userDetailsState.countryPhoneCode ?? "---"}
                extraTextPosition="prepend"
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "phone", value: validatePhone(e.target.value) },
                      {
                        required: true,
                        phone: true
                      }
                    )
                  });
                  setUserDetailsState({
                    ...userDetailsState,
                    countryPhoneCode: userDetailsState.countryPhoneCode,
                    countryCode: userDetailsState.countryCode,
                    phone: e.target.value
                  });
                }}
                color={
                  errors?.phone?.required || errors?.phone?.phone
                    ? "danger"
                    : ""
                }
                errors={errors?.phone}
              />
            </div>

            {/* Password */}
            <div className="col-12">
              <PasswordField
                hasLabel={true}
                label={userDetails.password}
                placeholder={userDetails.passwordPlaceholder}
                name="password"
                value={userDetailsState.password}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "password", value: e.target.value },
                      {
                        required: true,
                        password: true,
                        min: 8
                      }
                    )
                  });
                  setUserDetailsState({
                    ...userDetailsState,
                    password: e.target.value
                  });
                }}
                min={8}
                color={
                  errors?.password?.required ||
                  errors?.password?.password ||
                  errors?.password?.min
                    ? "danger"
                    : ""
                }
                errors={errors?.password}
              />
            </div>

            {/* Repeat Password */}
            <div className="col-12 mt-4">
              <PasswordField
                hasLabel={true}
                label={userDetails.repeatPassword}
                placeholder={userDetails.repeatPasswordPlaceholder}
                name="confirm"
                value={userDetailsState.passwordConfirmation}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "confirm", value: !isPasswordMatch },
                      {
                        required: true,
                        confirm: true
                      }
                    )
                  });
                  setUserDetailsState({
                    ...userDetailsState,
                    passwordConfirmation: e.target.value
                  });
                }}
                min={8}
                color={
                  errors?.confirm?.required || errors?.confirm?.confirm
                    ? "danger"
                    : ""
                }
                errors={errors?.confirm}
              />
            </div>
          </div>
        </div>

        <div className="row mt-3 justify-content-between m-0 px-2">
          <div className="col-6 col-md-6">
            <Link to="/login" className="mt-2 text-dark-blue back-btn">
              <span className="icx icx-left-solid-arrow text-10"></span>{" "}
              {userDetails.backBtn}
            </Link>
          </div>
          <div className="col-4 col-md-3 p-0 ">
            <button
              className="btn btn-primary w-100"
              onClick={() => checkEmailAvailability()}
              disabled={userDetailsState.spin}
            >
              {userDetailsState.spin ? (
                <Spinner color="light" size="sm" />
              ) : null}{" "}
              {userDetails.next}
            </button>
          </div>
        </div>
      </>
    );
  } else {
    return <Loader message={loaderMessage} />;
  }
}
