import React, {useEffect, useState} from "react";
import Activity from "./Activity";
import Counts from "./Counts";
import TravelersNumber from "./TravelersNumber";
import ApexChart from "./chart";
import {getHomePageData} from "../../../services/yarvel";
import Locale from "../../../translations";

export default function DashboardCompanies() {
    const {translate} = Locale;

    const [dashboardData, setDashboardData] = useState();

    useEffect(() => {
        async function fetchData() {
            const res = await getHomePageData();
            setDashboardData(res?.data?.data);
        }

        fetchData();
    }, []);

    let all;
    if (dashboardData && dashboardData.logs && Object.entries(dashboardData?.logs).length > 0) {
        all = Object.entries(dashboardData?.logs).map((data) => (
            <Activity data={data}/>
        ));
    } else {
        all = "";
    }


    let apexChart;
    if (dashboardData && dashboardData.logs && Object.entries(dashboardData?.logs).length > 0) {
        apexChart = <ApexChart num={dashboardData.reservations}/>;
    } else {
        apexChart = "";
    }

    return (
        <>
            <section className="dashboard-companies">
                <div className="container-fluid">
                    <div className="row px-5 pt-4">
                        <div className="col-md-3">
                            <Counts number={dashboardData?.team_management_number}/>
                        </div>
                        <div className="col-md-6">
                            <div className="bg-white shadow-sm h-100  p-2 font-weight-bold orders">
                                <p className="text">
                                    <TravelersNumber/>
                                </p>
                                <div className="row my-5">
                                    <div className="col-7">
                                        {" "}
                                        {apexChart}
                                    </div>
                                    <div className="  col-12 col-md-4 d-flex flex-column  justify-content-center">
                                        <div className="mt-3">
                                            <h4>
                                                <i className="fas fa-circle mx-3"
                                                   style={{color: "#F28F31", fontSize: "15px "}}/>
                                                {translate.InProgress}
                                            </h4>
                                            <h4 className="mx-5">
                                                {dashboardData?.reservations?.inprogress}
                                            </h4>
                                        </div>
                                        <div className="mt-3">
                                            <h4>
                                                <i className="fas fa-circle mx-3"
                                                   style={{color: "#24AEFC", fontSize: "15px "}}/>{" "}
                                                {translate.AreChecked}{" "}
                                            </h4>
                                            <h4 className="mx-5">
                                                {dashboardData?.reservations?.finished}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="p-2 activity bg-white shadow-sm rounded">
                                <div className="title">
                                    <h2 className="font-weight-bold text-subtitle">
                                        {translate.myActivity}
                                    </h2>
                                    <p className="text">{translate.activityText}</p>
                                </div>
                                {all}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
