import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { store } from "react-notifications-component";
import Locale from "translations";
import Filter from "./Filter";
import FilterDeals from "./FilterDeals";
import "./wallet.scss";
import WalletLayout from "./WalletLayout";
import WalletRequests from "./WalletRequests";
import WalletDeals from "./WalletDeals";
import {
  getWalletRequest,
  getWalletDeals,
  wallet_changeStatusRequest,
} from "../../../services/admin-wallet";
import Pagination from "components/shared/Pagination";
import ShowForPermissionAdmin from "helpers/ShowForPermissionAdmin";

export default function Wallet() {
  const { wallet, Admin } = Locale;

  const [currentTab, setCurrentTab] = useState("requests");

  const [walletRequests, setWalletRequests] = useState();
  const [walletRequestsMeta, setWalletRequestsMeta] = useState();
  const [walletDeals, setWalletDeals] = useState();
  const [walletDealsMeta, setWalletDealsMeta] = useState();

  const [filterRequest, setFilterRequest] = useState({
    status: "not-approved",
    receipt_number: "",
    receipt_date: "",
    type: "",
    page: 1,
  });

  const [filterDeals, setFilterDeals] = useState({
    status: "",
    transaction_number: "",
    transaction_date: "",
    type: "",
    page: 1,
  });

  const ChangeStatusRequest = async (id, status) => {
    const res = await wallet_changeStatusRequest(id, status);
    if (res.status === 200) {
      store.addNotification({
        title: "info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });

      const companyRequests = await getWalletRequest(filterRequest);
      setWalletRequests(companyRequests?.data);
      setWalletRequestsMeta(companyRequests?.meta);
      
    }
  };

  useEffect(() => {
    async function fetchData() {
      const companyRequests = await getWalletRequest(filterRequest);
      setWalletRequests(companyRequests?.data);
      setWalletRequestsMeta(companyRequests?.meta);
      

      const companyDeals = await getWalletDeals(filterDeals);
      setWalletDeals(companyDeals?.data);
      setWalletDealsMeta(companyDeals?.meta);
      
    }
    fetchData();
  }, [filterRequest, filterDeals]);

  // pagination
  const goToRequest = async (page) => {
    
    setFilterRequest({ ...filterRequest, page: page });
  };

  const goToDeals = async (page) => {
    setFilterDeals({ ...filterDeals, page: page });
  };

  const filterRequestSubmitted = (searchQ, dateQ, statusQ) => {
    
    
    
    let status_Q = "";

    if (statusQ !== "0") {
      status_Q = statusQ;
    }
    setFilterRequest({
      ...filterRequest,
      status: status_Q,
      receipt_number: searchQ,
      receipt_date: dateQ,
      page: 1,
    });
  };

  const resetRequestSubmitted = () => {
    setFilterRequest({
      ...filterRequest,
      status: "not-approved",
      type: "",
      receipt_number: "",
      receipt_date: "",
      page: 1,
    });
  };

  const filterDealsSubmitted = (statusQ, searchQ, dateQ) => {
    setFilterDeals({
      ...filterDeals,
      type: statusQ,
      transaction_number: searchQ,
      transaction_date: dateQ,
      page: 1,
    });
  };

  const resetDealsSubmitted = () => {
    setFilterDeals({
      ...filterDeals,
      type: "",
      transaction_number: "",
      page: 1,
    });
  };

  const onFetchRequests = async () => {
    const companyRequests = await getWalletRequest(filterRequest);
    setWalletRequests(companyRequests?.data);
    setWalletRequestsMeta(companyRequests?.meta);
  };

  const onFetchDeals = async () => {
    const companyDeals = await getWalletDeals(filterDeals);
    setWalletDeals(companyDeals?.data);
    setWalletDealsMeta(companyDeals?.meta);
  };

  return (
    <React.Fragment>
      <div className="AdminContainer-custom container">
        <header className="d-flex CompaniesList-orders py-3 px-2  ">
          <div>
            <h3 className="text-muted font-weight-bold CompaniesList-title">
              {Admin.header_balance_requests}
            </h3>
          </div>
          <div className="walletRowAction">
            <ShowForPermissionAdmin
              permission={["list-transaction-requests-and-transactions"]}
            >
              <button
                className={`text-bold mx-1 px-3 bg-brwon rounded text-muted  ${
                  currentTab === "requests"
                    ? "CompaniesList-LinkActive"
                    : "CompaniesList-LinkNotActive"
                }`}
                onClick={() => {
                  setCurrentTab("requests");
                  onFetchRequests();
                }}
              >
                {wallet.requests}
              </button>

              <button
                className={`text-bold mx-1 px-3 bg-brwon rounded text-muted ${
                  currentTab === "deals"
                    ? "CompaniesList-LinkActive"
                    : "CompaniesList-LinkNotActive"
                }`}
                onClick={() => {
                  setCurrentTab("deals");
                  onFetchDeals();
                }}
              >
                {wallet.deals}
              </button>
            </ShowForPermissionAdmin>
          </div>
        </header>

        {currentTab === "requests" && (
          <Filter
            ResetSubmitted={() => resetRequestSubmitted()}
            FilterSubmitted={(searchQ, date, status) =>
              filterRequestSubmitted(searchQ, date, status)
            }
          />
        )}

        {currentTab === "deals" && (
          <FilterDeals
            ResetSubmitted={() => resetDealsSubmitted()}
            FilterSubmitted={(status, searchQ, date) =>
              filterDealsSubmitted(status, searchQ, date)
            }
          />
        )}

        <div className="mt-3 p-0 WalletList-table">
          {currentTab === "requests" && (
            <WalletRequests
              changeStatusRequest={ChangeStatusRequest}
              requests={walletRequests}
            />
          )}
          {currentTab === "deals" && <WalletDeals deals={walletDeals} />}

          {currentTab === "requests" && (
            <Pagination
              info={walletRequestsMeta}
              goTo={goToRequest}
              items={10}
            />
          )}
          {currentTab === "deals" && (
            <Pagination info={walletDealsMeta} goTo={goToDeals} items={10} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
