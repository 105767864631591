import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import Locale from "../../../translations";

import { Collapse, Button, CardBody, Card } from "reactstrap";
import { Table } from "reactstrap";
import ShowForPermission from "helpers/showForPermission";
import useUserDetails from "helpers/useUserDetalis";

const Collaps = (props) => {
  const { translate } = Locale;
  const { Group } = props;
  let [Id, setId] = useState("");
  const [collapse, setCollapse] = useState(false);
  const [status, setStatus] = useState("Closed");

  const onEntering = () => setStatus("Opening...");

  const onEntered = () => setStatus("Opened");

  const onExiting = () => setStatus("Closing...");

  const onExited = () => setStatus("Closed");

  const toggle = (event) => {
    Id = event.target.id;
    setCollapse(!collapse);
    
  };


  return (
    <div>
      <div className="row align-items-center col-containt no-gutters">
        <div className="col-5 col-md-6 ">
          <Button
            className="CollapsePlusIcon"
            color="primary"
            id="btn1"
            onClick={(e) => toggle(e)}
            style={{ marginBottom: "1rem" }}
          >
            <i className={collapse ? "fas fa-minus" : "fas fa-plus"}></i>
          </Button>
          {Group.name}
        </div>

        <div className="col-4  col-md-5 ">
          <span className="num m-0">{Group.users.length}</span>{" "}
          {translate.users}
        </div>
        <ShowForPermission
          permission={["edit-group", "view-group"]}
          type={"and"}
        >
          {props.details?.group_id != Group?.id ? (
            <div className="col-3 col-md-1">
              <NavLink
                exact={true}
                to={`/team-management/groups/edit/` + Group.id}
              >
                <i class="fas fa-edit mx-1"></i>
                {translate.edit}
              </NavLink>
            </div>
          ) : null}
        </ShowForPermission>
      </div>

      <Collapse
        isOpen={collapse}
        onEntering={onEntering}
        onEntered={onEntered}
        onExiting={onExiting}
        onExited={onExited}
      >
        <Card>
          <CardBody className="card-body">
            <Table className="table table-responsive-sm">
              <thead>
                <tr>
                  <th> {translate.userName}</th>
                  <th>{translate.userEmail}</th>

                  <th>{translate.status}</th>
                </tr>
              </thead>
              <tbody className="table-stripe ">
                {Group.users
                  ? Group.users.map((res) => {
                      return (
                        <tr>
                          <td>{res.name}</td>
                          <td>{res.email}</td>
                          <td>
                            <span
                              className={`${
                                res.status === "active" || res.status === "مفعل"
                                  ? "text-green"
                                  : "text-danger"
                              }`}
                            >
                              {res.status}
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default Collaps;
