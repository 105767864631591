import { useAdminState } from "context/Admin/global";
import ShowForPermissionAdmin from "helpers/ShowForPermissionAdmin";
import React from "react";
import { NavLink } from "react-router-dom";
import Locale from "../../../../translations";

export default function OrderPending(props) {
	const { order, company_id } = props;
	const { translate_companies } = Locale;
  const { currentLanguage } = useAdminState();

	return (
    <>
      <tr className="OrderPending">
        <td>{order.id}</td>
        <td>
          <p className="m-0">{order.test_name}</p>
          <p className="m-0">{order.grade_name}</p>
        </td>
        <td>{order.traveler_name}</td>
        <td>
          <p className="m-0">{"Check-me"}</p>
          <p className="m-0">{order.type}</p>
        </td>
        {/* <td>{order.type}</td> */}
        <td>
          {order.check_date}
          <p className="orders-check-time">{" " + order.check_time}</p>
        </td>
        {/* <td>{order['count-reservations']}</td> */}
        <td>{order.created_at}</td>
        <td>
          {order.total_cost}
          {translate_companies.l_e}
        </td>
        <td>
          {order.payment?.type === "wallet"
            ? translate_companies.wallet_asType
            : translate_companies.company_asType}
        </td>
        <td className="CompanyRow-last">
          <span className="CompanyRow-OrangeColor">
            {order.payment?.commission}
            {translate_companies.pounds}
          </span>
          <ShowForPermissionAdmin permission={"view-reservation"}>
            <NavLink
              exact={true}
              to={`/admin/companies/orders/view/` + order.trip_id}
            >
              {currentLanguage === "ar" ? (
                <i
                  className="fa fa-angle-left CompanyRow-arrow"
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  className="fa fa-angle-right CompanyRow-arrow"
                  aria-hidden="true"
                ></i>
              )}
            </NavLink>
          </ShowForPermissionAdmin>
        </td>
      </tr>
    </>
  );
}
