import TextField from "components/shared/textField";
import React, { useState } from "react";
import Locale from "translations";
import { useHistory } from "react-router";
import { useClientsDispatch } from "context/addClient";
export default function PCR({ examnation, services }) {
  const { translate, translate_companies } = Locale;
  let history = useHistory();
  const [clientsNumber, setClientsNumber] = useState();
  const dispatch = useClientsDispatch();

  const country_id = localStorage.getItem("country_id");

  return (
    <div className="text_filed p-4  medical_box my-3">
      <div className="row align-items-center justify-content-between ">
        <figure className="rounded-circle bg-white p-5 border ">
          {examnation.check_id != 1 && (
            <i class="fas fa-syringe fa-3x text-primary"></i>
          )}
          {examnation.check_id === 1 && (
            <i class="fas fa-virus fa-3x text-primary"></i>
          )}
        </figure>
        <div className="d-flex flex-column justify-content-start col-12 col-md-6 col-lg-7   ">
          <h3 className="text-primary text-bolder">{examnation.title}</h3>
          <div className="d-flex align-items-baseline">
            <i class="far fa-clock mx-1"></i>
            <p className="m-0 mx-1 text-muted"> {translate.ResultTime} : </p>
            <p className="m-0 mx-1 text-bolder mt-2">
              {examnation.time} {translate_companies.hours}
            </p>
          </div>

          <div className="d-flex align-items-baseline">
            <i class="fas fa-tag mx-1"></i>
            <p className="m-0 mx-1  text-muted">{translate.Priceperson} :</p>
            <p className="m-0 mx-1 text-success text-bolder">
              {examnation.price}{" "}
              {country_id === "20"
                ? translate_companies.egyptian_pound
                : translate_companies.Sar}
            </p>
          </div>

          <div className="d-flex align-items-baseline mt-2 row ">
            <p className="m-0 mx-1 bg-pay text-pay p-2 col-12 col-md-4 mt-2">
              <i class="fas fa-money-bill-wave m-0 mx-1 text-pay"></i>
              {translate.walletCommission}{" "}
              <span className="text-bolder">
                {examnation.debit_comission}{" "}
                {country_id === "20"
                  ? translate_companies.pounds
                  : translate_companies.riyal}
              </span>{" "}
            </p>
            <p className="m-0 mx-1 bg-pay text-pay p-2 col-12 col-md-4 mt-2">
              <i class="fas fa-wallet m-0 mx-1 text-pay"></i>
              {translate.companyCommission}{" "}
              <span className="text-bolder">
                {examnation.credit_comission}{" "}
                {country_id === "20"
                  ? translate_companies.pounds
                  : translate_companies.riyal}
              </span>{" "}
            </p>
          </div>
        </div>

        <div className="row col-12 col-md-4  mt-2">
          <TextField
            className=" p-2 col-6"
            hasLabel={false}
            placeholder={translate.NumberOfPeople}
            type={"text"}
            onChange={(e) => {
              setClientsNumber(e.target.value);
            }}
          ></TextField>

          <button
            type="button"
            class="btn btn-success p-2  text-bolder col-5 mx-2"
            onClick={() => {
              history.push(
                `/medical/services/${clientsNumber ? clientsNumber : 1}/${
                  examnation.check_id
                }`
              );
              dispatch({
                type: "newAddService",
                data: { ...examnation, ...services },
              });
            }}
          >
            {translate.ServiceReservation}
          </button>
        </div>
      </div>
    </div>
  );
}
