import TextField from "components/shared/textField";
import { useCompanyDispatch } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { addLoginAPI, getGroupAPI, viewGroupAPI } from "services/yarvel";
import Locale from "translations";
import GroupSafa from "./groupSafa";
export default function LoginSafa({ isOpenModel, toggleModel , travelersData, setTravelersData}) {
  const { translate } = Locale;
  const [loginData, setLoginData] = useState({});
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  const dispatchLogin = useCompanyDispatch();
  const token_api = localStorage.getItem("token_api");

  const [isOpenGroupModel, setIsOpenGroupModel] = useState(false);
  const toggleGroupModel = () => setIsOpenGroupModel(!isOpenGroupModel);
  const [groupList, setGroupList] = useState([]);

  const [groupView, setGroupView] = useState(null);

  const checkFormErrors = () => {
    setErrors({
      ...errors,
      ...validate(
        {
          name: "safa_key",
          value: loginData.safa_key,
        },
        {
          required: true,
        }
      ),
      ...validate(
        {
          name: "email",
          value: loginData.email,
        },
        {
          required: true,
          email:true
        }
      ),
      ...validate(
        {
          name: "pwd",
          value: loginData.pwd,
        },
        {
          required: true,
         
        }
      ),
    });
  };
const [paginate,setPaginate]=useState({
  size:10,
  page:1
})
  useEffect(() => {
    const add = async () => {
        if (isFormValid(errors)) {
          const res = await addLoginAPI(loginData);

          if (res.status == 200 || res.status == 201) {
            dispatchLogin({
              type: "getTokenApi",
              payload: res?.data?.data?.token,
            });
            toggleModel(false);
            const ress = await getGroupAPI({ token: token_api },paginate.size,paginate.page);
            if (ress) {
              setGroupList(ress?.data?.data?.rows);
            }
           toggleGroupModel(!isOpenGroupModel);

          }
        }  
      
    };

    add();
  }, [isErrorLoaded]);

  const viewGroupAPIToken = async (id, token) => {
    const res = await viewGroupAPI(id, { token: token },paginate.size,paginate.page);
    console.log("ress11", res?.data);
    setGroupView(res?.data?.data?.row?.members);
  };

  const handleForm = async () => {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  };

  return (
    <section >
      <Modal
        isOpen={isOpenModel}
        toggle={() => {
          toggleModel(null);
        }}
      >
        <div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
          <p className="h3 text-secondary ">{translate.signIn}</p>
          <i
            className="far fa-times-circle fa-lg text-secondary "
            onClick={() => {
              toggleModel(null);
            }}
          ></i>
        </div>
        <ModalBody className="lan  mx-5 my-4 p-0 ">
          <div className="mt-4">
            <div className="container-fluide">
              <div className="row loginApi">
                <div className="col-12 ">
                  <TextField
                    type="text"
                    hasLabel={false}
                    label={"صفا كى"}
                    placeholder={"صفا كى"}
                    name="safa_key"
                    onChange={(e) => {
                      setLoginData({
                        ...loginData,
                        safa_key: e.target.value,
                      });
                      setErrors({
                        ...errors,
                        ...validate(e.target, {
                          require: true,
                        }),
                      });
                    }}
                    color={errors?.safa_key?.required ? "danger" : ""}
                    errors={errors?.safa_key}
                  >
                    <i className="fas fa-key text-primary"></i>
                  </TextField>
                </div>

                <div className="col-12">
                  <TextField
                    type="text"
                    hasLabel={false}
                    label={translate.email}
                    placeholder={translate.email}
                    name="email"
                    onChange={(e) => {
                      setLoginData({
                        ...loginData,
                        email: e.target.value,
                      });
                      setErrors({
                        ...errors,
                        ...validate(e.target, {
                          require:true,
                          email:true
                        }),
                      });
                    }}
                    color={errors?.email?.required ? "danger" : ""}
                    errors={errors?.email}
                  >
                    <i className="fas fa-user text-primary"></i>
                  </TextField>
                </div>
                <div className="col-12">
                  <TextField
                    type="password"
                    hasLabel={false}
                    label={translate.password}
                    placeholder={translate.password}
                    name="pwd"
                    onChange={(e) => {
                      setLoginData({
                        ...loginData,
                        pwd: e.target.value,
                      });
                      setErrors({
                        ...errors,
                        ...validate(e.target, {
                          require:true,
                        }),
                      })
                    }}
                    color={errors?.pwd?.required ? "danger" : ""}
                    errors={errors?.pwd}
                  >
                    <i className="fas fa-lock text-primary"></i>
                  </TextField>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="lan pb-4 border-top-0  mx-5 p-0 ">
          <button
            className="rounded btn-primary w-100  text-bold py-3  m-auto font-weight-bolder"
            onClick={handleForm}
          >
            {translate.signIn}
          </button>
        </ModalFooter>
      </Modal>

      <GroupSafa
        viewGroupAPIToken={viewGroupAPIToken}
        toggleGroupModel={toggleGroupModel}
        isOpenGroupModel={isOpenGroupModel}
        groupList={groupList}
        groupView={groupView}

        paginate={paginate}
        setPaginate={setPaginate}

        travelersData={travelersData} 
        setTravelersData={setTravelersData}
      />
    </section>
  );
}
