import RadioField from "components/shared/RadioField";
import { useCompanyState } from "context/global";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Collapse } from "reactstrap";
import Locale from "translations";
import HomeBooking from "./HomeBooking";
import LabBooking from "./LabBooking";
export default function Traveler({
  tripdate,
  client,
  setErrorsReservation,
  errorsReservation,
  checkFormallErrorsReservation,
  switchTo,
  fillClients,
  service,
  index,
  doneReservation,
  setDoneReservation,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const togglec = () => setIsOpen(!isOpen);
  const { translate } = Locale;
  const { currentLanguage } = useCompanyState();
  
  const [bookingType, setbookingType] = useState({
    selectedType: service.type_id.value
      ? service.type_id.value
      : service.type_id,
  });
  const [selectedGrade, setSelectedGrade] = useState({});
  const [cost, setCost] = useState();

  const handleChange = (e) => {
    const formatted = e.map((type) => ({
      value: type.id,
      label: type.name,
      price: type.price,
    }));
    setSelectedGrade(formatted);
  };

  /* to make grades option show in edit */
  /*   useEffect(() => {
    if (client.type_id != null) {
      if (client.type_id === 1) {
        handleChange(services[0].grades);
      } else if (client.type_id === 2) {
        handleChange(services[1].grades);
      } else if (client.type_id === 3) {
        handleChange(services[3].grades);
      }
      setCost(client?.total_coast);
      setTestid(client?.test_id);
    }

    setbookingType({
      ...bookingType,
      selectedType: client.type_id ? client.type_id : 2,
    });
  }, [client]); */

  return (
    <div className="mx-4 my-4 shadow ">
      <div className="list_header d-flex justify-content-between align-items-center m-0 p-3 rounded-top ">
        <button onClick={togglec} className="rounded-top ">
          <p className="m-0 h4  font-weight-bold text-color font-mobile">
            {index + 1} ( {client.traveler_name} )
          </p>
        </button>

        <div className="d-flex align-items-center ">
          <button className="bg-white p-0 btn mr-3 d-flex align-items-center"></button>
          {isOpen ? (
            <i className="fas fa-angle-down icons_label font-mobile"></i>
          ) : currentLanguage === "ar" ? (
            <i className="fas fa-angle-left  icons_label font-mobile"></i>
          ) : (
            <i className="fas fa-angle-right  icons_label font-mobile"></i>
          )}
        </div>
      </div>

      <Collapse isOpen={isOpen}>
        <Card className="py-2 px-5">
          <CardBody>
            {bookingType.selectedType === 1 && (
              <div className="mt-2">
                <LabBooking
                  errorsReservation={errorsReservation}
                  setErrorsReservation={setErrorsReservation}
                  bookingType={bookingType}
                />
              </div>
            )}
            {bookingType.selectedType === 2 && (
              <div className="mt-2">
                <HomeBooking
                  tripdate={tripdate}
                  client={client}
                  fillClients={fillClients}
                  errorsReservation={errorsReservation}
                  setErrorsReservation={setErrorsReservation}
                  checkFormallErrorsReservation={checkFormallErrorsReservation}
                  switchTo={switchTo}
                  bookingType={bookingType}
                  setbookingType={setbookingType}
                  index={index}
                  doneReservation={doneReservation}
                  setDoneReservation={setDoneReservation}
                />
              </div>
            )}
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
}
