import React, { useState, useEffect } from "react";
import { useHistory, useParams, NavLink } from "react-router-dom";
import Locale from "translations";
import WalletLayout from "../WalletLayout";
import Card from "./Card";
import { getWalletRequestWithID } from "../../../../services/admin-wallet";

export default function RequestInfo() {
  const { wallet, translate } = Locale;
  const history = useHistory();
  const { request_id } = useParams();

  const [walletRequestInfo, setWalletRequestInfo] = useState({});

  useEffect(() => {
    async function fetchData() {
      const walletRequest = await getWalletRequestWithID(request_id);
      setWalletRequestInfo(walletRequest?.data);
      
    }
    fetchData();
  }, [request_id]);

  return (
    <WalletLayout>
      <Card label={wallet.addBalanceRequest}>
        <div className="row WalletAdminInfo">
          <div className="col-6">{wallet.receiptNumber}</div>
          <div className="col-6">{walletRequestInfo.receipt_number}</div>

          <div className="col-6">{wallet.company_name}</div>
          <div className="col-6">{walletRequestInfo.company_name}</div>

          <div className="col-6">{wallet.requestDate}</div>
          <div className="col-6">{walletRequestInfo.receipt_date}</div>

          <div className="col-6">{wallet.amount}</div>
          <div className="col-6">{walletRequestInfo.amount}</div>

          {walletRequestInfo.photo
            ? (<React.Fragment>
              <div className="col-6">{wallet.filing_photo}</div>
              <div className="col-6">
                <a target='_blank' href={walletRequestInfo.photo} className="p-2 text-primary mr-3 btn-link">
                  <i className="fas fa-eye"></i>
                  {translate.view}
                </a>
              </div></React.Fragment>)
            : null}

          <button onClick={() => history.push("/admin/wallet")} className="back mt-5">
            {wallet.backToRequests}
          </button>
        </div>
      </Card>

    </WalletLayout>
  );
}
