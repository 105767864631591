import React from "react";
import { useParams } from "react-router";
import Locale from "translations";
import Traveler from "./traveler";

export default function ListTravelers({ clients }) {
  const { translate } = Locale;
  const { id } = useParams();
  const AllClients =
    clients &&
    clients.length > 0 &&
    clients.map((client, index) => <Traveler client={client} key={index} index={index} />);

  return (
    <div className="">
      <table className="table table-responsive-sm">
        <thead className="bg_table ">
          <tr className="table_head">
            <th scope="col">{translate.orderNumber}</th>
            <th scope="col">{translate.ExaminationType}</th>
            <th scope="col">{translate.traveler}</th>
            <th scope="col">{translate.examinationMethod}</th>
            <th scope="col">{translate.serviceType}</th>
            <th scope="col">{translate.timeRequest}</th>

            <th scope="rowgroup">{translate.Cost}</th>
            <th scope="rowgroup">{translate.PaymentStatus}</th>
            {/*  <th scope="rowgroup">{translate.theRest}</th> */}

            <th scope="rowgroup">{translate.DateRequest}</th>
            <th scope="col">{translate.processes}</th>
          </tr>
        </thead>
        <tbody>
          {AllClients && AllClients.length > 0 ? (
            AllClients
          ) : (
            <tr>
              <td colSpan="11">
                <div className=" text-center">
                  <i className="fas fa-info-circle fa-lg text-muted m-3 "></i>{" "}
                  <h4 className=" text-muted">{translate.noResult}</h4>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/*       <Pagination /> */}
    </div>
  );
}
