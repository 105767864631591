import { cleanKeys } from "helpers/cleanKeys";
import React from "react";
import Locale from "translations";
import Client from "./client";
export default function ListClients({ clients, reservation }) {
  const { translate } = Locale;

  const allclients =
    clients.length > 0
      ? clients.map((item, index) => {
          return (
            <Client
              client={cleanKeys(item)}
              key={index}
              index={index}
              reservation={reservation[index]}
            />
          );
        })
      : [];

  return <div>{allclients}</div>;
}
