import UserSetting from "components/UsetSetting/UserSetting";
import { useCompanyDispatch, useCompanyState } from "context/global";
import useWallet from "modules/Yarvel/wallet/useWallet";
import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Locale from "translations";
import logoImg from "assets/images/Image1.png";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ShowForPermission from "helpers/showForPermission";
import useUserDetails from "helpers/useUserDetalis";
import Notifications from "./Notifications/Notifications";
import { NotificationsProvider } from "./Notifications/useNotfication";

function Header() {
  const { translate, translate_companies } = Locale;
  const { pathname } = useLocation();

  const isAuth = localStorage.getItem("isAuth");
  const dispatch = useCompanyDispatch();
  const balances = useWallet();
  const { balance, safaWallet, permissions, is_admin } = useCompanyState();
  const company_name = localStorage.getItem("company_name");

  const { details } = useUserDetails();
  const country_id = localStorage.getItem("country_id");

  return !isAuth ? null : (
    <>
      <header className="header">
        <p className="w-100 py-2 text-white  mb-0 container text-bold">
          {translate.welcome} {company_name}
        </p>
      </header>
      <div className="w-100 bg-white pt-2">
        <div className="container ">
          <div className="d-flex justify-content-between align-items-baseline">
            <figure className="logo">
              <img className="w-75" src={logoImg} alt="logo" />
            </figure>
            <div className="header-icons">
              {/* <button
                onClick={() => {
                  dispatch({ type: "logout", payload: "logout" });
                }}
              >
                <i class="fas fa-sign-out-alt"></i>
              </button> */}

              <Notifications />

              <UserSetting />
            </div>
          </div>
        </div>
      </div>

      <nav className="header ">
        <div className="d-flex justify-content-between align-items-stretch  container ">
          <div className="d-flex  ">
            <NavLink
              exact={true}
              to="/"
              activeClassName="is-active"
              className="mr-4 py-2 text-bold"
            >
              {translate.MainMenu}
            </NavLink>

            <ShowForPermission permission={"create-reservation"}>
              <NavLink
                exact={true}
                to="/medical/services"
                isActive={() => {
                  return pathname.includes("/medical/services");
                }}
                activeClassName="is-active"
                className="mr-4 py-2  text-bold"
              >
                {translate.MedicalService}
              </NavLink>
            </ShowForPermission>

            <ShowForPermission permission={"list-reservation"}>
              <NavLink
                exact={true}
                to="/customers"
                isActive={() => {
                  return (
                    pathname.includes("done") || pathname.includes("customers")
                  );
                }}
                activeClassName="is-active"
                className="mr-4 py-2  text-bold"
              >
                {translate.MedicalServiceReq}
              </NavLink>
            </ShowForPermission>

            <ShowForPermission
              permission={[
                "show-wallet",
                "list-transaction-requests-and-transactions",
              ]}
            >
              <NavLink
                exact={true}
                to="/wallet"
                isActive={() => pathname.includes("wallet")}
                activeClassName="is-active"
                className="mr-4 py-2 text-bold"
              >
                {translate.Wallet}
              </NavLink>
            </ShowForPermission>

            <ShowForPermission permission={["list-groups", "list-admins"]}>
              {permissions.includes("list-admins") ||
              permissions.length === 0 ? (
                <NavLink
                  exact={true}
                  to="/team-management/users"
                  activeClassName="is-active"
                  isActive={() => pathname.includes("/team-management/users")}
                  className="mr-4 py-2 text-bold"
                >
                  {translate.teamManagement}
                </NavLink>
              ) : (
                <NavLink
                  exact={true}
                  to="/team-management/groups"
                  activeClassName="is-active"
                  isActive={() => pathname.includes("/team-management/groups")}
                  className="mr-4 py-2 text-bold"
                >
                  {translate.teamManagement}
                </NavLink>
              )}
            </ShowForPermission>
          </div>

          <ShowForPermission permission={"show-wallet"}>
            <div className="d-flex bg-white border-t p-1">
              <div className="medical_box p-0  d-flex align-items-center mx-2 border">
                <i className="fas fa-wallet fa-lg text_blue-dark mx-1"></i>
                <h5 className=" text_blue-dark m-0 ">
                  {translate_companies.safa_balance}
                </h5>
                <button className="btn  p-0 text_blue-dark px-1 mx-1 bg-gray">
                  {safaWallet} {country_id === "20" ? "EGP" : "SAR"}
                </button>
              </div>
              <div className="medical_box p-0 d-flex align-items-center border">
                <i className="fas fa-wallet fa-lg text_blue-dark mx-1"></i>
                <h5 className=" text_blue-dark m-0 ">{translate.Balance} : </h5>
                <button className="btn  p-0 text_blue-dark px-1 mx-1 text-bold bg-gray">
                  {balance} {country_id === "20" ? "EGP" : "SAR"}
                </button>
              </div>
            </div>
          </ShowForPermission>
        </div>
      </nav>
    </>
  );
}
export default Header;
