import React, { useState, useEffect } from "react";
import DatePicker from "../../../components/shared/Admin/DatePicker";
import TextField from "../../../components/shared/Admin/textField";
import SelectField from "../../../components/shared/SelectField";
import Locale from "../../../translations";
import moment from "moment";

import { ListChecksDropDown } from "../../../services/admin";

export default function Filter(props) {
  const { translate, translate_companies, Accounts } = Locale;

  const currentDate = moment(new Date());
  const limit = moment(currentDate).subtract(1, "days");

  const options = [
    { value: '0', label: Accounts.method_paymnt },
    { value: 'credit', label: Accounts.type_credit },
    { value: 'wallet', label: Accounts.type_wallet }
  ];

  const [optionsTypeExaminationState, setOptionsTypeExaminationState] = useState([]);
  const [optionsMethodPaymntState, setOptionsMethodPaymntState] = useState([]);
  const [searchCompanyNameState, setSearchCompanyNameState] = useState('');
  const [dateState, setDateState] = useState(null);
  const [dateQState, setDateQState] = useState('');
  const [methodPaymntState, setMethodPaymntState] = useState(0);
  const [typeExaminationState, setTypeExaminationState] = useState(0);

  useEffect(() => {
    setOptionsMethodPaymntState(options);
  }, [methodPaymntState]);

  useEffect(() => {
    async function fetchData() {
      let filterDrop = [
        { value: 0, label: Accounts.type_examination }
      ];
      const DropDown = await ListChecksDropDown();
      let dataDrop = DropDown.data.checks;

      let filterDropData= dataDrop.map(item => {
        filterDrop.push({value : item.id, label: item.name})
      });
      
      

      setOptionsTypeExaminationState(filterDrop);  
    }
    fetchData();
  }, []);

  const searchCompanyNameChangedHandler = (event) => {
    setSearchCompanyNameState(event.target.value);
  }

  const dateChangedHandler = (event) => {
    setDateQState(moment(event._d).format("YYYY-MM-DD"));
    setDateState(event);
  }

  const methodPaymntChangedHandler = (event) => {
    if(event.value !== 0) {
      setMethodPaymntState(event);
    }
  }

  const typeExaminationChangedHandler = (event) => {
    if(event.value !== 0) {
      setTypeExaminationState(event);
    }
  }

  const searchSubmitHandler = () => {
    props.FilterSubmitted(dateQState, searchCompanyNameState, typeExaminationState.value, methodPaymntState.value);
  }

  const resetSearchHandler = () => {
    setDateState('');
    setSearchCompanyNameState('');
    setMethodPaymntState(0);
    setTypeExaminationState(0);
    props.ResetSubmitted();
  };

  return (
    <div className="CompanyFilterContainer">
      <div className="row no-gutters " style={{ width: "100% " }}>
      <div className="col mr-2 ">
          <TextField
            hasLabel={false}
            search={true}
            value={searchCompanyNameState}
            onChange={(event) => searchCompanyNameChangedHandler(event)}
            label={translate_companies.company_name}
            type={"text"}
            placeholder={translate_companies.company_name}
          >
            <i className="fas fa-search"></i>
          </TextField>
        </div>

        <div className="col mr-2  ">
        <SelectField
          hasLabel={false}
          placeholder={Accounts.type_examination}
          options={optionsTypeExaminationState}
          value={typeExaminationState}
          onChange={(event)=>typeExaminationChangedHandler(event)}
        />
      </div>

        <div className="col mr-2  ">
          <DatePicker
            hasLabel={false}
            date={dateState}
            isOutsideRange={() => false}          
            label={Accounts.date_payment}
            placeholder={Accounts.date_payment}
            displayFormat={"YYYY-MM-DD"}
            minDate={limit}
            onChangeDate={(e) => dateChangedHandler(e)}
          />
        </div>

        <div className="col mr-2  ">
        <SelectField
          hasLabel={false}          
          placeholder={Accounts.method_paymnt}
          options={optionsMethodPaymntState}
          value={methodPaymntState}
          onChange={(event)=>methodPaymntChangedHandler(event)}
        />
      </div>

        <div className="col  pt-1" >
          <button onClick={searchSubmitHandler} className="btn btn-primary px-5 py-2 font-weight-bold CompaniesList-button">
            {translate.search}
          </button>
          <button onClick={resetSearchHandler} className="btn text-muted font-weight-bold ">
            {" "}
            <i className="fas fa-retweet"></i>{translate.reset}
          </button>
        </div>

      </div>
    </div>
  );
}
