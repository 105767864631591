import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { company_getWalletDeals } from "../../../../services/admin";
import Filter from "./Filter";

import Locale from "translations";
import Pagination from "components/shared/Pagination";

export default function WalletDeals({ companyID }) {
    const { wallet, translate, translate_companies } = Locale;

    const [deals, setDeals] = useState();
    const [dealsMeta, setDealsMeta] = useState();

    const [filter, setFilter] = useState({
        status: '',
        company_id: companyID,
        transaction_number: '',
        transaction_date: '',
        type: ''
    });

    useEffect(() => {
        async function fetchData() {
            const companyDeals = await company_getWalletDeals(filter);
            setDeals(companyDeals?.data);
            setDealsMeta(companyDeals?.meta);
            
        }
        fetchData();
    }, [filter]);

    // pagination
    const goTo = async (page) => {
        setFilter({ ...filter, page: page });
    };

    const filterSubmitted = (statusQ, searchQ, dateQ) => {
        
        
        if (statusQ !== '0') {
            setFilter({ ...filter, type: statusQ, transaction_number: searchQ, transaction_date: dateQ, page: 1 });
        } else {
            setFilter({ ...filter, transaction_number: searchQ, transaction_date:dateQ, page: 1 });
        }
    }

    const resetSubmitted = () => {
        setFilter({ ...filter, type: '', transaction_number: '', page: 1 });
    }

    return (
        <React.Fragment>
            <header className="d-flex justify-content-between  Adminlist_Fullheader border py-3 px-2  ">
                <Filter
                    ResetSubmitted={() => resetSubmitted()}
                    FilterSubmitted={(status, searchQ, date) => filterSubmitted(status, searchQ, date)} />
            </header>

            <table className="table">
                <thead className="">
                    <tr className="table_head">
                        <th scope="col">{wallet.receiptNumber}</th>
                        <th scope="col">{wallet.requestDate}</th>
                        <th scope="col">{wallet.amount}</th>
                        <th scope="col">{translate_companies.transaction_type}</th>
                        <th scope="col">{translate_companies.paymentMethod}</th>
                        <th scope="col">{wallet.status}</th>
                        <th scope="col">{translate.processes}</th>
                    </tr>
                </thead>
                <tbody>
                    {deals && deals.length > 0 ? (
                        deals.map((deal) => (
                            <tr key={deal.id}>
                                <td>{deal.transaction_number}</td>
                                <td>{deal.transaction_date}</td>
                                <td>{deal.amount}</td>
                                <td>{deal.type}</td>
                                <td>{deal.amount}</td>
                                <td className={"text-green"}>{translate_companies.approved}</td>
                                <td className="d-flex">
                                    <NavLink
                                        exact={true}
                                        to={`/admin/companies/wallet/deals-view/` + deal.id}
                                        activeClassName="is-active"
                                        className="p-2 text-primary mr-3 btn-link "
                                    >
                                        <i className="fas fa-eye"></i>
                                        {translate.view}
                                    </NavLink>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="11">
                                <div className=" text-center">
                                    <i className="fas fa-info-circle fa-lg text-muted m-3 "></i>{" "}
                                    <h4 className=" text-muted">{translate.noResult}</h4>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <Pagination info={dealsMeta} goTo={goTo} items={10} />
        </React.Fragment>
    );
}
