import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Locale from "translations";
import logoImg from "assets/images/Image1.png";
import Notifications from "./Notifications/Notifications";
import UserSetting from "components/UsetSetting/UserSetting";
import ShowForPermission from "helpers/showForPermission";
import { useCompanyDispatch, useCompanyState } from "context/global";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import useWallet from "modules/Yarvel/wallet/useWallet";
import useUserDetails from "helpers/useUserDetalis";
import { useState } from "react";
export default function MobileHeader() {
  const { translate, translate_companies } = Locale;
  const { pathname } = useLocation();

  let history = useHistory();
  const isAuth = localStorage.getItem("isAuth");
  const dispatch = useCompanyDispatch();
  const balances = useWallet();
  const { balance, safaWallet, permissions, is_admin } = useCompanyState();
  const company_name = localStorage.getItem("company_name");
  const [isMobile, setisMobile] = useState(true);
  const { details } = useUserDetails();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const styles = {
    paper: {
      background: "blue",
    },
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="header border-0 MobileHeader"
    >
      <List className="w-100 py-0">
        {" "}
        <ShowForPermission permission={"show-wallet"}>
          <div className="d-flex flex-column bg-white border-b p-3 w ">
            <div className="medical_box p-0  d-flex align-items-center border ">
              <i className="fas fa-wallet fa-lg text_blue-dark mx-1"></i>
              <h6 className=" text_blue-dark m-0 lan">
                {translate_companies.safa_balance} :
              </h6>
              <button className="btn  p-0 text_blue-dark px-1 mx-1 bg-gray lan">
                {safaWallet} EGP
              </button>
            </div>
            <div className="medical_box p-0 d-flex align-items-center border mt-2">
              <i className="fas fa-wallet fa-lg text_blue-dark mx-1"></i>
              <h6 className=" text_blue-dark m-0 lan">
                {translate.Balance} :{" "}
              </h6>
              <button className="btn  p-0 text_blue-dark px-1 mx-1 text-bold bg-gray lan">
                {balance} EGP
              </button>
            </div>
          </div>
        </ShowForPermission>
      </List>

      <List className="lan text-center">
        {" "}
        <NavLink
          exact={true}
          to="/"
          activeClassName="is-active"
          className="mr-4 py-2 text-bold "
        >
          {translate.MainMenu}
        </NavLink>
      </List>
      <List className="lan text-center">
        {" "}
        <ShowForPermission permission={"create-reservation"}>
          <NavLink
            exact={true}
            to="/medical/services"
            isActive={() => {
              return pathname.includes("/medical/services");
            }}
            activeClassName="is-active"
            className="mr-4 py-2  text-bold"
          >
            {translate.MedicalService}
          </NavLink>
        </ShowForPermission>
      </List>

      <List className="lan text-center">
        <ShowForPermission permission={"list-reservation"}>
          <NavLink
            exact={true}
            to="/customers"
            isActive={() => {
              return (
                pathname.includes("done") || pathname.includes("customers")
              );
            }}
            activeClassName="is-active"
            className="mr-4 py-2  text-bold"
          >
            {translate.MedicalServiceReq}
          </NavLink>
        </ShowForPermission>
      </List>

      <List className="lan text-center">
        {" "}
        <ShowForPermission
          permission={[
            "show-wallet",
            "list-transaction-requests-and-transactions",
          ]}
        >
          <NavLink
            exact={true}
            to="/wallet"
            isActive={() => pathname.includes("wallet")}
            activeClassName="is-active"
            className="mr-4 py-2 text-bold"
          >
            {translate.Wallet}
          </NavLink>
        </ShowForPermission>
      </List>

      <List className="lan text-center">
        <ShowForPermission permission={["list-groups", "list-admins"]}>
          {permissions.includes("list-admins") || permissions.length === 0 ? (
            <NavLink
              exact={true}
              to="/team-management/users"
              activeClassName="is-active"
              isActive={() => pathname.includes("/team-management/users")}
              className="mr-4 py-2 text-bold"
            >
              {translate.teamManagement}
            </NavLink>
          ) : (
            <NavLink
              exact={true}
              to="/team-management/groups"
              activeClassName="is-active"
              isActive={() => pathname.includes("/team-management/groups")}
              className="mr-4 py-2 text-bold"
            >
              {translate.teamManagement}
            </NavLink>
          )}
        </ShowForPermission>
      </List>

      <List className="lan text-center"></List>
      <List className="lan text-center"></List>
    </Box>
  );

  return (
    <>
      <header className="header">
        <p className="w-100 py-2 text-white  mb-0 container text-bold">
          {translate.welcome} {company_name}
        </p>
      </header>

      <div className="w-100 bg-white pt-2">
        <div className="container ">
          <div className="d-flex justify-content-between align-items-center">
            <figure
              className="logo"
              onClick={() => {
                history.push("/");
              }}
            >
              <img className="w-75" src={logoImg} alt="logo" />
            </figure>
            <div className="header-icons d-flex align-items-center">
              <Notifications isMobile={isMobile} />
              <UserSetting isMobile={isMobile} />
              <div>
                {["left"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                      <i class="fas fa-list fa-2x"></i>
                      {/* {anchor} */}
                    </Button>
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      PaperProps={{
                        sx: {
                          backgroundColor: "#324356",
                        },
                      }}
                    >
                      {list(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
