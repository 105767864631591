import axios from "axios";
import { cleanEmpty } from "./general";
const SafarnaURL = process.env.REACT_APP_API_URL + "/api";
// const [protocol, domain] = baseURL.split("//");

const host = window.location.host;
const subdomain = host != null ? host.split(".")[0] : null;

// export const SafarnaURL = `http://yarvel-back-staging.t10.safa.asia/api`;

// const URL = `http://${subdomain}.${domain}/api`;

export const getUserDetails = async () => {
  return await axios
    .get(`${SafarnaURL}/auth/me`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const forgetPassword = async (data) => {
  return await axios
    .post(`${SafarnaURL}/auth/forget-password`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const resetPassword = async (data) => {
  return await axios
    .post(`${SafarnaURL}/auth/reset-password`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const singin = async (data) => {
  return await axios
    .post(`${SafarnaURL}/auth/login`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => err.response);
};

export const getClientList = async (filter) => {
  return await axios
    .get(`${SafarnaURL}/pcr/reservation`, {
      params: cleanEmpty(filter),
    })
    .then((res) => res)
    .catch((err) => err.response);
};

export const getExamnation = async (data) => {
  return await axios
    .post(`${SafarnaURL}/pcr/services`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const addClientsWithpcr = async (data) => {
  return await axios
    .post(`${SafarnaURL}/trips`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const addClients = async (data) => {
  return await axios
    .post(`${SafarnaURL}/travelers/bulk`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const updateTrip = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/trips/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const updateClients = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/travelers/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const updateReservation = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/pcr/reservation/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const saveReservation = async (id, data) => {
  return await axios
    .post(`${SafarnaURL}/pcr/reservation/bulk/update`, cleanEmpty(id, data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const createPayment = async (data) => {
  return await axios
    .post(`${SafarnaURL}/payments`, cleanEmpty(data))
    .then((res) => res)
    .catch((err) => err.response);
};

export const getClientsReservation = async (id) => {
  return await axios
    .get(`${SafarnaURL}/trips/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const veiwReservation = async (id) => {
  return await axios
    .get(`${SafarnaURL}/trips/${id}?show=1`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getMRZ = async (mrz) => {
  return await axios
    .post(`${SafarnaURL}/travelers/mrz`, mrz)
    .then((res) => res)
    .catch((err) => err.response);
};

export const deletClient = async (id) => {
  return await axios
    .delete(`${SafarnaURL}/travelers/${id}?company_id=1`)
    .then((res) => res)
    .catch((err) => err.response);
};

/* Team Managment  */
export const ListUser = async () => {
  return await axios
    .get(`${SafarnaURL}/admins`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const RetrievedUser = async (id) => {
  return await axios
    .get(`${SafarnaURL}/admins/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const ListGroups = async () => {
  return await axios
    .get(`${SafarnaURL}/groups`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const changeStatus = async (id, status) => {
  return await axios
    .put(`${SafarnaURL}/admins/${id}/${status}`)
    .then((res) => res)
    .catch((err) => err.response);
};
export const changePasswordAPI = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/admins/auth/change-password`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const editUserAPI = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/admins/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const listGroupsDp = async () => {
  return await axios
    .get(`${SafarnaURL}/groups`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const listPermissionsDB = async () => {
  return await axios
    .get(`${SafarnaURL}/groups/permissions`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const addUserAPI = async (data) => {
  return await axios
    .post(`${SafarnaURL}/admins`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const addGroupAPI = async (data) => {
  return await axios
    .post(`${SafarnaURL}/groups`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const RetrievedGroup = async (id) => {
  return await axios
    .get(`${SafarnaURL}/groups/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const editGroupAPI = async (id, data) => {
  return await axios
    .put(`${SafarnaURL}/groups/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getHomePageData = async () => {
  return await axios
    .get(`${SafarnaURL}/statistics/company`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getNotification = async (filter) => {
 
  return await axios
    .get(`${SafarnaURL}/notifications`, {
      params: cleanEmpty(filter),
    })
    .then((res) => res)
    .catch((err) => err.response);
};

export const readNotification = async (id) => {
  return await axios
    .get(`${SafarnaURL}/read-notification/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

/* login  */

export const addLoginAPI = async (data) => {
  return await axios
    .post(`${SafarnaURL}/visa/token`, data)
    .then((res) => res)
    .catch((err) => err.response);
};
export const getGroupAPI = async (data,size,page) => {
  let formData=''
  formData = new FormData();
        formData.append('token', data.token);
        formData.append('data[size]' ,size);
        formData.append('data[page]',page)

  return await axios
    .post(`${SafarnaURL}/visa/groups`, formData)
    .then((res) => res)
    .catch((err) => err.response);
};
export const viewGroupAPI = async (id,data) => {
  return await axios
    .post(`${SafarnaURL}/visa/groups/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};
export const addTravelersAPI = async (data) => {
  return await axios
    .post(`${SafarnaURL}/trips/safavisa`, data)
    .then((res) => res)
    .catch((err) => err.response);
};
