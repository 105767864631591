import React from "react";
import TextField from "../../../components/shared/textField";
import Locale from "../../../translations";


export default function FilterGroup() {
  const { translate } = Locale;
  return (
    <>
      <div className="row no-gutters list_header" style={{ width: "100%" }}>
        <div className="col-12 col-md-5 m-0 mr-md-2">
          <TextField
            hasLabel={false}
            label={translate.search}
            type={"text"}
            className={"mb-2"}
            placeholder={translate.search}
          >
            <i className="fas fa-search"></i>
          </TextField>
        </div>

        <div className="col-12 col-md-3   text-center   pt-1">
          <button className="btn btn-primary px-5 py-2 font-weight-bold">
            {translate.search}
          </button>
          <button className="btn text-muted font-weight-bold ">
            {" "}
            <i className="fas fa-retweet"></i> {translate.reset}
          </button>
        </div>
      </div>
    </>
  );
}
