import axios from "axios";
const authURL = process.env.REACT_APP_API_URL + "/v1/auth";
const URI = process.env.REACT_APP_FILEUPLOAD_URL;
const URL = process.env.REACT_APP_API_URL;

export const emailAvailability = async (data) => {
  return await axios
    .get(`${URL}/api/companies/check-unique/${data}`)
    .then((res) => res)
    .catch((err) => err);
};

export const nameAvailability = async (data) => {
  return await axios
    .post(`${URL}/check_avaliable_company_name`, data)
    .then((res) => res)
    .catch((err) => err);
};

export const activeAccount = async (data) => {
  return await axios
    .post(`${authURL}/active-account`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const verifyEmail = async (data) => {
  return await axios
    .post(`${URL}/check_company_and_user_status`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const userLogin = async (data) => {
  return await axios
    .post(`${URL}/login`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const forgetPass = async (data) => {
  return await axios
    .post(`${URL}/forget-password`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const passReset = async (data) => {
  return await axios
    .post(`${URL}/forget-password/reset`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const accountRegister = async (data) => {
  return await axios
    .post(`${URL}/api/companies`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const accountUpdate = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  return await axios
    .post(`${URL}/api/companies`, data, config)
    .then((res) => res)
    .catch((err) => err.response);
};

export const uploadFile = async (data) => {
  return await axios
    .post(
      `${URI}`,
      data
      // , {
      // 	headers: {
      // 		"system-name": "Files_MS",
      // 		"system-secret": "secret",
      // 		"content-type": "multipart/form-data",
      // 	},
      // }
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const fetchImages = async (id) => {
  return await axios
    .get(`${URI}/fetch/${id}`)
    .then((res) => res.config.url)
    .catch((err) => err.response);
};
