export default function reducers(state, action) {
  switch (action.type) {
    case "setLoading":
      return { ...state, AdminLoading: action.payload };

    case "RedirectToHome": {
      return { ...state, havePermission: action.payload };
    }
    case "DecreseNoifications":
      return { ...state, notificationsNumbers: state.notificationsNumbers - 1 };

    case "setNotifications":
      return { ...state, notificationsNumbers: action.payload };

    case "setLocale":
      localStorage.setItem("currentLanguage", action.payload);
      window.location.reload();
      return { ...state, currentLanguage: action.payload };
    case "setBalance":
      return { ...state, balance: action.balance };
    case "logout":
      localStorage.clear();
      window.location.href = "/admin/login";
      return { ...state };

    case "getPermissions":
      //debugger;
      localStorage.setItem("userType", action.payload.user_type);
      localStorage.setItem(
        "Admin_permissions",
        JSON.stringify(action.payload.permissions)
      );
      return {
        ...state,
        Admin_permissions: action.payload.permissions,
        userType: action.payload.user_type,
        is_admin: action.payload.is_admin,
      };

    case "login":
      localStorage.setItem("Admin_isAuth", true);
      localStorage.setItem("Admin_token", action.payload.access_token);
      localStorage.setItem("Admin_userType", action.payload.user_type);
      localStorage.setItem("Admin_company_id", action.payload.company_id);
      localStorage.setItem("is_Admin", action.payload.is_admin);
      localStorage.setItem(
        "Admin_permissions",
        JSON.stringify(action.payload.permissions)
      );
      return {
        ...state,
        isAuth: true,
        token: action.payload.access_token,
        Admin_permissions: action.payload.permissions,
        userType: action.payload.user_type,
        company_id: action.payload.company_id,
        is_admin: action.payload.is_admin,
      };
    // eslint-disable-next-line no-fallthrough
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
