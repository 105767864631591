import { LooksupCities, LooksupCountries } from "components/lookups";
import DatePicker from "components/shared/DatePicker";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/textField";
import { useClientsDispatch, useClientsState } from "context/addClient";
import { useCompanyDispatch, useCompanyState } from "context/global";

import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Card,
  CardBody,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import {
  addLoginAPI,
  deletClient,
  getClientsReservation,
  getGroupAPI,
  getMRZ,
  updateClients,
} from "services/yarvel";
import { fetchAreas } from "services/lookups";
import { cleanEmpty } from "services/general";
import { cleanKeys } from "helpers/cleanKeys";
import ShowForPermission from "helpers/showForPermission";
import Locale from "translations";
import { Table } from "material-ui";
import LoginSafa from "./loginSafa";

export default function Travelers({
  data,
  index,
  setErrorsClients,
  errorsClients,
  checkFormallErrors,
  setDoneCheck,
  doneCheck,
  setSwitchTo,
  switchTo,
  removeClient,
  deleted,
  toggleModel,
  isOpenModel,
  setDeleted,

  travelersData,
  setTravelersData,

}) {
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenGroupModel, setIsOpenGroupModel] = useState(false);

  const locale = localStorage.getItem("currentLanguage") || "en";
  const dispatch = useClientsDispatch();
  const dispatchLogin = useCompanyDispatch();
  const { TravelerApi } = useClientsState();
  const toggle = () => setIsOpen(!isOpen);
  const toggleGroupModel = () => setIsOpenGroupModel(!isOpenGroupModel);

  const { translate, userDetails } = Locale;
  const { currentLanguage } = useCompanyState();
  const { trip_id } = useParams();
  const cities = LooksupCities();
  const countries = LooksupCountries();
  const { test_type } = useParams();
  const [area, setArea] = useState([]);
  const { listClients } = useClientsState();
  const [clientData, setclientData] = useState({
    en_first_name: "",
    ar_first_name: "",
    en_father_name: "",
    ar_father_name: "",
    en_grandpa_name: "",
    ar_grandpa_name: "",
    en_last_name: "",
    ar_last_name: "",
    passport_number: "",
    expiration_date: null,
    nationality: "",
    country_id: "20",
    test_type: test_type,
    national_id: "",
    index: index,
  });
  const [startVaild, setstartVaild] = useState(false);
  const [checkedEdit, setcheckedEdit] = useState(false);
  const [startVaildEdit, setstartVaildEdit] = useState(false);
  const [editing, setEditing] = useState(false);
  const [toggleCollapse, setToggleCollapse] = useState(false);

  const currentDate = moment(new Date());
  const limit = moment(currentDate).add(1, "M");
  const { allTravelerApi } = useClientsState();
console.log("toggleCollapse",toggleCollapse);
  const handleChange = (e) => {
    if ("target" in e) {
      const value = e.target.value;
      const name = e.target.name;
      setclientData({ ...clientData, [name]: value });
    } else {
      const value = e.value;
      const name = e.name;
      setclientData({ ...clientData, [name]: value });
    }
  };

  /*   to  make edit in Travelers and switch Tap*/
  useEffect(() => {
    if (data && data.ar_first_name && (switchTo.tripId != " " || trip_id)) {
      setclientData({
        ...cleanKeys(data),
        expiration_date: data.expiration_date
          ? moment(data.expiration_date)
          : "",
        nationality: {
          value: data?.nationality_id,
          label: data?.nationality,
        },
      });
    } else {
      if (
        data.hasOwnProperty("ar_first_name") &&
        data &&
        clientData.index != data.index
      ) {
        setclientData({
          ...clientData,
          en_first_name: data.en_first_name,
          ar_first_name: data.ar_first_name,
          en_father_name: data.en_father_name,
          ar_father_name: data.ar_father_name,
          en_grandpa_name: data.en_grandpa_name,
          ar_grandpa_name: data.ar_grandpa_name,
          en_last_name: data.en_last_name,
          ar_last_name: data.ar_last_name,
          passport_number: data.passport_number,
          nationality: data.nationality,
          national_id: data.national_id,
          phone: data.phone,
          expiration_date: data.expiration_date
            ? moment(data.expiration_date)
            : "",
          country_id: "20",
          address: data.address,
          test_type: test_type,
          index: index,
        });
      }
    }
  }, [data]);

  /* sending data to clients state */
  useEffect(() => {
    const sendData = setTimeout(() => {
      dispatch({
        type: "addClientData",
        payload: {
          ...clientData,
          expiration_date: clientData?.expiration_date
            ? moment(clientData?.expiration_date).format("YYYY-MM-DD ")
            : "",
          nationality: clientData?.nationality?.value,
        },
        index: index,
      });
    }, 1000);

    return () => {
      clearTimeout(sendData);
    };
  }, [clientData]);

  /* check errors */
  const checkFormErrorsfileds = () => {
    //debugger
    let error = errorsClients;

    if (!toggleCollapse) {
      let newError = {
        ...validate(
          {
            name: "en_first_name",
            value: clientData.en_first_name,
          },
          {
            required: true,
            notNumber: true,
          }
        ),

        /*     ...validate(
        {
          name: "national_id",
          value: clientData.national_id,
        },
        {
          number: true,
        }
      ), */

        ...validate(
          {
            name: "en_father_name",
            value: clientData.en_father_name,
          },
          {
            notNumber: true,
          }
        ),
        ...validate(
          {
            name: "en_grandpa_name",
            value: clientData.en_grandpa_name,
          },
          {
            notNumber: true,
          }
        ),
        ...validate(
          {
            name: "en_last_name",
            value: clientData.en_last_name,
          },
          {
            required: true,
            notNumber: true,
          }
        ),
        ...validate(
          {
            name: "ar_first_name",
            value: clientData.ar_first_name,
          },
          {
            required: true,
            notNumber: true,
          }
        ),
        ...validate(
          {
            name: "ar_father_name",
            value: clientData.ar_father_name,
          },
          {
            notNumber: true,
          }
        ),
        ...validate(
          {
            name: "ar_grandpa_name",
            value: clientData.ar_grandpa_name,
          },
          {
            notNumber: true,
          }
        ),
        ...validate(
          {
            name: "ar_last_name",
            value: clientData.ar_last_name,
          },
          {
            required: true,
            notNumber: true,
          }
        ),
        ...validate(
          {
            name: "passport_number",
            value: clientData.passport_number,
          },
          {
            required: true,
          }
        ),
        /*       ...validate(
        {
          name: "expiration_date",
          value: clientData.expiration_date,
        },
        {
          required: true,
        }
      ), */
        /*     ...validate(
        {
          name: "phone",
          value: clientData.phone,
        },
        {
          required: true,
        }
      ), */

        /*       ...validate(
        {
          name: "address",
          value: clientData.address,
        },
        {
          required: true,
        }
      ), */
      };

      if (error[index]) {
        error[index] = {
          ...error[index],
          ...newError,
        };
      } else if (Array.isArray(error)) {
        error.splice(index, 0, newError);
      }
    } else {
      let newError = {
        ...validate(
          {
            name: "en_first_name",
            value: clientData.en_first_name,
          },
          {
            required: false,
            notNumber: true,
          }
        ),

        ...validate(
          {
            name: "en_father_name",
            value: clientData.en_father_name,
          },
          {
            notNumber: true,
          }
        ),
        ...validate(
          {
            name: "en_grandpa_name",
            value: clientData.en_grandpa_name,
          },
          {
            notNumber: true,
          }
        ),
        ...validate(
          {
            name: "en_last_name",
            value: clientData.en_last_name,
          },
          {
            required: false,
            notNumber: true,
          }
        ),
        ...validate(
          {
            name: "ar_first_name",
            value: clientData.ar_first_name,
          },
          {
            required: false,
            notNumber: true,
          }
        ),
        ...validate(
          {
            name: "ar_father_name",
            value: clientData.ar_father_name,
          },
          {
            notNumber: true,
          }
        ),
        ...validate(
          {
            name: "ar_grandpa_name",
            value: clientData.ar_grandpa_name,
          },
          {
            notNumber: true,
          }
        ),
        ...validate(
          {
            name: "ar_last_name",
            value: clientData.ar_last_name,
          },
          {
            required: false,
            notNumber: true,
          }
        ),
        ...validate(
          {
            name: "passport_number",
            value: clientData.passport_number,
          },
          {
            required: false,
          }
        ),
      };

      if (error[index]) {
        error[index] = {
          ...error[index],
          ...newError,
        };
      } else if (Array.isArray(error)) {
        error.splice(index, 0, newError);
      }
    }

    //debugger
    setErrorsClients(error);
  };

  /* check errors to save clients */
  useEffect(() => {
    //debugger
    if (startVaild) {
      checkFormErrorsfileds();
      setDoneCheck(!doneCheck);

      setclientData({ ...clientData });
    }
    setstartVaild(true);

  }, [checkFormallErrors]);
console.log("checkFormallErrors",checkFormallErrors);
  const setErrorsClientsfiled = (name, value, role) => {
    let error = errorsClients;
    error[index] = {
      ...error[index],
      ...validate({ name: name, value: value }, role),
    };
    setErrorsClients(error);
  };

  /* edit  Clients FLOW */
  const submitEdit = () => {
    setcheckedEdit(!checkedEdit);
    setstartVaildEdit(true);
  };

  useEffect(() => {
    if (startVaildEdit) {
      checkFormErrorsfileds();
      setEditing(true);
    }
    setstartVaildEdit(false);
  }, [checkedEdit]);

  useEffect(() => {
    const editClients = async (id) => {
      if (isFormValid(errorsClients[index]) && editing) {
        const resClients = await updateClients(
          id,
          cleanEmpty({
            ...clientData,
            expiration_date: clientData.expiration_date
              ? moment(clientData?.expiration_date).format("YYYY-MM-DD")
              : null,
            country_id: 20,
            nationality: clientData.nationality
              ? clientData.nationality.value
              : "",
            national_id: clientData?.national_id,
            trip_id: trip_id,
          })
        );

        setErrorsClients({});
        if (!id) {
          dispatch({
            type: "clients",
            payload: resClients?.data.data.travelers,
          }); /* to make data agin after add client in edit */
        }
      }
    };
    editClients(data.id);
  }, [editing]);

  /* MRZ FLOW */
  const mrz = (id) => {
    const res = getMRZ({
      mrz: id,
    });
  };

  /*Login */
  const [loginData, setLoginData] = useState({});
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const token_api = localStorage.getItem("token_api");
  const [groupList, setGroupList] = useState({});
  const [groupView, setGroupView] = useState({});

  const checkFormErrors = () => {
    setErrors({
      ...errors,
      ...validate(
        {
          name: "safa_key",
          value: loginData.safa_key,
        },
        {
          required: true,
        }
      ),
      ...validate(
        {
          name: "email",
          value: loginData.email,
        },
        {
          required: true,
        }
      ),
      ...validate(
        {
          name: "pwd",
          value: loginData.pwd,
        },
        {
          required: true,
        }
      ),
    });
  };
  //getGroupAPI
  const fetchGroupAPI = async (token) => {
    const res = await getGroupAPI(token);
    setGroupList(res?.data?.data?.rows);
  };
  useEffect(() => {
    setGroupList(groupList);
  }, [groupList]);
  //viewGroupAPI
  const viewGroupAPI = async (id, token) => {
    const res = await viewGroupAPI(id, token);
    setGroupView(res);
  };

  useEffect(() => {
    const add = async () => {
      if (isFormValid(errors)) {
        const res = await addLoginAPI(loginData);

        if (res.status == 200 || res.status == 201) {
          dispatchLogin({
            type: "getTokenApi",
            payload: res?.data?.data?.token,
          });
          toggleModel();
          toggleGroupModel(!isOpenGroupModel);

          fetchGroupAPI({ token: token_api });
        }
      }
    };

    add();


  }, [isErrorLoaded]);

  const handleForm = async () => {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  };

  const dataList =
    groupList && groupList.length > 0
      ? groupList.map((list, index) => {
          return (
            <tr key={index}>
              <td>{list.id}</td>
              <td>{list.json.Group_Name}</td>
              <td>{list.json.tag}</td>
              <td>{Object.keys(list.json.in_group).length}</td>
              <td
                style={{ width: "80px", cursor: "pointer" }}
                className="text-primary"
              >
                <i
                  class="fas fa-eye text-primary text-center"
                  onClick={viewGroupAPI(list.id, token_api)}
                ></i>{" "}
                {translate.view}
              </td>
            </tr>
          );
        })
      : "";

  console.log("travelersData00", TravelerApi);
  console.log("toggleCollapse", toggleCollapse);
  return doneCheck === true || doneCheck === false ? (
    <>
      {!trip_id && (
        <div className="container">
          <button
            className="btn p-2 bold my-3  "
            style={{ background: "#D2AC67", fontWeight: "600" }}
            onClick={() => {
              toggleModel();
              setToggleCollapse(true);
            }}
          >
            استيراد من صفا فيزا
          </button>
        </div>
      )}

      <div className="mx-4 shadow mt-4  ">
        {!toggleCollapse && (
          <>
            <div className="list_header row justify-content-between align-items-center m-0 p-3 rounded-top ">
              <div className="d-flex col-12 col-md-8 text-mobile__center">
                <button onClick={toggle} className="rounded-top font-mobile   ">
                  <p className="m-0 h4  font-weight-bold text-color font-mobile  w-100 text-mobile__center">
                    {`${translate.client} (${index + 1}) 
            ${
              currentLanguage === "ar"
                ? `${clientData?.ar_first_name}  ${
                    clientData?.ar_father_name != null
                      ? clientData?.ar_father_name
                      : ""
                  }  ${
                    clientData?.ar_grandpa_name != null
                      ? clientData?.ar_grandpa_name
                      : ""
                  } ${clientData?.ar_last_name}`
                : `${clientData?.en_first_name}    ${
                    clientData?.en_father_name != null
                      ? clientData?.ar_father_name
                      : ""
                  }  ${
                    clientData?.en_grandpa_name != null
                      ? clientData?.ar_grandpa_name
                      : ""
                  }  ${clientData?.en_last_name} `
            }
          `}
                  </p>
                </button>
              </div>

              <div
                onPaste={mrz}
                className="d-flex align-items-center justify-content-end col-12 col-md-4 mt-3 mt-md-0 mobile-remove"
              >
                <div className="bg-white p-0 btn mr-3 d-flex align-items-center mobile-remove">
                  <h5 className="m-0 mx-2 text-muted font-mobile mobile-remove">
                    {translate.PassporAuto}
                  </h5>
                  <i className="fas fa-qrcode icons_label mx-1 p-1 font-mobile mobile-remove"></i>
                </div>
                {/* <div className="bg-white p-0 btn mr-3 d-flex align-items-center mobile-remove">
            <h5 className="m-0  text-muted font-mobile mobile-remove">
              <button
                className="btn p-2 bold"
                style={{ background: "#D2AC67", fontWeight: "600" }}
                onClick={toggleModel}
              >
                استيراد من صفا فيزا
              </button>
            </h5>
          </div> */}
                {isOpen ? (
                  <i className="fas fa-angle-down icons_label font-mobile  mobile-remove"></i>
                ) : currentLanguage === "ar" ? (
                  <i className="fas fa-angle-left  icons_label font-mobile mobile-remove "></i>
                ) : (
                  <i className="fas fa-angle-right  icons_label font-mobile mobile-remove"></i>
                )}
              </div>
            </div>

            <Collapse isOpen={isOpen}>
              <Card className="py-4 px-2 px-md-5">
                <CardBody>
                  <div className="row">
                    <div className="col-12 col-md-3">
                      <TextField
                        disabled={clientData.payment_status_flag === true}
                        hasLabel={true}
                        label={"First Name"}
                        value={clientData?.en_first_name}
                        placeholder={"Enter First Name"}
                        name="en_first_name"
                        min={3}
                        errors={errorsClients[index]?.en_first_name}
                        color={
                          errorsClients[index]?.en_first_name?.required ||
                          errorsClients[index]?.en_first_name?.notNumber
                            ? "danger"
                            : ""
                        }
                        onChange={(e) => {
                          setclientData({
                            ...clientData,
                            en_first_name: e.target.value,
                          });
                          setErrorsClientsfiled(
                            "en_first_name",
                            e.target.value,
                            {
                              required: true,
                              notNumber: true,
                            }
                          );
                        }}
                      ></TextField>
                    </div>
                    <div className="col-12 col-md-3">
                      <TextField
                        disabled={clientData.payment_status_flag === true}
                        hasLabel={true}
                        value={clientData?.en_father_name}
                        label={"Father Name"}
                        placeholder={"Enter Father Name"}
                        name="en_father_name"
                        min={3}
                        errors={errorsClients[index]?.en_father_name}
                        color={
                          errorsClients[index]?.en_father_name?.notNumber
                            ? "danger"
                            : ""
                        }
                        onChange={(e) => {
                          setclientData({
                            ...clientData,
                            en_father_name: e.target.value,
                          });
                          setErrorsClientsfiled(
                            "en_father_name",
                            e.target.value,
                            {
                              notNumber: true,
                            }
                          );
                        }}
                      ></TextField>
                    </div>
                    <div className="col-12 col-md-3">
                      <TextField
                        disabled={clientData.payment_status_flag === true}
                        hasLabel={true}
                        value={clientData?.en_grandpa_name}
                        label={"Grand Name "}
                        placeholder={"Enter Grand Name"}
                        name="en_grandpa_name"
                        min={3}
                        errors={errorsClients[index]?.en_grandpa_name}
                        color={
                          errorsClients[index]?.en_grandpa_name?.notNumber
                            ? "danger"
                            : ""
                        }
                        onChange={(e) => {
                          setclientData({
                            ...clientData,
                            en_grandpa_name: e.target.value,
                          });
                          setErrorsClientsfiled(
                            "en_grandpa_name",
                            e.target.value,
                            {
                              notNumber: true,
                            }
                          );
                        }}
                      ></TextField>
                    </div>
                    <div className="col-12 col-md-3">
                      <TextField
                        disabled={clientData.payment_status_flag === true}
                        hasLabel={true}
                        value={clientData?.en_last_name}
                        label={"Last Name "}
                        placeholder={"Enter Last Name"}
                        name="en_last_name"
                        min={3}
                        errors={errorsClients[index]?.en_last_name}
                        color={
                          errorsClients[index]?.en_last_name?.required ||
                          errorsClients[index]?.en_last_name?.notNumber
                            ? "danger"
                            : ""
                        }
                        onChange={(e) => {
                          setclientData({
                            ...clientData,
                            en_last_name: e.target.value,
                          });
                          setErrorsClientsfiled(
                            "en_last_name",
                            e.target.value,
                            {
                              required: true,
                              notNumber: true,
                            }
                          );
                        }}
                      ></TextField>
                    </div>
                    <div className="col-12 col-md-3 mt-2">
                      <TextField
                        disabled={clientData.payment_status_flag === true}
                        hasLabel={true}
                        value={clientData?.ar_first_name}
                        label={"الاسم الاول "}
                        placeholder={"ادخل الاسم الاول"}
                        name="ar_first_name"
                        min={3}
                        errors={errorsClients[index]?.ar_first_name}
                        color={
                          errorsClients[index]?.ar_first_name?.required ||
                          errorsClients[index]?.ar_first_name?.notNumber
                            ? "danger"
                            : ""
                        }
                        onChange={(e) => {
                          setclientData({
                            ...clientData,
                            ar_first_name: e.target.value,
                          });
                          setErrorsClientsfiled(
                            "ar_first_name",
                            e.target.value,
                            {
                              required: true,
                              notNumber: true,
                            }
                          );
                        }}
                      ></TextField>
                    </div>
                    <div className="col-12 col-md-3 mt-2">
                      <TextField
                        hasLabel={true}
                        disabled={clientData.payment_status_flag === true}
                        value={clientData?.ar_father_name}
                        label={"اسم الآب "}
                        placeholder={"ادخل الاسم الآب"}
                        name="ar_father_name"
                        min={3}
                        errors={errorsClients[index]?.ar_father_name}
                        color={
                          errorsClients[index]?.ar_father_name?.notNumber
                            ? "danger"
                            : ""
                        }
                        onChange={(e) => {
                          setclientData({
                            ...clientData,
                            ar_father_name: e.target.value,
                          });
                          setErrorsClientsfiled(
                            "ar_father_name",
                            e.target.value,
                            {
                              notNumber: true,
                            }
                          );
                        }}
                      ></TextField>
                    </div>
                    <div className="col-12 col-md-3 mt-2">
                      <TextField
                        hasLabel={true}
                        disabled={clientData.payment_status_flag === true}
                        value={clientData?.ar_grandpa_name}
                        label={"اسم الجد   "}
                        placeholder={"ادخل الاسم الجد"}
                        name="ar_grandpa_name"
                        min={3}
                        errors={errorsClients[index]?.ar_grandpa_name}
                        color={
                          errorsClients[index]?.ar_grandpa_name?.notNumber
                            ? "danger"
                            : ""
                        }
                        onChange={(e) => {
                          setclientData({
                            ...clientData,
                            ar_grandpa_name: e.target.value,
                          });
                          setErrorsClientsfiled(
                            "ar_grandpa_name",
                            e.target.value,
                            {
                              notNumber: true,
                            }
                          );
                        }}
                      ></TextField>
                    </div>
                    <div className="col-12 col-md-3 mt-2">
                      <TextField
                        hasLabel={true}
                        disabled={clientData.payment_status_flag === true}
                        value={clientData?.ar_last_name}
                        label={"اللقب "}
                        placeholder={"ادخل الاسم اللقب"}
                        name="ar_last_name"
                        min={3}
                        errors={errorsClients[index]?.ar_last_name}
                        color={
                          errorsClients[index]?.ar_last_name?.required ||
                          errorsClients[index]?.ar_last_name?.notNumber
                            ? "danger"
                            : ""
                        }
                        onChange={(e) => {
                          setclientData({
                            ...clientData,
                            ar_last_name: e.target.value,
                          });
                          setErrorsClientsfiled(
                            "ar_last_name",
                            e.target.value,
                            {
                              required: true,
                              notNumber: true,
                            }
                          );
                        }}
                      ></TextField>
                    </div>
                    <div className="col-12 col-md-3 mt-2">
                      <TextField
                        hasLabel={true}
                        disabled={clientData.payment_status_flag === true}
                        value={clientData?.passport_number}
                        label={translate.passportNumber}
                        name="passport_number"
                        min={3}
                        placeholder={translate.passportNumberPlaceHolder}
                        errors={errorsClients[index]?.passport_number}
                        color={
                          errorsClients[index]?.passport_number?.required ||
                          errorsClients[index]?.passport_number?.number
                            ? "danger"
                            : ""
                        }
                        onChange={(e) => {
                          setclientData({
                            ...clientData,
                            passport_number: e.target.value,
                          });
                          setErrorsClientsfiled(
                            "passport_number",
                            e.target.value,
                            {
                              required: true,
                            }
                          );
                        }}
                      ></TextField>
                    </div>
                    <div className="col-12 col-md-3 mb-3 mb-md-0  mt-2">
                      <DatePicker
                        hasLabel={true}
                        disabled={clientData.payment_status_flag === true}
                        label={translate.ExpiryDate}
                        date={clientData?.expiration_date}
                        name="expiration_date"
                        placeholder={translate.ExpiryDatePlaceholder}
                        displayFormat={"YYYY-MM-DD"}
                        minDate={limit}
                        /*                   errors={errorsClients[index]?.expiration_date} */
                        /*          color={
                    errorsClients[index]?.expiration_date?.required
                      ? "danger"
                      : ""
                  } */
                        onChangeDate={(e) => {
                          setclientData({
                            ...clientData,
                            expiration_date: e,
                          });
                          /*                     setErrorsClientsfiled("expiration_date", e, {
                      required: true,
                    }); */
                        }}
                      ></DatePicker>
                    </div>
                    <div className="col-12 col-md-3 mt-2">
                      <TextField
                        hasLabel={true}
                        disabled={clientData.payment_status_flag === true}
                        value={clientData?.national_id}
                        label={translate.nationalId}
                        name="national_id"
                        min={11}
                        placeholder={translate.nationalIdPlaceholder}
                        errors={errorsClients[index]?.national_id}
                        color={
                          errorsClients[index]?.national_id?.number
                            ? "danger"
                            : ""
                        }
                        onChange={(e) => {
                          setclientData({
                            ...clientData,
                            national_id: e.target.value,
                          });
                          setErrorsClientsfiled("national_id", e.target.value, {
                            number: true,
                          });
                        }}
                      ></TextField>
                    </div>
                    <div className="col-12 col-md-3 mb-3 mb-md-0   mt-2">
                      <SelectField
                        onFocus={true}
                        hasLabel={true}
                        disabled={clientData.payment_status_flag === true}
                        label={translate.Nationality}
                        placeholder={translate.Nationality}
                        value={clientData?.nationality}
                        options={countries}
                        name="nationality"
                        onChange={(e) => {
                          handleChange({ name: "nationality", value: e });
                        }}
                        /*    errors={errorsClients[index]?.nationality} */
                        /*        color={
                    errorsClients[index]?.nationality?.required ? "danger" : ""
                  } */
                      />
                    </div>
                    {/*    <div className="col-3 mt-2">
                <TextField
                  hasLabel={true}
                  value={clientData?.phone}
                  label={translate.phoneNumber}
                  name="phone"
                  min={11}
                  placeholder={userDetails.phoneNumberPlaceholder}
                  errors={errorsClients[index]?.phone}
                  color={
                    errorsClients[index]?.phone?.required
                      ? "danger"
                      : "" || errorsClients[index]?.phone?.min
                      ? "danger"
                      : ""
                  }
                  onChange={(e) => {
                    setclientData({
                      ...clientData,
                      phone: e.target.value,
                    });
                    setErrorsClientsfiled("phone", e.target.value, {
                      required: true,
                      min: 11,
                    });
                  }}
                ></TextField>
              </div> */}
                    <div className="col-12 col-md-3  mt-2">
                      <TextField
                        hasLabel={true}
                        disabled={true}
                        disabled={clientData.payment_status_flag === true}
                        label={translate.country}
                        placeholder={translate.country}
                        value={"Egypt"}
                      ></TextField>
                    </div>
                    {/*   <div className="col-3  mt-2">
                <SelectField
                  onFocus={true}
                  hasLabel={true}
                  value={clientData?.city_id}
                  label={translate.city}
                  placeholder={translate.city}
                  options={cities}
                  name="city_id"
                  errors={errorsClients[index]?.city_id}
                  color={
                    errorsClients[index]?.city_id?.required ? "danger" : ""
                  }
                  onChange={(e) => {
                    handleChange({ name: "city_id", value: e });
                    setErrorsClientsfiled("city_id", e, {
                      required: true,
                    });
                  }}
                />
              </div> */}
                    {/* 
              <div className="col-3  mt-2">
                <SelectField
                  onFocus={true}
                  hasLabel={true}
                  value={clientData?.area_id}
                  label={translate.area}
                  placeholder={translate.area}
                  options={area}
                  errors={errorsClients[index]?.area_id}
                  color={
                    errorsClients[index]?.area_id?.required ? "danger" : ""
                  }
                  onChange={(e) => {
                    handleChange({ name: "area_id", value: e });
                    setErrorsClientsfiled("area_id", e, {
                      required: true,
                    });
                  }}
                />
              </div>
 */}
                    {/*               <div className="col-9 mt-2">
                <TextField
                  hasLabel={true}
                  label={translate.address}
                  value={clientData?.address}
                  name="address"
                  errors={errorsClients[index]?.address}
                  color={
                    errorsClients[index]?.address?.required ? "danger" : ""
                  }
                  min={3}
                  placeholder={translate.address}
                  onChange={(e) => {
                    setclientData({
                      ...clientData,
                      address: e.target.value,
                    });
                    setErrorsClientsfiled("address", e.target.value, {
                      required: true,
                    });
                  }}
                ></TextField>
              </div>
 */}{" "}
                  </div>
                  {/* Actions */}

                  <div className="d-flex justify-content-end align-items-end mt-3">
                    {listClients.length != 1 && (
                      <div>
                        {/* remove in edit */}
                        {clientData.id &&
                        (trip_id || switchTo.tripId != " ") ? (
                          <ShowForPermission permission={"delete-reservation"}>
                            <button
                              className=" h4 text-danger text-bold m-0 font-mobile"
                              onClick={() => {
                                removeClient(clientData.id);
                              }}
                            >
                              <i class="fas fa-stream  text-danger  "></i>
                              <i className="fas fa-trash text-danger mx-1 "></i>
                              {translate.remove}
                            </button>
                          </ShowForPermission>
                        ) : (
                          <button
                            className=" h4 text-danger text-bold m-0 font-mobile"
                            onClick={() => {
                              listClients.splice(index, 1);
                              dispatch({
                                type: "deleteClient",
                                index: listClients,
                              });
                            }}
                          >
                            <i class="fas fa-stream  text-danger  "></i>
                            <i className="fas fa-trash text-danger mx-1 "></i>
                            {translate.remove}
                          </button>
                        )}
                      </div>
                    )}

                    {trip_id && clientData?.payment_status_flag != true && (
                      <button
                        className=" text-success h4 m-0 text-bold mx-3 font-mobile"
                        onClick={() => {
                          submitEdit();
                          setEditing(false);
                        }}
                      >
                        <i class="fas fa-save mx-1"></i>
                        {translate.SaveChanges}
                      </button>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Collapse>
          </>
        )}

        {/* {TravelerApi?.length > 0
        ? TravelerApi.map((traveler, index) => {
            return (
              <div
                className="list_header row justify-content-between align-items-center m-0 p-3 rounded-top my-3"
                key={index}
              >
                <div className="d-flex col-12 col-md-8 text-mobile__center">
                  <button className="rounded-top font-mobile   ">
                    <p className="m-0 h4  font-weight-bold text-color font-mobile  w-100 text-mobile__center">
                      {`${translate.client} (${index + 1})  
  
${
  currentLanguage === "ar"
    ? `${traveler?.en_first_name}  `
    : `${traveler?.ar_first_name}    `
}

`}
                    </p>
                  </button>
                </div>{" "}
              </div>
            );
          })
        : ""} */}
        {allTravelerApi?.length > 0
          ? allTravelerApi.map((traveler, index) => {
              return (
                <div
                  className="list_header row justify-content-between align-items-center m-0 p-3 rounded-top my-3"
                  key={index}
                >
                  <div className="d-flex col-12 col-md-8 text-mobile__center">
                    <button className="rounded-top font-mobile   ">
                      <p className="m-0 h4  font-weight-bold text-color font-mobile  w-100 text-mobile__center">
                        {`${translate.client} (${index + 1})  
  
${
  currentLanguage === "ar"
    ? `${traveler?.AFullName}  `
    : `${traveler?.FullName}    `
}

`}
                      </p>
                    </button>
                  </div>{" "}
                </div>
              );
            })
          : ""}

        <div></div>
      </div>
    </>
  ) : (
    "    RunRun"
  );
}
