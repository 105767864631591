import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import AdminAuthRoute from "./components/AdminAuthRoute";
import AdminRoute from "./components/AdminRoute";
import { AdminProvider } from "context/Admin/global";
import AdminLogin from "./modules/Admin/auth/login";
import AdminHome from "./modules/Admin/Home";
import Users from "./modules/Admin/WorkTeam/index";
import AddUser from "./modules/Admin/WorkTeam/add";
import EditUser from "./modules/Admin/WorkTeam/edit";
import Groups from "./modules/Admin/WorkTeam/groups";
import AddGroup from "./modules/Admin/WorkTeam/addGroup";
import EditGroup from "./modules/Admin/WorkTeam/editGroup";
import AdminCompanies from "./modules/Admin/Companies";
import AdminCompaniesRequests from "./modules/Admin/CompaniesRequests";
import EditCompany from "./modules/Admin/Companies/edit";
import CompanyUsers from "./modules/Admin/Companies/CompanyUsers/index";
import AddCompanyUser from "./modules/Admin/Companies/CompanyUsers/add";
import EditCompanyUser from "./modules/Admin/Companies/CompanyUsers/edit";
import CompanyOrders from "./modules/Admin/Companies/CompanyOrders/index";
import CompanyOrderView from "./modules/Admin/Companies/CompanyOrders/view";
import CompanyOrdersPending from "./modules/Admin/Companies/CompanyOrders/pending";
import Wallet from "./modules/Admin/wallet";
import RequestInfo from "modules/Admin/wallet/create-request/RequestInfo";
import DealsInfo from "modules/Admin/wallet/create-request/DealsInfo";
import CompanyWallet from "./modules/Admin/Companies/CompanyWallet";
import CompanyCreateRequest from "modules/Admin/Companies/CompanyWallet/create-request/CreateRequest";
import CompanyCreateSafaRequest from "modules/Admin/Companies/CompanyWallet/create-request/CreateSafaRequest"
import CompanyCreateSafaPay from "modules/Admin/Companies/CompanyWallet/create-request/CreateSafaPay";
import CompanyRequestInfo from "modules/Admin/Companies/CompanyWallet/create-request/RequestInfo";
import CompanyDealsInfo from "modules/Admin/Companies/CompanyWallet/create-request/DealsInfo";
import MedicalRequests from "./modules/Admin/MedicalServicesRequests/index";
import MedicalRequestView from "./modules/Admin/MedicalServicesRequests/view";
import MedicalRequestsInprogress from "./modules/Admin/MedicalServicesRequests/inprogress";
import AccountsView from "./modules/Admin/Accounts/index";
import ResetPassword from "modules/Admin/auth/reset-password";
import { AuthRoute } from "./components/AuthRoute";
import NotFound from "components/layout/NotFound";
import AllNotifications from "components/layout/Notifications";
import AllNotificationsAdmins from "components/layout/Admin/NotificationsAdmin";
const AdminRoutes = (
  <Suspense fallback={<div>Loading...</div>}>
    <Switch>
      <AdminAuthRoute exact path="/admin/login" component={AdminLogin} />
      <AdminRoute exact path="/admin/home" component={AdminHome} />

      <AdminRoute exact path="/admin/companies" component={AdminCompanies} />
      <AdminRoute
        exact
        path="/admin/companies-requests"
        component={AdminCompaniesRequests}
      />
      <AdminRoute
        exact
        path="/admin/companies/edit/:company_id"
        component={EditCompany}
      />

      <AdminRoute
        exact
        path="/admin/companies/users/:company_id"
        component={CompanyUsers}
      />
      <AdminRoute
        exact
        path="/admin/companies/users/add/:company_id"
        component={AddCompanyUser}
      />
      <AdminRoute
        exact
        path="/admin/companies/users/edit/:company_id/:user_id"
        component={EditCompanyUser}
      />

      <AdminRoute
        exact
        path="/admin/companies/orders/:company_id"
        component={CompanyOrders}
      />
      <AdminRoute
        exact
        path="/admin/companies/orders-inprogress/:company_id"
        component={CompanyOrdersPending}
      />
      <AdminRoute
        exact
        path="/admin/companies/orders/view/:id"
        component={CompanyOrderView}
      />

      <AuthRoute exact path="/reset-password" component={ResetPassword} />

      <AdminRoute
        exact
        path="/admin/companies/wallet/:company_id"
        component={CompanyWallet}
      />
      <AdminRoute
        exact
        path="/admin/companies/wallet/add/:company_id"
        component={CompanyCreateRequest}
      />
      <AdminRoute
        exact
        path="/admin/companies/wallet/safa-add/:company_id"
        component={CompanyCreateSafaRequest}
      />
      <AdminRoute
        exact
        path="/admin/companies/wallet/safa-pay/:company_id"
        component={CompanyCreateSafaPay}
      />
      <AdminRoute
        exact
        path="/admin/companies/wallet/request-view/:request_id"
        component={CompanyRequestInfo}
      />
      <AdminRoute
        exact
        path="/admin/companies/wallet/deals-view/:request_id"
        component={CompanyDealsInfo}
      />

      <AdminRoute exact path="/admin/team-management/users" component={Users} />
      <AdminRoute
        exact
        path="/admin/team-management/users/add"
        component={AddUser}
      />
      <AdminRoute
        exact
        path="/admin/team-management/users/edit/:user_id"
        component={EditUser}
      />
      <AdminRoute
        exact
        path="/admin/team-management/groups"
        component={Groups}
      />
      <AdminRoute
        exact
        path="/admin/team-management/groups/add"
        component={AddGroup}
      />
      <AdminRoute
        exact
        path="/admin/team-management/groups/edit/:group_id"
        component={EditGroup}
      />

      <AdminRoute exact path="/admin/wallet" component={Wallet} />
      <AdminRoute
        exact
        path="/admin/wallet/request-view/:request_id"
        component={RequestInfo}
      />
      <AdminRoute
        exact
        path="/admin/wallet/deals-view/:request_id"
        component={DealsInfo}
      />

      <AdminRoute
        exact
        path="/admin/medical-services-requests"
        component={MedicalRequests}
      />
      <AdminRoute
        exact
        path="/admin/orders-inprogress"
        component={MedicalRequestsInprogress}
      />
      <AdminRoute
        exact
        path="/admin/medical-services-requests/view/:id"
        component={MedicalRequestView}
      />
      <AdminRoute
        exact
        path="/admin/All-Notifications"
        component={AllNotificationsAdmins}
      />

      <AdminRoute exact path="/admin/accounts" component={AccountsView} />
    </Switch>
  </Suspense>
);
export default AdminRoutes;
