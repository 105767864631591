import { AuthProvider } from "context/auth";
import React from "react";
import { Route } from "react-router-dom";

// React Component
export function AuthRoute({ component: Component, ...props }) {
  // const isAuth = localStorage.getItem("isAuth");
  // const userType = localStorage.getItem("userType");
  // 

  // const redirectTo = () => {
  // 	switch (userType) {
  // 		case "company-super-admin":
  // 			return "/companies";
  // 		case "lab-super-admin":
  // 			return "/lab";
  // 		case "branch-super-admin":
  // 			return "/branches";
  // 		case "null":
  // 			return "/admin";
  // 		default:
  // 			return "/";
  // 	}
  // };

  // if (!isAuth) {
  return (
    <Route
      {...props}
      render={(matchProps) => (
        <AuthProvider>
          <Component {...matchProps} />
        </AuthProvider>
      )}
    />
  );
  // } else {
  // 	return <Redirect to={redirectTo()} />;
  // }
}
