import React from "react";
import { NavLink } from "react-router-dom";

import { Table } from "reactstrap";

import Locale from "translations";
import ShowForPermissionAdmin from "helpers/ShowForPermissionAdmin";

export default function WalletRequests({ requests, changeStatusRequest }) {
  const { wallet, translate, translate_companies } = Locale;
  

  const fetchStatusRequest = (status) => {
		let statusReturn = '';
		switch (status) {
			case 'pending':
				statusReturn = wallet.pending;
				break;
			case 'rejected':
				statusReturn = wallet.rejected;
				break;
			default:
				statusReturn = wallet.pending;
				break;
		}

		return statusReturn
	};

  return (
    <React.Fragment>
      <Table>
        <thead>
          <tr className="CompaniesList-head">
            <th scope="col">{wallet.company_name}</th>
            <th scope="col">{wallet.receiptNumber}</th>
            <th scope="col">{wallet.requestDate}</th>
            <th scope="col">{wallet.amount}</th>
            <th scope="col">{wallet.status}</th>
            <th scope="col">{translate.tools}</th>
          </tr>
        </thead>
        <tbody className="table-stripe ">
          {requests && requests.length > 0 ? (
            requests.map((request) => (
              <tr key={request.id}>
                <td>{request.company_name}</td>
                <td>{request.receipt_number}</td>
                <td>{request.request_date}</td>
                <td>{request.amount}</td>
                <td
                  className={
                    request.status === "rejected"
                      ? "text-danger"
                      : "text-warning"
                  }
                >
                  {fetchStatusRequest(request.status)}
                </td>
                <td className="d-flex">
                  <ShowForPermissionAdmin
                    permission={"view-transaction-request"}
                  >
                    <NavLink
                      exact={true}
                      to={`/admin/wallet/request-view/` + request.id}
                      activeClassName="is-active"
                      className="p-2 text-primary mr-3 btn-link "
                    >
                      <i className="fas fa-eye"></i>
                      {translate.view}
                    </NavLink>
                  </ShowForPermissionAdmin>

                  {request.status !== "rejected" ? (
                    <React.Fragment>
                      <ShowForPermissionAdmin
                        permission={"action-transaction-request"}
                      >
                        <button
                          type="button"
                          onClick={() =>
                            changeStatusRequest(request.id, "accept")
                          }
                          className="btn btn-link text-success walletAccepted"
                        >
                          <i
                            class="fa fa-check walletCheck"
                            aria-hidden="true"
                          ></i>
                          {translate_companies.confirm}
                        </button>

                        <button
                          type="button"
                          onClick={() =>
                            changeStatusRequest(request.id, "reject")
                          }
                          className="btn btn-link text-danger walletRejected"
                        >
                          {translate_companies.reject}
                        </button>
                      </ShowForPermissionAdmin>
                    </React.Fragment>
                  ) : null}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="11">
                <div className=" text-center">
                  <i className="fas fa-info-circle fa-lg text-muted m-3 "></i>{" "}
                  <h4 className=" text-muted">{translate.noResult}</h4>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </React.Fragment>
  );
}
