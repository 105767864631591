import React, { useState } from "react";
import SelectField from "../../../components/shared/Admin/SelectField";
import DatePicker from "../../../components/shared/Admin/DatePicker";
import TextField from "../../../components/shared/Admin/textField";
import Locale from "../../../translations";
import moment from "moment";

export default function Filter(props) {
  const { translate, translate_companies, wallet } = Locale;

  const currentDate = moment(new Date());
  const limit = moment(currentDate).subtract(1, "days");

  const [searchState, setSearchState] = useState('');
  const [dateState, setDateState] = useState(null);
  const [dateQState, setDateQState] = useState('');
  const [statusState, setStatusState] = useState(0);

  const searchChangedHandler = (event) => {
    setSearchState(event.target.value);
  }

  const dateChangedHandler = (event) => {
    setDateQState(moment(event._d).format("YYYY-MM-DD"));
    setDateState(event);
  }

  const statusChangedHandler = (event) => {
    //setStatusState(event.target.value);
    
    if (event.value !== '0') {
      setStatusState(event);
    }
  }

  const searchSubmitHandler = () => {
    props.FilterSubmitted(searchState, dateQState, statusState.value);
  }

  const resetSearchHandler = () => {
    setDateState('');
    setStatusState(0);
    setSearchState('');
    props.ResetSubmitted();
  }

  const optionsType = [
    { value: '0', label: translate_companies.type_transaction, isDisabled: true },
    { value: 'add_credit', label: wallet.add_credit },
    { value: 'deduct_credit', label: wallet.deduct_credit },
    { value: 'add_wallet', label: wallet.add_wallet },
    { value: 'deduct_wallet', label: wallet.deduct_wallet },
  ];

  const options = [
    { value : '0', label: translate.status, isDisabled: true },
    { value: 'pending', label: wallet.pending },
    { value: 'rejected', label: wallet.rejected }
  ];

  return (
    <div className="CompanyFilterContainer">
      <div className="row no-gutters " style={{ width: "100% " }}>
        <div className="col mr-2 ">
          <TextField
            hasLabel={false}
            search={true}
            value={searchState}
            onChange={(event) => searchChangedHandler(event)}
            label={translate.search}
            type={"text"}
            placeholder={translate.search}
          >
            <i className="fas fa-search"></i>
          </TextField>
        </div>

        <div className="col mr-2  ">
          <DatePicker
            hasLabel={false}
            date={dateState}
            label={translate.examinationDate}
            placeholder={wallet.wallet_date}
            displayFormat={"YYYY-MM-DD"}
            isOutsideRange={() => false} 
            onChangeDate={(e) => dateChangedHandler(e)}
          />
        </div>


        <div className="col mr-2  ">
          <SelectField
            hasLabel={false}
            placeholder={translate.status}
            options={options}
            value={statusState}
            onChange={(event) => statusChangedHandler(event)}
          />
        </div>

        <div className="col  pt-1" >
          <button onClick={searchSubmitHandler} className="btn btn-primary px-5 py-2 font-weight-bold CompaniesList-button">
            {translate.search}
          </button>
          <button onClick={resetSearchHandler} className="btn text-muted font-weight-bold ">
            {" "}
            <i className="fas fa-retweet"></i>{translate.reset}
          </button>
        </div>
      </div>
    </div>
  );
}
