import { useClientsState } from 'context/addClient';
import React from 'react'
import Locale from "translations";
export default function OrderData() {
  const { translate } = Locale;
   const { service } = useClientsState();


  

    return (
      <div className="border bg-white rounded">
        <h5 className="font-weight-bold p-3 text_blue-dark m-0">
          {`${translate.orderData} `}
        </h5>
        <div className="  px-3 row">
          <p className="col-6  col-md-3 h5 font-mobile   font-weight-bold">
            {translate.ExaminationType}
          </p>
          <p className="col-6  col-md-3 h5 font-mobile ">{service.check}</p>
          <p className="col-6  col-md-3 h5 font-mobile  font-weight-bold">
            {translate.ServiceGrade}
          </p>
          <p className="col-6  col-md-3 h5 font-mobile ">{service.grade}</p>
          <p className="col-6  col-md-3 h5 font-mobile  font-weight-bold">
            {translate.serviceType}
          </p>
          <p className="col-6  col-md-3 h5 font-mobile ">{service.type}</p>
          <p className="col-6  col-md-3 h5 font-mobile   font-weight-bold">
            {translate.ResultTime}
          </p>
          <p className="col-6  col-md-3 h5 font-mobile ">{service.time}</p>
        </div>
      </div>
    );
}
