import SelectField from "components/shared/SelectField";
import { useCompanyDispatch } from "context/global";
import { useHistory } from "react-router-dom";

import React, { useState } from "react";
import { store } from "react-notifications-component";
import { createPayment } from "services/yarvel";
import { getCurrentBalance } from "services/wallet";
import Locale from "translations";
import useWallet from "modules/Yarvel/wallet/useWallet";
import ShowForPermission from "helpers/showForPermission";
import { useClientsState } from "context/addClient";

export default function ClientPayment({
  data,

  setReservation,
  index,
  reservation,
  getTotalComission,
}) {
  const dispatch = useCompanyDispatch();
  const { getBalance } = useWallet();
  const { translate, translate_companies } = Locale;
  const paymentMethod = [
    { value: "credit", label: translate_companies.safa_balance },
    { value: "wallet", label: translate.Balance },
  ];
  const [payed, setPayed] = useState(false);
  const { tenant_id } = useClientsState();

  /* payment creat */
  const paymentReservation = async () => {
    if (data.by != undefined) {
      const res = await createPayment({
        reservation_id: data.id,
        type: data.by.value,
        tenant_id: tenant_id,
      });
      if (res?.status === 201) {
        store.addNotification({
          title: "info!",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });

        setPayed(!payed);
        getBalance();
      }
    }
  };

  return (
    <div className="bg-white rounded-lg row border py-3 mt-4">
      <div className=" col-4 col-md-3 order-0 order-md-0">
        <p className="text-muted">{translate.traveler}</p>
        <p className="text-bolder"> {data.traveler_name}</p>
      </div>

      <div className="col-4 col-md-1 order-1 order-md-1">
        <p className="text-muted">{translate.Cost}</p>
        <p className="text-bolder"> {data?.total_cost}</p>
      </div>

      <div className="col-12 col-md-3 order-3 order-md-2">
        <SelectField
          onFocus={true}
          hasLabel={true}
          disabled={data.payment_status_flag != true ? false : true}
          options={paymentMethod}
          label={translate.paymentMethods}
          placeholder={
            data?.payment?.type === "wallet"
              ? translate.Balance
              : data?.payment?.type === "credit"
              ? translate.safaWallet
              : translate.paymentMethodsPlaceholder
          }
          value={data.by}
          onChange={(e) => {
            let x = reservation;
            x[index] = { ...reservation[index], by: e };
            setReservation([...x]);
          }}
        />
      </div>
      <div className="col-4 col-md-3 order-2 order-md-3">
        <p className="text-muted">{translate.commission}</p>
        {data.payment === null && (
          <p className="text-bolder mx-4">
            {data?.by?.value === "credit"
              ? data?.credit_comission
              : data?.by?.value === "wallet"
              ? data?.debit_comission
              : ""}
          </p>
        )}
        {data.payment != null && (
          <p className="text-bolder mx-4">{data.payment.commission}</p>
        )}
      </div>

      <div className="col-12 col-md-2 order-4 order-md-4 ">
        {data.payment_status_flag != true && !payed ? (
          <ShowForPermission permission={"create-reservation"}>
            <button
              type="button"
              class="btn btn-success mt-4 w-100"
              onClick={() => {
                paymentReservation();
              }}
            >
              {translate.ConfirmSend}
            </button>
          </ShowForPermission>
        ) : (
          ""
        )}
        {data.payment_status_flag === true || payed ? (
          <p className="text-success text-bold mt-4">
            <i class="fas fa-check mx-1 "></i>
            {translate.BeenSent}
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
