import React from "react";
import DatePicker from "components/shared/DatePicker";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/textField";
import Locale from "translations";

export default function Filter({ filter, setfilter, submit }) {
  const { translate } = Locale;
  return (
    <div className="row pt-2 bg-white no-gutters list_header bg-white px-2">
      <div className="col-12 col-md-3 m-0  mr-md-2 my-md-2">
        <TextField
          hasLabel={false}
          label={translate.search}
          className={"mb-0"}
          value={filter.traveler_name}
          type={"text"}
          placeholder={translate.search}
          onChange={(e) => {
            setfilter({
              ...filter,
              traveler_name: e.target.value,
            });
          }}
        >
          <i className="fas fa-search"></i>
        </TextField>
      </div>
      <div className="col-12 col-md-3 m-0  mr-md-2 my-2">
        <DatePicker
          hasLabel={false}
          placeholder={translate.ExaminationDate}
          date={filter.created_date}
          displayFormat={"YYYY-MM-DD"}
          onChangeDate={(e) => {
            setfilter({
              ...filter,
              created_date: e,
            });
          }}
        />
      </div>

      <div className="col-12 col-md-3 m-0  mr-md-2 my-2">
        <DatePicker
          hasLabel={false}
          placeholder={translate.examinationDate}
          date={filter.check_date}
          displayFormat={"YYYY-MM-DD"}
          onChangeDate={(e) => {
            setfilter({
              ...filter,
              check_date: e,
            });
          }}
        />
      </div>

      {/*      <div className="col mr-2">
        <SelectField
          hasLabel={false}
          onFocus={true}
          label={translate.analysisResults}
          placeholder={translate.ChoseanalysisResults}
        />
      </div> */}

      <div className="my-2 col-12 col-md-2 text-center">
        <button
          className="btn btn-primary  text_filed px-5 py-2 font-weight-bold"
          onClick={submit}
        >
          {translate.search}
        </button>
        <button
          className=" text-muted font-weight-bold "
          onClick={() => {
            setfilter({
              traveler_name: "",
              travel_date: "",
              check_date: "",
            });
            submit();
          }}
        >
          {" "}
          {translate.reset} <i className="fas fa-retweet"></i>
        </button>
      </div>
    </div>
  );
}
