import React from "react";
import { NavLink } from "react-router-dom";

import { Table } from "reactstrap";

import Locale from "translations";
import ShowForPermissionAdmin from "helpers/ShowForPermissionAdmin";

export default function WalletDeals({ deals }) {
    const { wallet, translate, translate_companies } = Locale;
    
    const getTypeOfDeals = (type) => {
        let type_transaction = '';
        switch (type) {
         
            case "add_credit":
                type_transaction = wallet.add_credit;
                break;
            case "add_wallet":
                type_transaction = wallet.add_wallet;
                break;
            case "deduct_credit":
                type_transaction = wallet.deduct_credit;
                break;
            case "deduct_wallet":
                type_transaction = wallet.deduct_wallet;
                break;
            default:
                type_transaction = '';
                break;
        }

        return type_transaction;
    }
    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr className="CompaniesList-head">
                        <th scope="col">{wallet.receiptNumber}</th>
                        <th scope="col">{wallet.requestDate}</th>
                        <th scope="col">{wallet.amount}</th>
                        <th scope="col">{translate_companies.transaction_type}</th>
                        <th scope="col">{translate_companies.paymentMethod}</th>
                        <th scope="col">{wallet.status}</th>
                        <th scope="col">{translate.tools}</th>
                    </tr>
                </thead>
                <tbody className="table-stripe ">
                    {deals && deals.length > 0 ? (
                        deals.map((deal) => (
                            <tr key={deal.id}>
                                <td>{deal.transaction_number}</td>
                                <td>{deal.transaction_date}</td>
                                <td>{deal.amount}</td>
                                <td>{getTypeOfDeals(deal.type)}</td>
                                <td>{deal.transaction_type }</td>
                                <td className={"text-green"}>{translate_companies.approved}</td>
                                <td className="d-flex">
                                <ShowForPermissionAdmin permission={"list-transaction"}>
                                    <NavLink
                                        exact={true}
                                        to={`/admin/wallet/deals-view/` + deal.id}
                                        activeClassName="is-active"
                                        className="p-2 text-primary mr-3 btn-link "
                                    >
                                        <i className="fas fa-eye"></i>
                                        {translate.view}
                                    </NavLink>
                                    </ShowForPermissionAdmin>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="11">
                                <div className=" text-center">
                                    <i className="fas fa-info-circle fa-lg text-muted m-3 "></i>{" "}
                                    <h4 className=" text-muted">{translate.noResult}</h4>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </React.Fragment>
    );
}
