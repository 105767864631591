import React,{useState} from "react";
import Locale from "../../../translations";
import { useHistory } from "react-router-dom";
import UserNumber from "./userNumber"
import { NavLink } from "react-router-dom";
import Users from "./users"
import ShowForPermission from "helpers/showForPermission";
import ShowForPermissionAdmin from "helpers/ShowForPermissionAdmin";

function WorkTeam() {
  const { translate } = Locale;
  let history = useHistory();
  const [users, setUsers] = useState([]);

  const countOfUsers = (val) => {
    setUsers(val);
  };

  return (
    <section>
      <UserNumber users={users} />
      <div className="container">
        <div className="d-flex justify-content-between mt-5 mb-2">
          <h3 className="text-muted font-weight-bold">
            {translate.teamManagement}
          </h3>

          <ShowForPermissionAdmin permission={"create-admin"}>
            <NavLink
              exact={true}
              to={`/admin/team-management/users/add`}
              activeClassName="is-active"
            >
              <button
                type="button"
                className="btn btn-success px-4 py-2 font-weight-bold "
                onClick={() => {
                  history.push("/admin/team-management/users");
                }}
              >
                <i className="fas fa-plus-circle mr-2"></i>
                {translate.addUser}
              </button>
            </NavLink>
          </ShowForPermissionAdmin>



        </div>
        <Users countUsers={(val) => countOfUsers(val)} />
      </div>
    </section>
  );
}

export default WorkTeam;
