import React from "react";
import { NavLink } from "react-router-dom";
import Locale from "../../../translations";

export default function Order(props) {
  const { order } = props;
  const { translate_companies, Accounts } = Locale;

  return (
    <>
      <tr>
        <td>{order.company_name}</td>
        <td>{order.reservation_number}</td>
        <td>{order.check_date}</td>
        <td>{order.traveller_name}</td>
        <td>{order.check_type}</td>
        <td>{"Check-me"}</td>
        <td>
          {order.total_cost}
          {translate_companies.l_e}
        </td>
        <td>
          {order.check_me_cost}
          {translate_companies.l_e}
        </td>
        <td>
          {order.company_commission}
          {translate_companies.l_e}
        </td>
        <td>
          {order.safa_commission}
          {translate_companies.l_e}
        </td>
        <td>
          {order.wallet_paid}
          {translate_companies.l_e}
        </td>
        <td>{order.payment_date}</td>
        <td>
          {order.payment_method === "wallet"
            ? Accounts.type_wallet
            : Accounts.type_credit}
        </td>
        <td>{order.wallet_balance}</td>
      </tr>
    </>
  );
}
