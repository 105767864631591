import Pagination from "components/shared/Pagination";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getNotification } from "services/yarvel";
import Locale from "translations";
import Notify from "./Notify";
import useNotfication from "./useNotfication";

export default function AllNotificationsAdmins() {
  const { translate } = Locale;
  const [notifications, setNotifications] = useState();
  const [meta, setmeta] = useState();
  const [page, setPage] = useState();

  useEffect(async () => {
    const res = await getNotification({ page: page });
    if (res.status === 200) {
      setNotifications(res.data.data);
      setmeta(res.data.meta);
    }
  }, [page]);

  const goTo = async (page) => {
    setPage(page);
  };

  const AllNotifies =
    notifications && notifications.length > 0 ? (
      notifications.map((data, index) => (
        <Notify data={data} key={index} index={index} />
      ))
    ) : (
      <h3 className="mt-3"> {translate.notification}</h3>
    );

  return (
    <div className="container">
      <div className="d-flex flex-column justify-content-center align-items-center mt-3">
        <header className=" list_header border py-3 px-2 col-5">
          <h3 className="text-muted font-weight-bold">
            {translate.allNotification}
          </h3>
        </header>

        {AllNotifies}
        <Pagination info={meta} goTo={goTo} items={10} />
      </div>
    </div>
  );
}
