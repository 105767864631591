import Activation from "modules/Yarvel/auth/pages/Activation";
import ForgotPassword from "modules/Yarvel/auth/pages/ForgotPassword";
import ForgotPasswordSubmitted from "modules/Yarvel/auth/pages/ForgotPasswordSubmitted";
import Register from "modules/Yarvel/auth/pages/Register";
import RegistrationSucceed from "modules/Yarvel/auth/pages/NotConfirmed";

import CreateRequest from "modules/Yarvel/wallet/create-request/CreateRequest";
import RequestInfo from "modules/Yarvel/wallet/create-request/RequestInfo";
import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { AddClientProvider } from "../src/context/addClient";
import { AuthRoute } from "./components/AuthRoute";
import CompanyRouter from "./components/CompanyRouter";
import AdminAuthRoute from "./components/AdminAuthRoute";
import AdminRoute from "./components/AdminRoute";
import Login from "./modules/Yarvel/auth/login";
import AddReservation from "./modules/Yarvel/travelers/AddReservation";
import CustomersList from "./modules/Yarvel/travelers/CustomersList";
import View from "./modules/Yarvel/travelers/CustomersList/view";
import Wallet from "./modules/Yarvel/wallet";
import Users from "./modules/Yarvel/WorkTeam/index";
import AddUser from "./modules/Yarvel/WorkTeam/add";
import EditUser from "./modules/Yarvel/WorkTeam/edit";
import Groups from "./modules/Yarvel/WorkTeam/groups";
import AddGroup from "./modules/Yarvel/WorkTeam/addGroup";
import EditGroup from "./modules/Yarvel/WorkTeam/editGroup";
import MedicalService from "modules/Yarvel/travelers/AddReservation/MedicalService";
import DashboardCompaniesAdmin from "modules/Yarvel/dashboard/DashboardCompanies";
import Transfer from "modules/Yarvel/wallet/transfers";
import TransferInfo from "modules/Yarvel/wallet/transfers/TransferInfo";
import NotConfirmed from "modules/Yarvel/auth/pages/NotConfirmed";
import NotFound from "components/layout/NotFound";
import AddClientRoute from "components/AddClientRoute";
import ForgetPassword from "modules/Yarvel/auth/forget-password";
import ResetPassword from "modules/Yarvel/auth/reset-password";
import AllNotifications from "components/layout/Notifications";

const Routes = (
  <Suspense fallback={<div>Loading...</div>}>
    <Switch>
      <CompanyRouter exact path="/" component={DashboardCompaniesAdmin} />
      {/* <AuthRoute exact path="/auth/login" component={Login} />
      <AuthRoute path="/" exact component={Login} /> */}
      {/* Admin */}
      {/* <AdminProvider>
        <AdminAuthRoute exact path="/admin/login" component={AdminLogin} />
        <AdminRoute exact path="/admin/home" component={"hello"} />
      </AdminProvider> */}
      {/* END Admin */}
      <AuthRoute exact path="/login" component={Login} />
      <AuthRoute exact path="/register" exact component={Register} />
      <AuthRoute
        path="/register/success"
        exact
        component={RegistrationSucceed}
      />
      {/*      <AuthRoute path="/forget-password" exact component={ForgotPassword} /> */}
      <AuthRoute
        path="/forgot_password_submitted"
        exact
        component={ForgotPasswordSubmitted}
      />

      <AuthRoute exact path="/confirmed" component={Activation} />
      <AuthRoute exact path="/not-confirmed" component={NotConfirmed} />
      <AuthRoute
        exact
        path="/activation/resend"
        component={RegistrationSucceed}
      />

      <AuthRoute exact path="/reset-password" component={ResetPassword} />
      <AuthRoute exact path="/forget-password" component={ForgetPassword} />
      <CompanyRouter exact path="/view-result/:id" component={View} />
      <CompanyRouter exact path="/customers/:id" component={CustomersList} />

      <CompanyRouter exact path="/customers" component={CustomersList} />
      <CompanyRouter exact path="/wallet" component={Wallet} />
      <CompanyRouter exact path="/wallet/add" component={CreateRequest} />
      <CompanyRouter exact path="/wallet/transfer" component={Transfer} />
      <CompanyRouter
        exact
        path="/wallet/transfered-successfully"
        component={TransferInfo}
      />
      <CompanyRouter exact path="/wallet/add/send" component={RequestInfo} />
      <CompanyRouter exact path="/view-result" component={View} />
      {/* workTeam */}
      <CompanyRouter exact path="/team-management/users" component={Users} />
      <CompanyRouter
        exact
        path="/team-management/users/add"
        component={AddUser}
      />
      <CompanyRouter
        exact
        path="/team-management/users/edit/:user_id"
        component={EditUser}
      />

      <CompanyRouter exact path="/All-Notifications" component={AllNotifications} />

      <CompanyRouter exact path="/team-management/groups" component={Groups} />
      <CompanyRouter
        exact
        path="/team-management/groups/add"
        component={AddGroup}
      />
      <CompanyRouter
        exact
        path="/team-management/groups/edit/:group_id"
        component={EditGroup}
      />
      {/* add Resrvation */}

      <AddClientRoute
        exact
        path="/medical/services/:clientsNumber/:test_type"
        component={AddReservation}
      />
      <AddClientRoute
        exact
        path="/medical/services/:trip_id"
        component={AddReservation}
      />
      <AddClientRoute
        exact
        path="/medical/services"
        component={MedicalService}
      />

       <Route path="*" component={NotFound} /> 

      {/* NotFound in end for any page router */}
    </Switch>
  </Suspense>
);
export default Routes;
