import { useClientsDispatch, useClientsState } from "context/addClient";
import { isFormValid } from "helpers/validate";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { deletClient } from "services/admin";
import { updateClients } from "services/yarvel";
import Locale from "translations";
import LoginSafa from "./loginSafa";
import Travelers from "./travelers";

export default function ListTravelers({
  setErrorsClients,
  errorsClients,
  checkFormallErrors,
  setDoneCheck,
  doneCheck,
  setSwitchTo,
  switchTo,
  setDeleted,
  deleted,
}) {
  const { translate } = Locale;
  const dispatch = useClientsDispatch();
  const { listClients } = useClientsState();
  const { clientsNumber } = useParams();
  const { trip_id } = useParams();
  const [isOpenModel, setIsOpenModel] = useState(false);
  const toggleModel = () => setIsOpenModel(!isOpenModel);

//
const [travelersData, setTravelersData] = useState([])


  useEffect(() => {
    if (clientsNumber && switchTo.tripId === " ") {
      dispatch({
        type: "clientsNumber",
        count: clientsNumber ? clientsNumber : 1,
      });
    }
  }, []);
  /* delet in edit */

  const removeClient = useCallback(async (id) => {
    const remove = await deletClient(id);
    if (remove?.status === 201 || remove?.status === 200) {
      dispatch({
        type: "removeClient",
        id: id,
      });
    }
  }, []);



  const allListClients = useMemo(() => {
    return listClients.length > 0
      ? listClients.map((item, index) => {
          
          return (
            <Travelers
            isOpenModel={isOpenModel}
            toggleModel={toggleModel}
              data={item}
              setDoneCheck={setDoneCheck}
              doneCheck={doneCheck}
              setErrorsClients={setErrorsClients}
              errorsClients={errorsClients}
              key={index}
              checkFormallErrors={checkFormallErrors}
              index={index}
              setSwitchTo={setSwitchTo}
              switchTo={switchTo}
              removeClient={removeClient}
              setDeleted={setDeleted}
              deleted={deleted}

              travelersData={travelersData}
              setTravelersData={setTravelersData}

              
            />
          );
        })
      : [];
  }, [listClients, checkFormallErrors]);

  return (
    <div className="pb-5">
      {allListClients}

      {
       
        <button
          className="text-success font-weight-bold pt-3 d-flex align-items-baseline ml-5 p-0 font-mobile"
          onClick={() => {
            dispatch({
              type: "addClient",
            });
          }}
        >
          <i className="fas fa-plus-circle icons_label text-success m-3 font-mobile "></i>
          <p className="h3 text-bold font-mobile">{translate.AddTravele}</p>
        </button>
      }


      <LoginSafa isOpenModel={isOpenModel}  toggleModel={toggleModel}  travelersData={travelersData} setTravelersData={setTravelersData}/>
    </div>
  );
}
