import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { company_getWalletRequest } from "../../../../services/admin";
import Filter from "./Filter";

import Locale from "translations";
import Pagination from "components/shared/Pagination";
import ShowForPermissionAdmin from "helpers/ShowForPermissionAdmin";

export default function WalletRequests({ companyID, changeStatusRequest, statusChanged }) {
  const { wallet, translate, translate_companies } = Locale;

  const [requests, setRequests] = useState();
  const [requestsMeta, setRequestsMeta] = useState();

  const [filter, setFilter] = useState({
    status: "pending",
    company_id: companyID,
    receipt_number: "",
    receipt_date: "",
    type: "",
  });

  useEffect(() => {
    async function fetchData() {
      const companyRequests = await company_getWalletRequest(filter);
      setRequests(companyRequests?.data);
      setRequestsMeta(companyRequests?.meta);
      
    }
    fetchData();
  }, [filter, statusChanged]);

  // pagination
  const goTo = async (page) => {
    setFilter({ ...filter, page: page });
  };

  const filterSubmitted = (statusQ, searchQ, dateQ) => {
    
    
    if (statusQ !== "0") {
      setFilter({
        ...filter,
        type: statusQ,
        receipt_number: searchQ,
        receipt_date: dateQ,
        page: 1,
      });
    } else {
      setFilter({
        ...filter,
        receipt_number: searchQ,
        receipt_date: dateQ,
        page: 1,
      });
    }
  };

  const resetSubmitted = () => {
    setFilter({
      ...filter,
      status: "pending",
      type: "",
      receipt_number: "",
      receipt_date: "",
      page: 1,
    });
  };

  return (
    <React.Fragment>
      <header className="d-flex justify-content-between  Adminlist_Fullheader border py-3 px-2  ">
        <Filter
          ResetSubmitted={() => resetSubmitted()}
          FilterSubmitted={(status, searchQ, dateQ) =>
            filterSubmitted(status, searchQ, dateQ)
          }
        />
      </header>

      <table className="table">
        <thead className="">
          <tr className="table_head">
            <th scope="col">{wallet.receiptNumber}</th>
            <th scope="col">{wallet.requestDate}</th>
            <th scope="col">{wallet.amount}</th>
            <th scope="col">{wallet.status}</th>
            <th scope="col">{translate.processes}</th>
          </tr>
        </thead>
        <tbody>
          {requests && requests.length > 0 ? (
            requests.map((request) => (
              <tr key={request.id}>
                <td>{request.receipt_number}</td>
                <td>{request.request_date}</td>
                <td>{request.amount}</td>
                <td className={"text-warning"}>{translate.pending}</td>
                <td className="d-flex">
                  <ShowForPermissionAdmin
                    permission={"view-transaction-request"}
                  >
                    <NavLink
                      exact={true}
                      to={`/admin/companies/wallet/request-view/` + request.id}
                      activeClassName="is-active"
                      className="p-2 text-primary mr-3 btn-link "
                    >
                      <i className="fas fa-eye"></i>
                      {translate.view}
                    </NavLink>
                  </ShowForPermissionAdmin>

                  <ShowForPermissionAdmin
                    permission={"action-transaction-request"}
                  >
                    <button
                      type="button"
                      onClick={() => changeStatusRequest(request.id, "accept")}
                      className="btn btn-link text-success walletAccepted"
                    >
                      <i class="fa fa-check walletCheck" aria-hidden="true"></i>
                      {translate_companies.confirm}
                    </button>

                    <button
                      type="button"
                      onClick={() => changeStatusRequest(request.id, "reject")}
                      className="btn btn-link text-danger walletRejected"
                    >
                      {translate_companies.reject}
                    </button>
                  </ShowForPermissionAdmin>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="11">
                <div className=" text-center">
                  <i className="fas fa-info-circle fa-lg text-muted m-3 "></i>{" "}
                  <h4 className=" text-muted">{translate.noResult}</h4>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination info={requestsMeta} goTo={goTo} items={10} />
    </React.Fragment>
  );
}
