import { useClientsState } from "context/addClient";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getClientsReservation } from "services/yarvel";
import Locale from "translations";
import ListClients from "./listClients";
import ListPayment from "./listPayment";
import OrderData from "./orderData";

import TripInfo from "./tripInfo";
export default function Payment({ switchTo, setSwitchTo }) {
  const { translate } = Locale;
  const [clients, setClients] = useState([]);
  const [services, setServices] = useState({});
  const [trip, setTrip] = useState({});
  const { test_type } = useParams();
  const [reservation, setReservation] = useState({});



  const { service } = useClientsState();

  useEffect(() => {
    async function fetchListClients() {
      const list = await getClientsReservation(switchTo.tripId);
      if (
        list.status === 201 ||
        (list.status === 200 && list.data.data.travelers)
      ) {
        setClients(list?.data.data.travelers);
        setReservation(list?.data.data.reservations);
        setServices(list?.data.data.services);
        setTrip(list?.data.data.Trip);
      }
    }

    fetchListClients();
  }, []);

  return (
    <>
      <div className=" container medical_box border py-4">
        <h3 className="text-center py-3 text-bolder font-mobile">
          {translate.BeforeSending}
        </h3>
        <OrderData />
        {service.check_id === 1 && <TripInfo trip={trip} />}
        <ListClients clients={clients} reservation={reservation} />
      </div>

      <div className=" container medical_box border py-4 mt-5">
        <h3 className="text-center py-3 text-bolder font-mobile">
          {translate.PaymentDetermination}
        </h3>

        <ListPayment
          reservation={reservation}
          setReservation={setReservation}
        />
      </div>
    </>
  );
}
