import axios from "axios";
import { useCompanyDispatch, useCompanyState } from "context/global";
import { store } from "react-notifications-component";
import { Redirect, Route } from "react-router";

let axiosInterceptor = null;
let axiosRequestInterceptor = null;
const AxiosConfig = () => {
  let currentLocale = localStorage.getItem("currentLanguage") ?? "ar";
  const isAuth = localStorage.getItem("isAuth");
  let { token } = useCompanyState();
  const dispatch = useCompanyDispatch();

  axios.defaults.headers.common["Accept-Language"] = currentLocale
    ? currentLocale
    : "ar";
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  let counter = 0;
  if (!!axiosInterceptor || axiosInterceptor === 0) {
    axios.interceptors.response.eject(axiosInterceptor);
  }

  if (!!axiosRequestInterceptor || axiosRequestInterceptor === 0) {
    axios.interceptors.request.eject(axiosRequestInterceptor);
  }
  axiosRequestInterceptor = axios.interceptors.request.use(
    (request) => {
      counter++;
      if (request.data && request.data.hasOwnProperty("search")) {
        return request;
      } else {
        dispatch({ type: "setLoading", payload: true });
        return request;
      }
    },
    (error) => {
      counter--;
    }
  );

  axiosInterceptor = axios.interceptors.response.use(
    (response) => {
      counter--;
      // if (!response.config.url.includes("view_user_profile")) {
      if (counter === 0) {
        dispatch({ type: "setLoading", payload: false });
        return response;
      } else {
        return response;
      }
    },

    (error) => {
      counter--;
      // Show Alert FOr Error

      if (counter === 0) {
        dispatch({ type: "setLoading", payload: false });
      }
      if (error.response && error.response.status === 400) {
        store.addNotification({
          title: "Something Went Wrong!",
          message: error.response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      } else if (error.response && error.response.status === 403) {
 
        store.addNotification({
          title: "Unauthorized!",
          message: error.response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      } else if (error.response && error.response.status === 401) {
        store.addNotification({
          title: "Unauthorized!",
          message: "Go Back to Login",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        localStorage.removeItem("isAuth");
        localStorage.removeItem("token");
        localStorage.removeItem("currentLocale");
        window.location.href = "/login";
      } else if (error.response && error.response.status === 422) {
        //debugger;
        let errorsMsgs = ``;
        if (typeof error.response.data.error === "object") {
          Object.values(error.response.data.error).map(
            (msg) => (errorsMsgs += msg + "\n")
          );
        } else {
          errorsMsgs = error.response.data.error;
        }

        store.addNotification({
          title: error.response.data.message,
          message: errorsMsgs,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      } else if (error.response && error.response.status === 500) {
        store.addNotification({
          title: "Something Went Wrong!",
          message: "Internal Server Error, Please try again later",
          type: "danger",
          insert: "top",
          container: "top-left",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      } else if (error.response && error.response.status === 404) {
        store.addNotification({
          title: "Something Went Wrong!",
          message: "Page not found 404",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
      }

      return Promise.reject(error);
    }
  );
};

export default AxiosConfig;
