import { useState } from "react";
import {  isInclusivelyBeforeDay } from "react-dates";
import { useAdminState } from "context/Admin/global";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import Locale from "../../../translations";
export default function DatePicker(props) {
  const [focused, setFocused] = useState(false);
  const { translate } = Locale;
  const { currentLanguage } = useAdminState();
  
  const generateErrorsMsgs = (errors) => {
    let message = [];
    if (errors) {
      for (const [k, v] of Object.entries(errors)) {
        if (k === "required" && v) {
          message.push(translate.isRequired);
        } else if (k === "min" && v) {
          //message.push(` should be at least ${props.min} chars`);atLeast
          message.push(`${translate.atLeast} ${props.min}  ${translate.char}`);
        } else if (k === "maxNumber" && v) {
          message.push(` should not exceed ${props.max}`);
        } else if (k === "minNumber" && v) {
          message.push(` ${translate.atLeast} ${props.min}`);
        } else if (k === "email" && v) {
          message.push(translate.shouldBeValid);
        } else if (k === "phone" && v) {
          message.push(translate.shouldBeValid);
        } else if (k === "number" && v) {
          message.push(translate.shouldBeNumber);
        } else if (k === "formula" && v) {
          message.push(translate.shouldBeFormula);
        }
      }

      if (message.length === 1) {
        return `${props.label} ${message[0]}`;
      } else if (message.length > 1) {
        return `${props.label} ${message.join(" & ")}`;
      } else {
        return;
      }
    }
  };

  return (
    <div className={`Admintext_filed  border-${props.color}`}>
      <div
        className={`d-flex align-items-center  ${
          props.disabled ? "bg-disabled" : " bg-white"
        }`}
      >
        <div className="w-100 d-flex flex-column justify-content-center ">
          <div>
            {props.hasLabel ? (
              <label
                className={`control-field ${
                  props.color ? "text-" + props.color : ""
                }`}
              >
                {props.label}
              </label>
            ) : null}
          </div>

          <SingleDatePicker
            numberOfMonths={1}
            date={props.date}
            noBorder={true}
            disabled={props.disabled}
            focused={focused}
            displayFormat={() => {
              if (props.displayFormat) {
               return props.displayFormat
              }
              return "YYYY-MM-DD    HH:mm:ss"
            }}
     
            onFocusChange={({ focused }) => setFocused(focused)}
            onDateChange={props.onChangeDate ? props.onChangeDate : () => {}}
            placeholder={props.placeholder}
            hideKeyboardShortcutsPanel={true}
            isOutsideRange={props.isOutsideRange}
            isRTL={currentLanguage === "en" ? false : true}
            anchorDirection={currentLanguage === "en" ? "left" : "right"}
          />
        </div>
        <div
          className={`${props.hasLabel ? "icons_label" : "icons"} text-${
            props.color
          }`}
        >
          <i className="fas fa-calendar-alt date-icon  px-2 mb-1 "></i>
        </div>
      </div>
      <small className={` text-${props.color} position-absolute`}>
        {generateErrorsMsgs(props.errors)}
      </small>
    </div>
  );
}
