import { useCompanyDispatch, useCompanyState } from "context/global";
import React from "react";
import { Redirect, Route } from "react-router-dom";

export default function CompanyRouter({ component: Component, ...props }) {
  const isAuth = localStorage.getItem("isAuth");

  const dispatch = useCompanyDispatch();

  if (isAuth) {
      return (
        <Route
          {...props}
          render={(matchProps) => <Component {...matchProps} />}
        />
      );
  } else {
    return <Redirect to="/login" />;
  }
}
