import { useAdminState } from "context/Admin/global";

import React from "react";

export default function ShowForPermissionAdmin(props) {
  let CompanyState = useAdminState();

  if (
    CompanyState.Admin_permissions &&
    CompanyState.Admin_permissions.length > 0
  ) {
    let type = props.type ? props.type : "or";

    if (Array.isArray(props.permission) && type === "or") {
      /* this case for or conditions ex :  list admins and list groups   permission could have one of them */
      return props.permission.some((per) =>
        CompanyState.Admin_permissions.includes(per)
      ) ? (
        <React.Fragment>{props.children}</React.Fragment>
      ) : null;

      /* *************************************** */
    } else if (Array.isArray(props.permission) && type === "and") {
      
      return props.permission.every((per) =>
        CompanyState.Admin_permissions.includes(per)
      ) ? (
        <React.Fragment>{props.children}</React.Fragment>
      ) : null;

      /* *************************************** */
    } else {
      
      return CompanyState.Admin_permissions.includes(props.permission) ? (
        <React.Fragment>{props.children}</React.Fragment>
      ) : null;
    }
  } else if (CompanyState.userType.includes("super-admin")) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
}
