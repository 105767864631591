import useUserAdminDetails from "context/Admin/useUserAdminDetalis";
import ShowForPermissionAdmin from "helpers/ShowForPermissionAdmin";
import React from "react";
import { NavLink } from "react-router-dom";
import Locale from "../../../translations";

export default function User(props) {
  const { user } = props;
  //const { Labs } = Locale;
  const { translate, TeamManagement } = Locale;

  const fetchStatusUser = (status) => {
    let statusReturn = "";
    switch (status) {
      case "Active":
        statusReturn = TeamManagement.active;
        break;
      case "مفعل":
        statusReturn = TeamManagement.active;
        break;
      /* case "InActive":
        statusReturn = TeamManagement.deactive;
        break; */
      /*       case "pending":
        statusReturn = TeamManagement.pending;
        break; */
      default:
        statusReturn = TeamManagement.deactive;
        break;
    }

    return statusReturn;
  };

  return (
    <>
      <tr>
        <td>{user.name}</td>
        <td>{user.email}</td>
        <td>{user.phone_number}</td>
        <td>{user.group_name}</td>
        <td
          className={
            user.status === "Active" || user.status === "مفعل"
              ? "text-green"
              : "text-danger"
          }
        >
          {" "}
          {fetchStatusUser(user.status)}
        </td>
        <td>
          <div className="actions row justify-content-start">
            <ShowForPermissionAdmin
              permission={["view-admin", "edit-admin"]}
              type={"and"}
            >
              {props.details?.id != user?.id ? (
                <NavLink
                  exact={true}
                  to={`/admin/team-management/users/edit/` + user.id}
                  activeClassName="is-active"
                  className="p-2 text-primary mr-3 btn-link  col-3 "
                >
                  <i className="fas fa-edit mx-1"></i>
                  {translate.edit}
                </NavLink>
              ) : (
                <div className="col-3 mx-2">{""}</div>
              )}
            </ShowForPermissionAdmin>
            {props.details?.id != user?.id ? (
              <span className="text-primary  ">
                {user.status === "Active" || user.status === "مفعل" ? (
                  <ShowForPermissionAdmin permission={"activate-admin"}>
                    {" "}
                    <button
                      type="button"
                      onClick={() =>
                        props.changeStatusUser(user.id, "deactivate")
                      }
                      className="btn btn-link text-danger stopping"
                    >
                      <i className="fas fa-stop mx-1"></i>
                      {translate.deactivate}
                    </button>
                  </ShowForPermissionAdmin>
                ) : (
                  <ShowForPermissionAdmin permission={"activate-admin"}>
                    <button
                      type="button"
                      onClick={() =>
                        props.changeStatusUser(user.id, "activate")
                      }
                      className="btn btn-link text-success"
                    >
                      <i class="far fa-play-circle mx-1"></i>
                      {translate.active}
                    </button>
                  </ShowForPermissionAdmin>
                )}
              </span>
            ) : null}

            {/* <button onClick={() => props.togglePassword(user.id)}>
              {" "}
              <span className="mx-3 text-primary btn-link">
                <i className="fas fa-lock"></i> {translate.changePassword}
              </span>{" "}
            </button> */}

            {/*       <button
              onClick={() => props.deleteUserHandler(user.id)}
              className="btn btn-danger mx-3"
            >
              {translate.DeleteUser}
            </button> */}
          </div>
        </td>
      </tr>
      {/* 
			<tr>
				<td>{user.name}</td>
				<td>{user.email}</td>
				<td> {user.group}</td>
				<td>
					{user.status == "active" ? (
						<i className="far fa-check-circle btn fa-2x text-success"></i>
					) : (
						<i class="far fa-times-circle btn fa-2x text-danger"></i>
					)}
				</td>

				<td className="w-10">
					
						<button
							type="button"
							onClick={() => props.toggleEdit(user.id)}
							className="btn btn-link text-gray"
						>
							<i className="far fa-edit"></i> {Labs.edit}
						</button>
				
				</td>
				<td className="w-10">
					{user.status == "active" ? (
					
							<button
								type="button"
								onClick={() => props.changeStatusUser(user.id, "deactivate")}
								className="btn btn-link text-danger"
							>
								<i className="fas fa-stop"></i>
								{Labs.deactive}
							</button>
					) : (
						
							<button
								type="button"
								onClick={() => props.changeStatusUser(user.id, "activate")}
								className="btn btn-link text-success"
							>
								<i class="far fa-play-circle"></i>
								{Labs.active}
							</button>
						
					)}
				</td>
				<td className="w-10">
					
						<button
							type="button"
							onClick={() => props.togglePassword(user.id)}
							className="text-gray btn btn-link "
						>
							<i className="fas fa-unlock-alt"></i>
							{Labs.changePassword}
						</button>
					
				</td>
				<td className="w-10">
				 <button type="button" className="btn btn-danger">
						حذف
					</button> 
				</td>
			</tr>
			*/}
    </>
  );
}
