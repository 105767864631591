import { useCompanyState } from "context/global";
import TextField from "components/shared/textField";
import React from "react";
import Locale from "translations";
export default function StartTransfer({ setAmount, sendAmount }) {
  const { translate, translate_companies, wallet } = Locale;
  const { balance, safaWallet } = useCompanyState();

  return (
    <div className="container row justify-content-center no-gutters">
      <div className=" border bg-white col-12 col-md-6 py-2 mt-5 ">
        <div className="d-flex flex-column align-items-center ">
          <i class="fas fa-arrow-right fa-3x text-blue my-1 "></i>
          <i class="fas fa-arrow-left fa-3x text-danger my-1 "></i>
          <h3>{translate.TransferAccounts}</h3>
          <div className="row w-100  justify-content-center my-3 position-relative">
            <div className="medical_box  border rounded box-width p-2 text-center mx-1 col-11 col-md-5 mt-2 mt-md-0">
              <p className="m-0">{translate_companies.company_account}</p>
              <p className="m-0">{balance}</p>
            </div>

            <span className=" rounded-circle bg-white to p-3 text-bolder text-primary ">
              {translate.to}
            </span>

            <div className="medical_box border rounded box-width p-2 text-center mx-1 col-11 col-md-5 ">
              <p className="m-0">{translate_companies.wallet_safa_account}</p>
              <p className="m-0">{safaWallet}</p>
            </div>
          </div>
          <div className="col-11 col-md-10 p-0">
            <TextField
              hasLabel={true}
              label={wallet.amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
            ></TextField>
          </div>

          <button
            type="button"
            class="btn btn-success  mb-2 col-11 col-md-10"
            onClick={() => {
              sendAmount();
            }}
          >
            {translate.transfer}
          </button>
        </div>
      </div>
    </div>
  );
}
