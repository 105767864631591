import { useClientsDispatch } from "context/addClient";
import { useCompanyState } from "context/global";
import { Table } from "material-ui";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { addTravelersAPI } from "services/yarvel";
import Locale from "translations";
import Traveler from "../PcrReservations/Traveler";
import TravelerSafa from "./travelerSafa";
export default function GroupSafa({
  viewGroupAPIToken,
  toggleGroupModel,
  isOpenGroupModel,
  groupList,
  groupView,
  paginate,
  setPaginate,

  travelersData, 
  setTravelersData
}) {
  const { translate } = Locale;
  const {token_api}=useCompanyState();
  const dispatch=useClientsDispatch()
  const [isOpenTravelerModel, setIsOpenTravelerModel] = useState(false);
  const toggleTravelerModel = () => setIsOpenTravelerModel(!isOpenTravelerModel);
  const [groupName, setGroupName] = useState(null);
  const [groupId, setGroupId] = useState(null);


  const addTravelersAPIToken = async (data) => {
    const res = await addTravelersAPI(data);
    console.log("ress", res?.data);
    setTravelersData(res?.data?.data?.travelers);
    dispatch({type:"setTravelerApi", payload:res?.data?.data?.travelers})
  };

  return (
    <section className=" ">
      <Modal
        isOpen={isOpenGroupModel}
        toggle={() => {
          toggleGroupModel(null);
        }}
        className="group-login"
      >
        <div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom">
          <p className="h3 text-secondary ">{translate.groups}</p>
          <i
            className="far fa-times-circle fa-lg text-secondary "
            onClick={() => {
              toggleGroupModel(null);
            }}
          ></i>
        </div>
        <ModalBody className="lan  mx-5 my-4 p-0 ">
          <div className="mt-4">
            <div className="container-fluide">
              <div className=" mt-3 p-0 CompaniesList-table">
                <Table className="login-thead">
                  <thead className="py-3">
                    <tr className="CompaniesList-head py-3">
                      <th className="AccountsHead-title"> {"ID"}</th>
                      <th className="AccountsHead-title">
                        {" "}
                        {translate.groupName}
                      </th>
                      <th className="AccountsHead-title"> {"معرف المجموعة"}</th>
                      <th className="AccountsHead-title"> {"عدد المسافرين"}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="table-stripe ">
                    {groupList && groupList.length > 0
                      ? groupList.map((list, index) => {
                          return (
                            <tr key={index}>
                              <td>{list.id}</td>
                              <td>{list.json.Group_Name}</td>
                              <td>{list.json.tag}</td>
                              <td>{Object.keys(list.json.in_group).length}</td>
                              <td
                                style={{ width: "80px", cursor: "pointer" }}
                                className="text-primary"
                              >
                                <a
                                  href={() => false}
                                  onClick={() => {
                                    viewGroupAPIToken(list.id, token_api);
                                    setGroupName(list.json.Group_Name)
                                    setGroupId(list.id)
                                    toggleGroupModel(false)
                                    toggleTravelerModel(true);
                                  }}
                                >
                                  <i class="fas fa-eye text-primary text-center"></i>{" "}
                                  {translate.view}
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </Table>

              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <TravelerSafa
        isOpenTravelerModel={isOpenTravelerModel}
        toggleTravelerModel={toggleTravelerModel}
        groupView={groupView}
        groupName={groupName}
        groupId={groupId}
        travelersData={travelersData}
        setTravelersData={setTravelersData}
        addTravelersAPIToken={addTravelersAPIToken}

       
      />
    </section>
  );
}
