import React from "react";
import { NavLink } from "react-router-dom";
import Locale from "../../../translations";
import ShowForPermissionAdmin from "helpers/ShowForPermissionAdmin";

export default function User(props) {
  const { company } = props;
  const { translate, translate_companies, TeamManagement } = Locale;
//debugger
  return (
    <>
      <tr className="Active-company_tr">
        <td className="CompanyRow-title">{company.name}</td>
        <td className="CompanyRow-title">{company.admin_email}</td>
        <td className="CompanyRow-title">{company.manager_name}</td>
        <td className="CompanyRow-title">{company.phone}</td>
        <td className="CompanyRow-title">
          <i className="companyEye fas fa-eye"></i>
          {company.total_reservations}
        </td>
        <td className="CompanyRow-title">{company.current_balance}</td>
        <td className="CompanyRow-title">
          {company.credit_balance?.limit}{" "}
          <span className="Company-creditAmount">
            {"/" + company.credit_balance?.amount}
          </span>
        </td>
        <td
          className={
            company.status === "Active" || company.status === "مفعل"
              ? "text-green CompanyRow-title"
              : "text-danger CompanyRow-title"
          }
        >
          {company.status === "Active" || company.status === "مفعل"
            ? TeamManagement.active
            : TeamManagement.deactive}
        </td>
        <td>
          <div className="actions">
            <span className="text-primary CompanyActions-row">
              <ShowForPermissionAdmin
                permission={["update-company", "view-company"]}
                type={"and"}
              >
                <NavLink
                  exact={true}
                  to={`/admin/companies/edit/` + company.id}
                  activeClassName="is-active"
                  className="p-2 text-primary mr-3 btn-link CompanyActions-link"
                >
                  <i className="fas fa-edit mx-1"></i>
                  {translate.edit}
                </NavLink>
              </ShowForPermissionAdmin>

              <ShowForPermissionAdmin
                permission={[
                  "show-wallet",
                  "list-transaction-requests-and-transactions",
                ]}
              >
                <NavLink
                  exact={true}
                  to={`/admin/companies/wallet/` + company.id}
                  activeClassName="is-active"
                  className="p-2 text-primary mr-3 btn-link CompanyActions-link"
                >
                  <i className="fas fa-wallet mx-1"></i>
                  {translate_companies.wallet}
                </NavLink>
              </ShowForPermissionAdmin>

              <ShowForPermissionAdmin permission={"list-reservation"}>
                <NavLink
                  exact={true}
                  to={`/admin/companies/orders-inprogress/` + company.id}
                  activeClassName="is-active"
                  className="p-2 text-primary mr-3 btn-link CompanyActions-link"
                >
                  <i className="fas fa-th-list mx-1"></i>
                  {translate_companies.orders}
                </NavLink>
              </ShowForPermissionAdmin>

              {/* <NavLink
                exact={true}
                to={`/admin/companies/users/` + company.id}
                activeClassName="is-active"
                className="p-2 text-primary mr-3 btn-link CompanyActions-link"
              >
                <i className="fas fa-user-friends"></i>
                {translate_companies.users}
              </NavLink> */}

              <ShowForPermissionAdmin permission={"update-company"}>
                {company.status === "Active" || company.status === "مفعل" ? (
                  <button
                    type="button"
                    onClick={() =>
                      props.changeStatusCompany(company.id, "deactivate")
                    }
                    className="btn btn-link text-danger CompanyActions-stopping"
                  >
                    <div className="CompanyActions-square-stop-container">
                      <span className="CompanyActions-square-stop"></span>
                    </div>
                    {translate.deactivate}
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() =>
                      props.changeStatusCompany(company.id, "activate")
                    }
                    className="btn btn-link text-success CompanyActions-activate"
                  >
                    <div className="CompanyActions-playCircle-container">
                      <i class="far fa-play-circle CompanyActions-playIcon"></i>
                    </div>
                    {translate.active}
                  </button>
                )}
              </ShowForPermissionAdmin>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
}
