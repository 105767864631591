import React from "react";
import { Redirect, Route } from "react-router-dom";
import { AddClientProvider } from "../context/addClient";

export default function AddClientRoute({ component: Component, ...props }) {
  const isAuth = localStorage.getItem("isAuth");
  if (isAuth) {
    return (
      <AddClientProvider>
        <Route
          {...props}
          render={(matchProps) => <Component {...matchProps} />}
        />
      </AddClientProvider>
    );
  } else {
    return <Redirect to="/login" />;
  }
}
