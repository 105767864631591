import emailImg from "assets/images/mailbox.svg";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { activeAccount } from "services/auth";
import Locale from "translations";
import AuthLayout from "../layout/AuthLayout";

function Activation() {
  const { login,success } = Locale;
  const [counter, setCounter] = useState(5);
	const history = useHistory();
  const [msg, setMsg] = useState(
    "Please click to this link to activate your email"
  );

	useEffect(() => {
		if (true) {
			const timer =
      counter > 0	? setInterval(() => setCounter(counter - 1), 1000) : history.push("/login");
			
			return () => {
				clearInterval(timer);
			};
		}
	}, [counter]);
 

  return (
    <AuthLayout>
      <div className="bg-white p-2 px-3 radius-10 mt-3">
        <div className="row">
          <div className="col-md-6 mx-auto text-center py-4">
            <img
              src={emailImg}
              width="180"
              alt="email-verification"
              title="email-verification"
            />
            <p className="text-headline font-medium">{success.successTitle}</p>
             <p className="text-headline">     00:{counter}</p> 
       
              <Link to="/login" className="btn btn-success">
                {login.backToLogin}
              </Link>
          
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Activation;
