import React,{useState} from "react";
import Locale from "../../../translations";
import { useHistory } from "react-router-dom";
import UserNumber from "./userNumber"
import Filter from "./filter";
import { Link, NavLink } from "react-router-dom";
import Users from "./users"
import ShowForPermission from "helpers/showForPermission";

function WorkTeam() {
  const { translate } = Locale;
  let history = useHistory();
  const [users, setUsers] = useState([]);

  const countOfUsers = (val) => {
    setUsers(val);
  };

  return (
    <section>
      <UserNumber users={users} />
      <div className="container">
        <div className="d-flex justify-content-between mt-5 mb-2">
          <h3 className="text-muted font-weight-bold">
            {translate.teamManagement}
          </h3>

          <ShowForPermission permission={"create-admin"}>
            <NavLink
              exact={true}
              to={`/team-management/users/add`}
              activeClassName="is-active"
            >
              <button
                type="button"
                className="btn btn-success px-4 py-2 font-weight-bold "
                onClick={() => {
                  history.push("/team-management/users");
                }}
              >
                <i className="fas fa-plus-circle mr-2"></i>
                {translate.addUser}
              </button>
            </NavLink>
          </ShowForPermission>
        </div>
        <header className="row list_header border py-3 px-2  justify-content-center ">
          <div className="col-12 col-md-10">
            <Filter />
          </div>

          <div className="mt-3 mt-md-0">
            <ShowForPermission permission={"list-admins"}>
              <NavLink
                exact={true}
                to={`/team-management/users`}
                activeClassName="is-active"
                className="btn-nav text-bold mx-1 px-3 bg-brwon rounded text-muted"
              >
                {translate.users}
              </NavLink>
            </ShowForPermission>

            <ShowForPermission permission={"list-groups"}>
              <NavLink
                exact={true}
                to={`/team-management/groups`}
                activeClassName="is-active"
                className="btn-nav text-bold mx-1 px-3 bg-brwon rounded text-muted"
              >
                {translate.groups}
              </NavLink>
            </ShowForPermission>
          </div>
        </header>
        <Users countUsers={(val) => countOfUsers(val)} />
      </div>
    </section>
  );
}

export default WorkTeam;
