import ShowForPermission from "helpers/showForPermission";
import useUserDetails from "helpers/useUserDetalis";
import React from "react";
import { NavLink } from "react-router-dom";
import Locale from "../../../translations";

export default function User(props) {
  const { user } = props;
  //const { translate } = Locale;
  const { translate , TeamManagement } = Locale;
  
  


  return (
    <>
      <tr>
        <td>{user.name}</td>
        <td>{user.email}</td>
        <td>{user.phone_number}</td>
        <td>{user.group_name}</td>
        <td
          className={
            user.status === "مفعل" || user.status === "Active"
              ? "text-green"
              : "text-danger"
          }
        >
          {" "}
          {user.status}
        </td>
        <td>
          <div className="actions d-flex  align-items-center justify-content-start ">
            <ShowForPermission
              permission={["edit-admin", "view-admin"]}
              type={"and"}
            >
              {props.details?.id != user?.id ? (
                <NavLink
                  exact={true}
                  to={`/team-management/users/edit/` + user.id}
                  activeClassName="is-active"
                  className=" text-primary btn-link d-flex align-items-center "
                >
                  <i className="fas fa-edit mx-1"></i>
                  {translate.edit}
                </NavLink>
              ) : null}
            </ShowForPermission>

            {props.details?.id != user?.id ? (
              <span className="text-primary ">
                {user.status === "مفعل" || user.status === "Active" ? (
                  <ShowForPermission permission={"activate-admin"}>
                    <button
                      type="button"
                      onClick={() =>
                        props.changeStatusUser(user.id, "deactivate")
                      }
                      className="btn btn-link text-danger stopping"
                    >
                      <i className="fas fa-stop mx-1"></i>
                      {translate.deactivate}
                    </button>
                  </ShowForPermission>
                ) : (
                  <ShowForPermission permission={"activate-admin"}>
                    <button
                      type="button"
                      onClick={() =>
                        props.changeStatusUser(user.id, "activate")
                      }
                      className="btn btn-link text-success"
                    >
                      <i class="far fa-play-circle mx-1"></i>
                      {translate.activate}
                    </button>
                  </ShowForPermission>
                )}
              </span>
            ) : null}

            {/*           <button onClick={() => props.togglePassword(user.id)}>
              {" "}
              <span className="mx-3 text-primary btn-link">
                <i className="fas fa-lock"></i> {translate.changePassword}
              </span>{" "}
            </button> */}
            {/* 
            {details?.id != user?.id ? (
              <button className="btn btn-danger mx-3">
                {translate.remove}
              </button>
            ) : null} */}
          </div>
        </td>
      </tr>

      {/*  <tr>
        <td>{user.name}</td>
        <td>{user.email}</td>
        <td> {user.group}</td>
        <td>
          {user.status == "active" ? (
            <i className="far fa-check-circle btn fa-2x text-success"></i>
          ) : (
            <i class="far fa-times-circle btn fa-2x text-danger"></i>
          )}
        </td>

        <td className="">
          <button
            type="button"
            onClick={() => props.toggleEdit(user.id)}
            className="btn btn-link text-gray"
          >
            <i className="far fa-edit"></i> {translate.edit}
          </button>
        </td>
        <td className="">
          {user.status == "active" ? (
            <button
              type="button"
              onClick={() => props.changeStatusUser(user.id, "deactivate")}
              className="btn btn-link text-danger"
            >
              <i className="fas fa-stop"></i>
              {translate.deactive}
            </button>
          ) : (
            <button
              type="button"
              onClick={() => props.changeStatusUser(user.id, "activate")}
              className="btn btn-link text-success"
            >
              <i class="far fa-play-circle"></i>
              {translate.active}
            </button>
          )}
        </td>
        <td className="">
          <button
            type="button"
            onClick={() => props.togglePassword(user.id)}
            className="text-gray btn btn-link "
          >
            <i className="fas fa-unlock-alt"></i>
            {translate.changePassword}
          </button>
        </td>
        <td className="">
          <button type="button" className="btn btn-danger">
            حذف
          </button>
        </td>
      </tr> */}
    </>
  );
}
