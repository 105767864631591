import { useClientsDispatch, useClientsState } from "context/addClient";
import { useCompanyState } from "context/global";
import { Table } from "material-ui";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import Locale from "translations";
export default function TravelerSafa({
  toggleTravelerModel,
  isOpenTravelerModel,
  groupView,
  groupName,
  groupId,
  addTravelersAPIToken,
}) {
  const { translate } = Locale;
  const { token_api } = useCompanyState();
  const { tripInfoState } = useClientsState();
  const { service } = useClientsState();
const dispatch=useClientsDispatch();

//const dispatchTravelerAndTrip=useClientsDispatch();


  console.log("service", service);
  const [travelerData, setTravelerData] = useState([]);
  const [serviceInfo, setServiceInfo] = useState({
    city_id: service?.city_id?.value,
    area_id: service?.area_id?.value,
    comission_type: service.comission_type,
    safa_commission: service.safa_comission,
    tenant_id: service.tenant_id,
    type_id: service?.type_id?.value,

    test_id: service.check_id,
    total_cost: service?.price,
    result_time: service.time,
    grade_id: service.check_grade_id,
    credit_comission: service.credit_comission,
    debit_comission: service.debit_comission,
    service_id: service.id,
  });

  console.log("serviceInfo", serviceInfo);
  console.log("tripInfoState", tripInfoState);

  const handleCheckboxChange = (event) => {
    if (event.target.checked === true) {
      setTravelerData([...travelerData, Number(event.target.value)]);
    } else {
      setTravelerData([
        ...travelerData.filter((e) => Number(e) !== Number(event.target.value)),
      ]);
    }
  };

//**** */
let travelerSelected=[]
const allTraveler=travelerData.length>0 && groupView?.length>0 && travelerData.map((x,i)=>{
  travelerSelected.unshift(...(groupView?.filter((e)=> Object.values(e).includes(x) )) )
})

const allTravelerApiData={
  ...tripInfoState,
    "visa":{
      "group_id":groupId,
      "token":token_api,
       "members":travelerData,
    },
      ...serviceInfo,
}


  return (
    <section className=" ">
      <Modal
        isOpen={isOpenTravelerModel}
        toggle={() => {
          toggleTravelerModel(null);
        }}
        className="traveler-login"
      >
        <div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom">
          <p className="h3 text-secondary ">
            {translate.travelers} ({groupName})
          </p>
          <i
            className="far fa-times-circle fa-lg text-secondary "
            onClick={() => {
              toggleTravelerModel(null);
            }}
          ></i>
        </div>
        <ModalBody className="lan  mx-5 my-4 p-0 ">
          <div className="mt-4">
            <div className="container-fluide">
              <div className=" mt-3 p-0 CompaniesList-table">
                <Table className="login-thead">
                  <thead className="py-3 ">
                    <tr className="CompaniesList-head py-3">
                      <th></th>
                      <th className="AccountsHead-title"> {"ID"}</th>
                      <th className="AccountsHead-title">
                        {" "}
                        {translate.passportNumber}
                      </th>
                      <th className="AccountsHead-title">
                        {" "}
                        {translate.nameArabic}
                      </th>
                      <th className="AccountsHead-title">
                        {" "}
                        {translate.EnglishName}
                      </th>
                      <th className="AccountsHead-title">{translate.gender}</th>
                      <th className="AccountsHead-title">{"رقم التأشيرة"}</th>
                    </tr>
                  </thead>
                  <tbody className="table-stripe ">
                    {groupView && groupView.length > 0
                      ? groupView.map((list, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <input
                                  type="checkbox"
                                  onChange={handleCheckboxChange}
                                  value={list?.Id}
                                />
                              </td>
                              <td>{list.Id}</td>
                              <td>{list.PPNo}</td>
                              <td>{list.AFullName}</td>
                              <td>{list.FullName}</td>
                              <td>
                                {list.Gender == 1
                                  ? translate.male
                                  : translate.female}
                              </td>
                              <td>{list.e_number}</td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </Table>
                <button
                  className="btn btn-primary my-3"
                  onClick={() => {
                  //   addTravelersAPIToken({
                  //     ...tripInfoState,
                  //   "visa":{
                  //     "group_id":groupId,
                  //     "token":token_api,
                  //      "members":travelerData,
                  //   },
                  //     ...serviceInfo,                    
                  //   });
                  //   toggleTravelerModel(false)
                  // }

                  dispatch({type:"setAllTravelerApi",payload:travelerSelected})
                  dispatch({type:"setAllTravelerAndTrip",payload:allTravelerApiData})
                  toggleTravelerModel(false)
                }}
                 
                >
                  {"اضافة المسافرين"}
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </section>
  );
}
