import { useCompanyState } from "context/global";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Locale from "translations";
import Filter from "./Filter";
import useWallet from "./useWallet";
import "./walletYarvel.scss";
import WalletLayout from "./WalletLayout";
import WalletRequests from "./WalletRequests";
import { useHistory } from "react-router";
import WalletTransfer from "./WalletTransfer";
import ShowForPermission from "helpers/showForPermission";

export default function Wallet() {
  const { wallet, translate, translate_companies } = Locale;
  const {
    balance,
    safaWallet,
    safaLimit,
    balanceCreditor,
    balanceDebtor,
    safaCreditor,
    safaDebtor,
  } = useCompanyState();
  const [currentTab, setCurrentTab] = useState("not-approved");
  const { requests, getRequests, getRequestsTransaction } = useWallet();
  const company_id = localStorage.getItem("company_id");

    const country_id = localStorage.getItem("country_id");

  let history = useHistory();
  const { permissions } = useCompanyState();

  const [filter, setfilter] = useState({
    transaction_number: "",
    transaction_date: "",
    type: null,
  });
  const [submiting, setsubmiting] = useState(false);

  const sumbit = () => {
    if (currentTab === "transfer") {
      getRequestsTransaction(company_id, filter);
    } else if (currentTab === "not-approved") {
      getRequests("not-approved", filter);
    }
  };

  useEffect(() => {
    if (
      permissions.includes("list-transaction-requests-and-transactions") ||
      permissions.length === 0
    ) {
      sumbit();
    }
  }, [submiting]);

  return (
    <WalletLayout>
      <div className="container">
        <div className=" mt-3 mb-5 ">
          <ShowForPermission permission={"show-wallet"}>
            <div className="row w-100">
              <div className=" col-12 col-md-4  ">
                <h2 className="walletBalanceHeader BalanceHeader">
                  {translate.Balance}
                </h2>
                <div className="walletBalanceContent BalanceContent">
                  <div className="walletBalanceAmountContent AmountContent">
                    <h2 className="walletBalanceAmountTitle AmountTitle">
                      {translate_companies.current_balance}
                    </h2>
                    <p className="walletBalanceAmountNumber text-success AmountNumber">
                      {balance}{" "}
                      <span className="walletBalanceAmountPound AmountPound">
                        {country_id === "20"
                          ? translate_companies.egyptian_pound
                          : translate_companies.Sar}
                      </span>
                    </p>
                    <div className="walletBalanceAmountNumber AmountNumber ">
                      <span className="text-dark">{wallet.creditor} : </span>
                      <span className="text-success "> {balanceCreditor} </span>
                      &nbsp;
                      <span className="text-dark">{wallet.debtor} : </span>
                      <span className="text-success "> {balanceDebtor}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-12 col-md-5  mt-3 mt-md-0 ">
                <h2 className="walletBalanceHeader BalanceHeader">
                  {translate_companies.safa_balance}
                </h2>
                <div className="walletBalanceContent BalanceContent">
                  <div className="walletBalanceAmountContent AmountContent">
                    <h2 className="walletBalanceAmountTitle AmountTitle">
                      {translate_companies.remaining_balance +
                        " / " +
                        translate_companies.total_balance}
                    </h2>
                    <p className="walletBalanceAmountNumber text-success AmountNumber">
                      {safaWallet != undefined ? safaWallet + " / " : 0 + " / "}
                      <span className="walletBalanceAmountNumberSpan AmountNumberSpan">
                        {safaLimit != undefined ? safaLimit : 0}
                      </span>
                      &nbsp; &nbsp;
                      <span className="walletBalanceAmountPound AmountPound">
                        {country_id === "20"
                          ? translate_companies.egyptian_pound
                          : translate_companies.Sar}
                      </span>
                    </p>
                    <div className="walletBalanceAmountNumber AmountNumber ">
                      <span className="text-dark">{wallet.creditor} :</span>
                      <span className="text-success "> {safaCreditor} </span>
                      &nbsp;
                      <span className="text-dark">{wallet.debtor} : </span>
                      <span className="text-success "> {safaDebtor}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-12 col-md-3 mt-4">
                <ShowForPermission permission={"create-transaction-request"}>
                  <button
                    type="button"
                    class="rounded p-1 btn-success my-3 w-75 "
                    onClick={() => {
                      history.push("/wallet/add");
                    }}
                  >
                    <i class="fas fa-money-bill-wave fa-2x"></i>
                    <p className="h6">{translate_companies.add_credit}</p>
                  </button>
                </ShowForPermission>

                <ShowForPermission permission={"sadad-wallet"}>
                  <button
                    type="button"
                    class="rounded p-1 btn-success my-2 w-75 "
                    onClick={() => {
                      history.push("/wallet/transfer");
                    }}
                  >
                    <i class="fas fa-exchange-alt fa-2x"></i>
                    <p className="h6">{translate.TransferAccounts}</p>
                  </button>
                </ShowForPermission>
              </div>
            </div>
          </ShowForPermission>
        </div>
        <ShowForPermission
          permission={"list-transaction-requests-and-transactions"}
        >
          <div className="row tabs justify-content-center justify-content-md-start    ">
            <button
              className={`tab ${currentTab === "transfer" ? "active" : ""}`}
              onClick={() => {
                setCurrentTab("transfer");
                getRequestsTransaction(company_id, filter);
              }}
            >
              <i className="fas fa-exchange-alt fa-lg mx-2"></i>
              {wallet.deals}
            </button>
            <span className="mx-2"></span>
            <button
              className={`tab ${currentTab === "not-approved" ? "active" : ""}`}
              onClick={() => {
                setCurrentTab("not-approved");
                getRequests("not-approved", filter);
              }}
            >
              <i className="fas fa-file-alt fa-lg mx-2"></i>
              {wallet.requests}
            </button>
          </div>
          <Filter
            filter={filter}
            setfilter={setfilter}
            sumbit={sumbit}
            currentTab={currentTab}
            setsubmiting={setsubmiting}
            submiting={submiting}
          />
        </ShowForPermission>

        <ShowForPermission
          permission={"list-transaction-requests-and-transactions"}
        >
          <div className="row requests">
            {currentTab === "transfer" && (
              <WalletTransfer requests={requests} />
            )}

            {currentTab === "not-approved" && (
              <WalletRequests requests={requests} />
            )}
          </div>
        </ShowForPermission>
      </div>
    </WalletLayout>
  );
}
