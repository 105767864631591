const reducersAddClient = (state, action) => {
  switch (action.type) {
    case "addService": {
      return { ...state, service: action.data };
    }
    case "newAddService": {
      return {
        ...state,
        service: action.data,
        tenant_id: action.data.tenant_id,
        listClients: [],
        tripInfoState: {},
        listReservation: [],
        tripInfo: {},
      };
    }

    case "addTanentId": {
      return { ...state, tenant_id: action.data };
    }
    case "setTravelerApi": {
      return { ...state, TravelerApi: action.payload };
    }
    case "setServiceInfo": {
      return { ...state, serviceInfo: action.payload };
    }

    case "setAllTravelerApi": {
      return { ...state, allTravelerApi: action.payload };
    }
    case "setAllTravelerAndTrip": {
      return { ...state, allTravelerAndTrip: action.payload };
    }
    case "addTrip": {
      return { ...state, tripInfoState: action.data };
    }

    case "addClient": {
      return { ...state, listClients: [...state.listClients, {}] };
    }
    case "addReservation": {
      state.listReservation[action.index] = { ...action.data };
      return { ...state };
    }

    case "removeReservation": {
      return { ...state, listReservation: [] };
    }
    
    case "deleteClient": {
      if (state.listClients.length != 0) {
        return {
          ...state,
          listClients: [...action.index],
        };
      }
    }
    
    case "removeClient": {
      return {
        ...state,
        listClients: state.listClients.filter((item) => item.id != action.id),
      };
    }

    case "addClientData": {
      state.listClients[action.index] = { ...action.payload };
      return { ...state };
    }

    case "clientsNumber": {
      let arr = [...Array(+action.count)].map((_, index) => {
        return {};
      });
      return { ...state, listClients: [...arr] };
    }

    case "clientsEdit": {
      let arr = action.data.map((item) => {
        return { ...item };
      });

      return { ...state, listClients: [...arr] };
    }

    case "clients": {
      return { ...state, listClients: [...action.payload] };
    }
   

    default: {
      return state;
    }
  }
};
export default reducersAddClient;
