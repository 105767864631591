import DatePicker from "components/shared/DatePicker";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/textField";
import React from "react";
import Locale from "translations";
import reset from "assets/images/reset.svg";

export default function Filter({
  filter,
  setfilter,
  sumbit,
  currentTab,
  setsubmiting,
  submiting,
}) {
  const { wallet, translate, translate_companies } = Locale;
  const paymentMethod = [
    { value: "add_credit", label: wallet.add_credit },
    { value: "deduct_credit", label: wallet.deduct_credit },
    { value: "add_wallet", label: wallet.add_wallet },
    { value: "deduct_wallet", label: wallet.deduct_wallet },
  ];

  const statues = [
    { value: "pending", label: wallet.pending },
    { value: "rejected", label: wallet.rejected },
    { value: "approved", label: translate_companies.approved },
  ];

  return (
    <div className="row filter align-items-center">
      <div className="col-12 col-md-3 m-0  mr-md-2 my-2">
        <TextField
          type="text"
          className={"mb-0"}
          value={filter.transaction_number}
          placeholder={translate.search}
          onChange={(e) =>
            setfilter({ ...filter, transaction_number: e.target.value })
          }
        />
      </div>
      <div className="col-12 col-md-3 m-0  mr-md-2 my-2">
        <DatePicker
          hasLabel={false}
          placeholder="DD/MM/YYY"
          date={filter.transaction_date}
          onChangeDate={(e) => {
            setfilter({ ...filter, transaction_date: e });
          }}
        />
      </div>
      <div className="col-12 col-md-3 m-0  mr-md-2 my-2">
        {currentTab === "transfer" && (
          <SelectField
            hasLabel={false}
            value={filter.type}
            placeholder={translate.status}
            options={paymentMethod}
            onChange={(e) => setfilter({ ...filter, type: e })}
          />
        )}
        {currentTab === "not-approved" && (
          <SelectField
            hasLabel={false}
            value={filter.type}
            onFocus={true}
            placeholder={translate.status}
            options={statues}
            onChange={(e) =>
              setfilter({
                type: e,
              })
            }
          />
        )}
      </div>
      <div className="d-flex justify-content-center col-12 col-md-2">
        <button
          className="search mt-2 m-md-0"
          onClick={() => setsubmiting(!submiting)}
        >
          {translate.search}
        </button>
        <button
          className="reset"
          onClick={() => {
            setfilter({
              transaction_number: "",
              transaction_date: "",
              type: null,
            });
            setsubmiting(!submiting);
          }}
        >
          <img src={reset} alt="reset icon" /> {translate.reset}
        </button>
      </div>
    </div>
  );
}
