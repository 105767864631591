import React from "react";
import { useHistory } from "react-router-dom";
import { store } from "react-notifications-component";

import { useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";

import { addBalanceToWallet } from "services/admin";
import Locale from "translations";
import WalletLayout from "../WalletLayout";
import Card from "./Card";
import "./CreateRequest.scss";

export default function CreateRequest() {
  const { wallet, translate_companies } = Locale;
  const history = useHistory();
  const { company_id } = useParams();

  const validationSchema = yup.object({
    amount: yup
      .number(translate_companies.amountError)
      .required(translate_companies.amountRequired),
  });

  const formik = useFormik({
    initialValues: {
      amount: ""
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      let payload = {
        amount: values.amount,
        company_id: company_id,
        type: 'add',
        description: 'add'
      };

      

      addCredit(payload);
    },
  });

  const addCredit = async (payload) => {
    const res = await addBalanceToWallet(payload);

    if (res) {
      
      store.addNotification({
        title: "info!",
        message: 'success',
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      history.push('/admin/companies/wallet/'+company_id)
    }
    
  };

  return (
    <WalletLayout>
      <Card label={translate_companies.addAmount} type={"pay"} walletBalance={''}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            className={`WalletRequestInput ${formik.touched.amount && formik.errors.amount
              ? "WalletRequestInputError"
              : ""
              }`}
            id="amount"
            name="amount"
            type="number"
            label={wallet.amount}
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            value={formik.values.amount}
            onChange={formik.handleChange}
            error={
              formik.touched.amount &&
              Boolean(formik.errors.amount)
            }
            helperText={
              formik.touched.amount && formik.errors.amount
            }
            placeholder={translate_companies.amount_placeholder}
          />
          <button className="add-credit mt-3 text-bold" type="submit">
            {wallet.addBalance}
          </button>
        </form>
      </Card>
    </WalletLayout>
  );
}
